import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
    page: {
        backgroundColor: '#fff',
        // paddingLeft: 138 / 2,
        // paddingRight: 138 / 2,
        paddingTop: 30,
        position: 'relative',
        paddingBottom: '70pt',
    },

    itemWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    titleWrapper: {
        display: 'flex',
        width: '100%',
        alignContent: 'center',
        marginBottom: 16,
    },
    titleName: {
        fontSize: '12px',
        fontWeight: '700',
        color: '#000000',
        textAlign: 'center',
    },
    cardWrapper: {
        paddingTop: 47,
        paddingLeft: 18,
        display: 'flex',
        position: 'relative',
        width: 604 / 2,
        height: 353 / 2,
        marginBottom: 16,
        borderRadius: '4px',
        backgroundColor: 'green'        
    },
    backgroundImage: {
        position: 'absolute',
        top: 0,
        left: 1,
        width: 600 / 2,
        height: 350 / 2,
    },
    barcodeImage: {
        height: 14,
        width: 100,
        marginBottom: 6
    },
    textNIK: {
        fontSize: 28 / 2,
        color: '#000000',
        fontStyle: 'bold',
    },
    textNumber: {
        fontSize: 18 / 2,
        color: '#000000',
        fontWeight: '600',
        fontStyle: 'bold',
        paddingTop: 2,
    },
    textName: {
        fontSize: 9,
        color: '#000000',
        fontWeight: '600',
        paddingTop: 24,
    },
    divider: {
        borderBottom: '1px dashed #A9B3C1',
        width: '95%',
        height: '1px',
    },
    cutWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 2,
        justifyContent: 'center',
        marginBottom: '14',
    },
    gridContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 4 / 2,
        marginBottom: 16
    },
    gridItem: {
        display: 'flex',
        flexDirection: 'row',
    },
    textProfileKey: {
        fontSize: 12 / 2,
        width: 65,
        textAlign: 'left'
    },
    textColon : {
        fontSize: 12 / 2,
        width: 12
    },
    textProfile: {
        fontSize: 12 / 2,
        textAlign: 'left'
    },
    tncWrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 5
    },
    itemTnC: {
        fontSize: 9 / 2,
        textAlign: 'left'
    },
    footerWrapper: {
        display: 'flex',
        flexDirection: 'column' 
    },
    bpjsURL: {
        fontSize: 7 / 2,
        fontWeight: 'heavy', 
        textAlign: 'left'
    }
});
