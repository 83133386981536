import DatePicker from "@Atom/DatePicker";
import InputField from "@Atom/InputField";
import React from "react";
import Styles from "./style.module.scss";
import badgeInfo from "@Assets/Icon/info.svg";
import useWindowSize from "@Hooks/useWindowSize";
export default function FormDataFamilies({
  topRef,
  setInputDataFamilies = () => {},
  inputDataFamilies = [],
  idx = 0,
  maritalStatus = "",
  setIsErrorFieldFamilies = () => {},
  isErrorFieldFamilies,
}) {
  const { width } = useWindowSize();
  const handleChangeInput = (idx, name, value) => {
    setInputDataFamilies((prev) => {
      const updatedData = [...prev];
      updatedData[idx] = {
        ...updatedData[idx],
        [name]: value?.toUpperCase(),
      };
      return updatedData;
    });
    setIsErrorFieldFamilies((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors[idx] = {
        ...updatedErrors[idx],
        [name]: false,
      };
      return updatedErrors;
    });
  };
  const dataMapGender = ["LAKI-LAKI", "PEREMPUAN"];
  const dataMapDropdownFamilies = ["SUAMI", "ISTRI", "ANAK"];
  const dataDropdownJobStatus = ["TIDAK BEKERJA", "WIRASWASTA", "WIRAUSAHA"];
  return (
    <section className={Styles.containInput}>
      {idx === 0 && (
        <div className={Styles.badgeInfo} ref={topRef}>
          <img src={badgeInfo} alt="badge-info" />
          <span>Status kawin : {maritalStatus}</span>
        </div>
      )}
      <div className={Styles.sticky}>
        <h2>Informasi Data Keluarga {idx + 1}</h2>
        <div className={Styles.divider}></div>
      </div>

      <div className={width < 768 ? Styles.betweenInput : Styles.flexInput}>
        <InputField
          isLabel
          textLabel="Hubungan Keluarga"
          isRequired
          placeholder="Pilih hubungan keluarga"
          isDropdown
          setValue={(val) => handleChangeInput(idx, "contactRelations", val)}
          value={inputDataFamilies[idx]?.contactRelations}
          isError={isErrorFieldFamilies[idx]?.contactRelations}
          textError="Hubungan Keluarga belum di isi"
          dataMapDropdown={dataMapDropdownFamilies}
        />
        <InputField
          isLabel
          textLabel="Nama"
          isRequired
          placeholder="Nama"
          setValue={(val) => handleChangeInput(idx, "name", val)}
          value={inputDataFamilies[idx]?.name}
          isError={isErrorFieldFamilies[idx]?.name}
          textError="Nama belum di isi"
        />
      </div>
      <div className={width < 768 ? Styles.betweenInput : Styles.flexInput}>
        <InputField
          isLabel
          textLabel="Tempat Lahir"
          isRequired
          placeholder="Tempat Lahir"
          setValue={(val) => handleChangeInput(idx, "placeOfBirth", val)}
          value={inputDataFamilies[idx]?.placeOfBirth}
          isError={isErrorFieldFamilies[idx]?.placeOfBirth}
          textError="Tempat Lahir belum di isi"
        />
        <DatePicker
          textLabel="Tanggal Lahir"
          placeholder="Pilih Tanggal"
          isAddLabel
          isRequired
          setValue={(val) => handleChangeInput(idx, "dateOfBirth", val)}
          value={inputDataFamilies[idx]?.dateOfBirth}
          isError={isErrorFieldFamilies[idx]?.dateOfBirth}
          textError="Tanggal Lahir belum di pilih"
        />
      </div>
      <div className={width < 768 ? Styles.betweenInput : Styles.flexInput}>
        <InputField
          isLabel
          textLabel="NIK"
          isRequired
          placeholder="0"
          setValue={(val) => handleChangeInput(idx, "nik", val)}
          value={inputDataFamilies[idx]?.nik}
          isError={isErrorFieldFamilies[idx]?.nik}
          textError="NIK belum di isi"
        />
        <InputField
          isLabel
          textLabel="Jenis Kelamin"
          isRequired
          placeholder="Pilih Jenis Kelamin"
          isDropdown
          setValue={(val) => handleChangeInput(idx, "gender", val)}
          value={inputDataFamilies[idx]?.gender}
          dataMapDropdown={dataMapGender}
          isError={isErrorFieldFamilies[idx]?.gender}
          textError="Jenis Kelamin belum di pilih"
        />
      </div>
      <div
        style={width < 851 ? { width: "100%" } : { width: "calc(50% - 8px)" }}
      >
        <InputField
          isLabel
          textLabel="Status Pekerjaan"
          isRequired
          placeholder="Pilih Status Pekerjaan"
          isDropdown
          setValue={(val) => handleChangeInput(idx, "jobStatus", val)}
          value={inputDataFamilies[idx]?.jobStatus}
          isError={isErrorFieldFamilies[idx]?.jobStatus}
          textError="Status Pekerjaan belum di pilih"
          dataMapDropdown={dataDropdownJobStatus}
        />
      </div>
    </section>
  );
}
