import ListEmployee from '@Organism/Employee/ListEmployee';
import { getActiveEmployee, getHoldEmployee, getInActiveEmployee } from '@Services/employee/getAllEmployee';
import React, { Suspense } from 'react';
import { Await, defer, useLoaderData } from 'react-router-dom';
import SkeletonListEmployee from '@Molecule/Skeleton/Employee/ListEmployee';
import AsyncErrorPage from '@Molecule/AsyncErrorPage';

export const loader = (route) => {
    const params = new URLSearchParams(route?.request?.url);
    const limit = params?.get('limit') || 10;
    const page = params?.get('page') || 1;
    const searchQuery = params?.get('search') || '';
    const corpID = localStorage.getItem('corpID');
    const type = params?.get('t') || 'ACTIVE';

    let fetchData;
    if (type === 'ACTIVE') {
        fetchData = getActiveEmployee(limit, page, searchQuery, corpID);
    } else if (type === 'INACTIVE') {
        fetchData = getInActiveEmployee(limit, page, searchQuery, corpID);
    } else if (type === 'HOLD') {
        fetchData = getHoldEmployee(limit, page, searchQuery, corpID);
    } else {
        fetchData = getActiveEmployee(limit, page, searchQuery, corpID);
    }

    return defer({
        getDataEmployee: fetchData,
    });
};
export default function ListEmployeePage() {
    const { getDataEmployee } = useLoaderData();
    return (
        <Suspense fallback={<SkeletonListEmployee />}>
            <Await resolve={getDataEmployee} errorElement={<AsyncErrorPage />}>
                {(valueEmployee) => (
                    <div style={{ width: '100%' }}>
                        <ListEmployee dataEmployee={valueEmployee} isEmpty={valueEmployee?.data?.isEmpty} />
                    </div>
                )}
            </Await>
        </Suspense>
    );
}
