export const ticketCategories = [
  {
    label: 'BPJS',
    icon: 'bpjs',
    iconBg: '#41D182',
    type: ['eksternal']
  },
  {
    label: 'Penggajian',
    icon: 'dollar-group',
    iconBg: '#FBDF49',
    type: ['eksternal']
  },
  {
    label: 'Slip Gaji',
    icon: 'salary-slip',
    iconBg: '#F28E78',
    type: ['eksternal']
  },
  {
    label: 'Paklaring',
    icon: 'certificate',
    iconBg: '#31955E',
    type: ['eksternal']
  },
  {
    label: 'Parklaring',
    icon: 'certificate',
    iconBg: '#31955E',
    type: ['eksternal']
  },
  {
    label: 'Masalah Aplikasi',
    icon: 'socmed',
    iconBg: '#4B94E8',
    type: ['eksternal']
  },
  {
    label: 'Pungutan Liar',
    icon: 'bribe',
    iconBg: '#E26D70',
    type: ['eksternal', 'internal']
  },
  {
    label: 'Kekerasan di Lingkungan Kerja',
    icon: 'violation',
    iconBg: '#EC8F40',
    type: ['eksternal', 'internal']
  },
  {
    label: 'Lainnya',
    icon: 'three-dots',
    iconBg: '#9747FF',
    type: ['eksternal', 'internal']
  },
  {
    label: 'Kebersihan',
    icon: 'mop',
    iconBg: '#41D182',
    type: ['internal']
  },

  {
    label: 'Aset & Fasilitas Kantor',
    icon: 'office',
    iconBg: '#F28E78',
    type: ['internal']
  },
]