import Modal from '@Atom/Modal';
import Styles from './styles.module.scss';
import Button from '@Atom/Button';
import moment from 'moment';
import InputField from '@Atom/InputField';
import { useState } from 'react';
import DatePicker from '@Atom/DatePicker';
import Toast from '@Atom/Toast';
import { changeStatusInActiveEmployee } from '@Services/employee/changeStatusEmployee';
import { useLocation, useNavigate } from 'react-router-dom';

export default function ModalNonActivateEmployee({ employeeID, contractID, name, endDateContract, isOpen, setIsOpen }) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isFailed, setIsFailed] = useState(false);
    const [values, setValues] = useState({
        reasonNonActivate: '',
        note: '',
        endDateActive: null,
        endMonthPayroll: null,
    });

    const [errorField, setErrorField] = useState();
    const [errorMessage, setErrorMessage] = useState();

    const monthOpt = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleChange = (val, name) => {
        setValues({
            ...values,
            [name]: val,
        });
        setErrorField({
            ...errorField,
            [name]: false,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let newErrors = {};
        Object.keys(values).forEach((key) => {
            if (!values[key]) {
                newErrors[key] = true;
            }
        });

        if (Object.keys(newErrors).length > 0) {
            setErrorField(newErrors);
            return;
        }

        const monthNumber = monthOpt.indexOf(values?.endMonthPayroll) + 1

        try {
            setIsLoading(true);
            const response = await changeStatusInActiveEmployee(contractID, {
                remark: values?.reasonNonActivate?.toUpperCase(),
                description: values?.note,
                resignDate: moment(values?.endDateActive).format('YYYY-MM-DD'),
                lastPayrollMonth: monthNumber,
                employeeID: `${employeeID}(${localStorage.getItem('corpID')})`,
                corpID: `${localStorage.getItem('corpID')}`,
            });
            if (response.status === 200) {
                setIsSuccess(true);
                setIsLoading(false);
                setIsOpen(false);
                navigate(pathname, { replace: true });
            }
        } catch (error) {
            if (error.response) {
                // Jika server merespons dengan status error
                setErrorMessage(error.response.data?.message || 'Terjadi kesalahan pada server.');
            } else if (error.request) {
                // Jika request dikirim tapi tidak ada respons dari server
                setErrorMessage('Tidak ada respons dari server.');
            } else {
                // Error saat mengatur request
                setErrorMessage(error.message);
            }
            setIsFailed(true);
            setIsLoading(false);
            console.error(error);
        }
    };

    return (
        <>
            <Modal isOpen={isOpen}>
                <div className={Styles.container}>
                    <div className={Styles.header}>
                        <div className={Styles.item}>
                            <span>Nonaktifkan Karyawan</span>
                            <span>Isi form berikut untuk menonaktifkan karyawan</span>
                        </div>
                        <Button
                            isNoBorder
                            isOutlined
                            colorIcon="#3E4856"
                            iconOnly
                            icon="close-circle"
                            onClick={handleClose}
                            iconSize={'14px'}
                        />
                    </div>
                    <div className={Styles.body}>
                        <div className={Styles.disableField}>
                            <div className={Styles.rowField}>
                                <span>Nama</span>
                                <span>{name}</span>
                            </div>
                            <div className={Styles.rowField}>
                                <span>Tangal Kontrak Berakhir</span>
                                <span>{endDateContract ? moment(endDateContract).format('DD MMMM YYYY') : '-'}</span>
                            </div>
                        </div>
                        <InputField
                            isLabel
                            textLabel={'Alasan Dinonaktifkan'}
                            isRequired
                            placeholder="Belum Dipilih"
                            isDropdown
                            dataMapDropdown={['Terminate', 'Resign']}
                            value={values.reasonNonActivate}
                            setValue={(val) => handleChange(val, 'reasonNonActivate')}
                            isError={errorField?.reasonNonActivate}
                            textError="Alasan belum di pilih"
                        />
                        <InputField
                            isLabel
                            textLabel={'Catatan Karyawan Dinonaktifkan'}
                            isRequired
                            placeholder="Tulis catatan"
                            isTextArea
                            value={values.note}
                            setValue={(val) => handleChange(val, 'note')}
                            isError={errorField?.note}
                            textError="Catatan belum diisi"
                        />
                        <DatePicker
                            textLabel="Tanggal Akhir Aktif"
                            type={'date'}
                            isAddLabel
                            placeholder="Belum dipilih"
                            isRequired
                            textError="Tanggal belum dipilih"
                            value={values.endDateActive}
                            setValue={(val) => handleChange(val, 'endDateActive')}
                            isError={errorField?.endDateActive}
                        />
                        <InputField
                            isLabel
                            textLabel={'Bulan Penggajian Terakhir'}
                            isRequired
                            placeholder="Belum Dipilih"
                            isDropdown
                            dataMapDropdown={monthOpt}
                            value={values.endMonthPayroll}
                            setValue={(val) => handleChange(val, 'endMonthPayroll')}
                            isError={errorField?.endMonthPayroll}
                            textError="Bulan belum dipilih"
                        />
                    </div>
                    <div className={Styles.footer}>
                        <Button
                            text="Batal"
                            isOutlined
                            onClick={handleClose}
                            isDisabled={isLoading}
                        />
                        <Button
                            text="Simpan"
                            onClick={handleSubmit}
                            isDisabled={isLoading}
                        />
                    </div>
                </div>
            </Modal>
            <Toast
                color="green"
                text={`Karyawan atas nama ${name} berhasil dinonaktifkan`}
                isopen={isSuccess}
                onClose={() => {
                    setIsSuccess(false);
                }}
            />
            <Toast color="red" text={errorMessage} isopen={isFailed} onClose={() => setIsFailed(false)} />
        </>
    );
}
