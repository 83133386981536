import { ticketCategories } from '@Assets/data/ticketCategories'
import AttachmentCard from '@Atom/AttachmentCard'
import Icon from '@Atom/Icon'
import Input from '@Atom/Input'
import InputTitle from '@Atom/InputTitle'
import Skeleton, { CircleSkeleton } from '@Atom/Skeleton'
import { capitalizedFirstLetter } from '@Helpers/capitalizedFirstLetter'
import { timeFormatV2 } from '@Helpers/timeFormat'
import AssignTicketModal from '@Molecule/_modal/AssignTicketModal'
import Images from '@Theme/Images'
import { fileBaseUrl } from 'configs'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Styles from './style.module.scss'

export default function FeedbackDetailsLayout({
  packageDetails,
  isLoading
}) {
  const [activeTab, setActiveTab] = useState('information')
  const [handler, setHandler] = useState('')

  const [showTransfer, setShowTransfer] = useState(false)

  const navigate = useNavigate()



  const data = useMemo(() => {
    return {
      ...packageDetails,
      id: 4,
      ticketID: packageDetails?.ticketID || '',
      category: packageDetails?.issueType,
      user: {
        name: packageDetails?.employeeName,
        profilePicture: Images.AVA_DEFAULT,
        clientName: packageDetails?.clientName,
        phoneNumber: packageDetails?.userDetails?.phoneNumber || '-',
        email: packageDetails?.userDetails?.email || '-',
        idNumber: packageDetails?.userDetails?.idNumber,
        ksNumber: packageDetails?.userDetails?.bpjs?.find(obj => obj?.type === 'KS')?.number || '-',
        jhtNumber: packageDetails?.userDetails?.bpjs?.find(obj => obj?.type === 'JHT')?.number || '-',
        tkNumber: packageDetails?.userDetails?.bpjs?.find(obj => obj?.type === 'TK')?.number || '-',
        jpNumber: packageDetails?.userDetails?.bpjs?.find(obj => obj?.type === 'JP')?.number || '-',
      },
      timestamp: new Date(),
      handler: {
        name: packageDetails?.assignedDetails?.name,
        profilePicture: Images.AVA_DEFAULT,
        employeeId: packageDetails?.assignedDetails?.employeeId,
        clientName: '-',
      },
      firstResponseTime: moment(packageDetails?.firstResponseTime)?.format('LLL'),
      averageResponseTime: '-',
      sla: '-',
      status: [packageDetails?.status, packageDetails?.stage]
    }
  }, [packageDetails])

  useEffect(() => {
    setHandler(data?.handler?.name)
  }, [data?.handler?.name])

  const histories = useMemo(() => {
    return packageDetails?.ticketLogs?.map(obj => {
      return {
        title: obj?.title,
        timestamp: obj?.createdAt,
        description: obj?.description
      }
    })

  }, [packageDetails?.ticketLogs])

  const ratings = useMemo(() => {
    return packageDetails?.ticketPerformances?.map((obj, i) => {
      return {
        manager: obj?.responsible[0]?.managerName,
        handler: obj?.responsible[0]?.staffName,
        firstResponseTime: obj?.responsible[0]?.firsResponseTime ? timeFormatV2(obj?.responsible[0]?.firsResponseTime) : '-',
        averageResponseTime: obj?.responsible[0]?.averagerResponseTime ? timeFormatV2(obj?.responsible[0]?.averagerResponseTime) : '-',
        rating: i === packageDetails?.ticketPerformances?.length - 1 ? packageDetails?.rating?.rate : '',
        feedback: i === packageDetails?.ticketPerformances?.length - 1 ? packageDetails?.rating?.remark : ''
      }
    })
    // return [
    //   {
    //     manager: 'Junaedi Ahmad Budi Sugiono',
    //     handler: 'Sugiono Budi Ahmad Junaedi',
    //     firstResponseTime: '34 Menit',
    //     averageResponseTime: '1 Menit 30 Detik',
    //     rating: null,
    //     feedback: null
    //   },
    //   {
    //     manager: 'Junaedi Ahmad Budi Sugiono',
    //     handler: 'Sugiono Budi Ahmad Junaedi',
    //     firstResponseTime: '34 Menit',
    //     isViolated: true,
    //     averageResponseTime: '1 Menit 30 Detik',
    //     rating: 3,
    //     feedback: 'Corem ipsum dolor sit amet, consectetur adipiscing elit.'
    //   },
    // ]
  }, [packageDetails?.rating?.rate, packageDetails?.rating?.remark, packageDetails?.ticketPerformances])

  const chats = useMemo(() => {
    return packageDetails?.ticketChats?.map((obj, i) => {
      return {
        ...obj,
        type: obj?.employeeId === packageDetails?.employeeId ? 'user' : 'handler',
        timestamp: obj.createdAt,
        subject: i === 0 ? packageDetails?.title : '',
        message: obj?.text,
        attachment: []
      }
    })
    // return [
    //   {
    //     type: 'user',
    //     timestamp: new Date(),
    //     subject: 'BPJS Saya TIdak Dapat Digunakan Di Rumah Sakit',
    //     message: 'Horem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent.',
    //     attachments: [
    //       {
    //         id: 1,
    //         fileName: 'example.jpg',
    //         fileUrl: 'https://i.pinimg.com/736x/18/d0/b2/18d0b2f08e4a20ec0c6d67c57ff8e37c.jpg',
    //         fileType: 'image/jpeg',
    //       },
    //       {
    //         id: 1,
    //         fileName: 'example.pdf',
    //         fileUrl: 'https://drive.google.com/file/d/1DnDld5IwJ_yPcPUAe9FdY-Ydg-5spCof/view?usp=drive_link',
    //         fileType: 'application/pdf',
    //       },
    //     ],
    //   },
    //   {
    //     type: 'handler',
    //     timestamp: new Date(),
    //     message: 'Halo [Nama pelapor] terima kasih telah menggunakan layanan pengaduan kami. Untuk sekarang ini pengaduan kamu sedang dalam proses pengecekan, harap tunggu untuk mendapatkan informasi lanjutan terkait pengaduan kamu ya.'
    //   },
    //   {
    //     type: 'user',
    //     timestamp: new Date(),
    //     message: 'Horem ipsum dolor sit amet, consectetur adipiscing elit. '
    //   }
    // ]
  }, [packageDetails?.employeeId, packageDetails?.ticketChats, packageDetails?.title])


  const leftContent = useMemo(() => {
    if (isLoading) {
      return (
        <div className={Styles.information}>
          <Section className={Styles.statusSection}>
            <Skeleton>
              <div className={Styles.indicators}>
                <Icon color={data?.originTicketId ? '#22CFBA' : '#DEE2E7'} icon={'document-previous'} size={20} />
                <Icon color={data?.isLateResponse ? '#D42701' : '#DEE2E7'} icon={'flag'} size={20} />
                <Icon color={data?.isTransfers ? '#2E3192' : '#DEE2E7'} icon={'document-forward'} size={20} />
                <div className={Styles.messageIcon}>
                  <Icon color={data?.needResponse ? '#22970F' : '#DEE2E7'} icon={'message'} size={20} />
                  {
                    data?.status?.find(obj => obj?.includes('MESSAGE'))
                    &&
                    <span>
                      {data?.status?.find(obj => obj?.includes('MESSAGE'))?.split('-')[1]}
                    </span>
                  }
                </div>
              </div>
            </Skeleton>

            <Skeleton height='36px'>
              <div className={`${Styles.status} ${Styles[data?.status[0]?.toLowerCase()]}`}>
                {`${capitalizedFirstLetter(data?.status[0])} : ${data?.status[1] === 'NEW'
                  ? 'Baru'
                  : (
                    data?.status[1] === 'ON-PROSESS'
                      ?
                      'Dalam Proses'
                      :
                      'Selesai'
                  )}`}
              </div>
            </Skeleton>

          </Section>
          <Section className={Styles.userSection}>
            <span>
              <Skeleton width='96px' height='20px' >
                Detail Pelapor
              </Skeleton>
            </span>
            <div className={Styles.profile}>
              <CircleSkeleton height='52px'>
                <img src={data?.user?.profilePicture || Images.AVA_DEFAULT} alt='' />
              </CircleSkeleton>
              <div className={Styles.userName}>
                <Skeleton height='26px'>
                  <span>{data?.user?.name}</span>
                </Skeleton>
                <p style={{ paddingTop: '4px' }}>
                  <Skeleton height='20px'>
                    {data?.user?.clientName}
                  </Skeleton>
                </p>
              </div>
            </div>
            <div className={Styles.userDetails}>
              {
                [
                  {
                    label: 'No Hp',
                    value: data?.user?.phoneNumber
                  },
                  {
                    label: 'Email',
                    value: data?.user?.email
                  },
                  {
                    label: 'No KTP',
                    value: data?.user?.idNumber
                  },
                  {
                    label: 'No BPJS KS',
                    value: data?.user?.ksNumber
                  },
                  {
                    label: 'No BPJS JHT',
                    value: data?.user?.jhtNumber
                  }
                ]
                  ?.map((detail, i) => (
                    <Skeleton key={i}>
                      <div className={Styles.detail}>
                        <span>{detail?.label}</span>
                        <span>:</span>
                        <span>{detail?.value}</span>
                      </div>
                    </Skeleton>

                  ))
              }
            </div>
          </Section>

          <Section className={Styles.handlerSection}>
            <Skeleton width='124px' />
            <Skeleton height='44px'>
              <Input
                title='Penanggung Jawab'
                disabled={data?.handler}
                value={handler}
                setValue={setHandler}
                placeholder='Pilih penanggung jawab'
                dropdownOptions={[
                  {
                    name: 'Yon Safira',
                    picture: Images.AVA_DEFAULT
                  }
                ]}
                isUserAutocomplete
              />
            </Skeleton>

            <Skeleton height='40px'>
              <button disabled={data?.handler}>Simpan</button>
            </Skeleton>
          </Section>

          <Section className={Styles.categorySection}>
            <div>
              <span style={{ paddingBottom: '4px' }}>
                <Skeleton width='150px' >
                  Kategori Pengaduan
                </Skeleton>
              </span>

              <div className={Styles.category}>
                <div className={Styles.bpjsLogo}>
                  <CircleSkeleton height='32px'>
                    <img src={Images.BPJS} alt='' />
                  </CircleSkeleton>
                </div>
                <Skeleton height='24px' width='80px'>
                  <span>{data?.category}</span>
                </Skeleton>
              </div>
            </div>
            <div>
              <span style={{ paddingBottom: '4px' }}>
                <Skeleton width='180px' >
                  Tanggal dan Waktu Pengaduan Diterima
                </Skeleton>
              </span>
              <Skeleton>
                <p>{moment(data?.timestamp)?.format('LLL')}</p>
              </Skeleton>
            </div>
            <div>
              <span style={{ paddingBottom: '4px' }}>
                <Skeleton width='180px' >
                  Waktu Penyelesaian Pengaduan
                </Skeleton>
              </span>
              <Skeleton>
                <p>{data?.closedAt ? timeFormatV2((data?.closedAt?.getTime() - data?.createdAt?.getTime() / 1000)) : '-'}</p>
              </Skeleton>
            </div>
          </Section>
        </div>
      )
    }
    switch (activeTab) {
      case 'history':
        return (
          <div className={Styles.histories}>
            {
              histories?.map((history, i) => (
                <div key={i} className={`${Styles.history} ${Styles[history?.isViolated ? 'violated' : '']}`}>
                  <div className={`${Styles.bullet}`} />
                  <div className={Styles.historyDetails}>
                    <span>{history?.title}</span>
                    <span>{moment(history?.timestamp)?.format('LLL')}</span>
                    <span>{history?.description}</span>
                  </div>
                </div>
              ))
            }
          </div>
        )
      case 'rating':
        return (
          <div className={Styles.ratings}>
            {
              ratings?.map((rating, i) => (
                <Section key={i} className={Styles.rating}>
                  <span>Performa Penerima {i + 1}</span>
                  <div className={Styles.ratingDetails}>
                    {
                      [
                        {
                          title: 'Manager Penerima',
                          value: rating?.manager
                        },
                        {
                          title: 'Penanggung Jawab',
                          value: rating?.handler,
                        },
                        {
                          title: 'First Response Time',
                          value: rating?.firstResponseTime,
                          className: Styles[rating?.isViolated ? 'red' : '']
                        },
                        {
                          title: 'Average Response Time',
                          value: rating?.averageResponseTime,
                        },
                        {
                          title: 'Rating',
                          value: rating?.rating ? (
                            <div className={Styles.ratingValue}>
                              <img src={Images.EMO_1} className={Styles[rating?.rating < 1 ? 'transparent' : '']} alt='' />
                              <img src={Images.EMO_2} className={Styles[rating?.rating < 2 ? 'transparent' : '']} alt='' />
                              <img src={Images.EMO_3} className={Styles[rating?.rating < 3 ? 'transparent' : '']} alt='' />
                              <img src={Images.EMO_4} className={Styles[rating?.rating < 4 ? 'transparent' : '']} alt='' />
                              <img src={Images.EMO_5} className={Styles[rating?.rating < 5 ? 'transparent' : '']} alt='' />
                              <span>{rating?.rating}/5</span>
                            </div>
                          ) : '-'
                        }
                      ]
                        ?.map((obj, i) => (
                          <div key={i}>
                            <span>{obj?.title}</span>
                            <span>:</span>
                            <div>
                              {obj?.value || '-'}
                            </div>
                          </div>
                        ))
                    }
                    {
                      rating?.feedback
                      &&
                      <div>
                        <span>Feedback</span>
                        <span>:</span>
                        <div>
                          {rating?.feedback || '-'}
                        </div>
                      </div>
                    }
                  </div>
                </Section>
              ))
            }
          </div>
        )
      default:
        return (
          <div className={Styles.information}>
            <Section className={Styles.statusSection}>
              <div className={Styles.indicators}>
                <Icon color={data?.originTicketId ? '#22CFBA' : '#DEE2E7'} icon={'document-previous'} size={20} />
                <Icon color={data?.isLateResponse ? '#D42701' : '#DEE2E7'} icon={'flag'} size={20} />
                <Icon color={data?.isTransfers ? '#2E3192' : '#DEE2E7'} icon={'document-forward'} size={20} />
                <div className={Styles.messageIcon}>
                  <Icon color={data?.needResponse ? '#22970F' : '#DEE2E7'} icon={'message'} size={20} />
                  {
                    data?.status?.find(obj => obj?.includes('MESSAGE'))
                    &&
                    <span>
                      {data?.status?.find(obj => obj?.includes('MESSAGE'))?.split('-')[1]}
                    </span>
                  }
                </div>
              </div>
              <div className={`${Styles.status} ${Styles[data?.status[0]?.toLowerCase()]}`}>
                {`${capitalizedFirstLetter(data?.status[0])} : ${data?.status[1] === 'NEW'
                  ? 'Baru'
                  : (
                    data?.status[1] === 'ON-PROSESS'
                      ?
                      'Dalam Proses'
                      :
                      'Selesai'
                  )}`}
              </div>
            </Section>
            <Section className={Styles.userSection}>
              <span>Detail Pelapor</span>
              <div className={Styles.profile}>
                <img src={data?.user?.profilePicture || Images.AVA_DEFAULT} alt='' />
                <div className={Styles.userName}>
                  <span>{data?.user?.name}</span>
                  <p>{data?.user?.clientName}</p>
                </div>
              </div>
              <div className={Styles.userDetails}>
                {
                  [
                    {
                      label: 'No Hp',
                      value: data?.user?.phoneNumber
                    },
                    {
                      label: 'Email',
                      value: data?.user?.email
                    },
                    {
                      label: 'No KTP',
                      value: data?.user?.idNumber
                    },
                    {
                      label: 'No BPJS KS',
                      value: data?.user?.ksNumber
                    },
                    {
                      label: 'No BPJS JHT',
                      value: data?.user?.jhtNumber
                    }
                  ]
                    ?.map((detail, i) => (
                      <div key={i} className={Styles.detail}>
                        <span>{detail?.label}</span>
                        <span>:</span>
                        <span>{detail?.value}</span>
                      </div>
                    ))
                }
              </div>
            </Section>


            <Section className={Styles.handlerSection}>
              <InputTitle title='Penanggung Jawab' />
              <div className={Styles.handler}>
                <img src={Images.AVA_DEFAULT} alt='' />
                <span style={{ color: data?.handler?.name ? '#3E4856' : '' }}>{data?.handler?.name || 'Belum memiliki penanggung jawab'}</span>
              </div>
            </Section>


            <Section className={Styles.categorySection}>
              <div>
                <span>Kategori Pengaduan</span>
                <div className={Styles.category}>
                  <div className={Styles.categoryLogo} style={{ backgroundColor: ticketCategories?.find(obj => obj?.label?.toLowerCase() === data?.issueType?.toLowerCase())?.iconBg }}>
                    <Icon icon={ticketCategories?.find(obj => obj?.label?.toLowerCase() === data?.issueType?.toLowerCase())?.icon} size={20} />
                  </div>
                  <span>{data?.category}</span>
                </div>
              </div>
              <div>
                <span>Tanggal dan Waktu Pengaduan Diterima</span>
                <p>{moment(data?.timestamp)?.format('LLL')}</p>
              </div>
              <div>
                <span>Waktu Penyelesaian Pengaduan</span>
                <p>{data?.closedAt ? timeFormatV2((new Date(data?.closedAt)?.getTime() - new Date(data?.createdAt)?.getTime()) / 1000) : '-'}</p>
              </div>
            </Section>
          </div>
        )
    }
  }, [activeTab, data?.category, data?.closedAt, data?.createdAt, data?.handler, data?.isLateResponse, data?.isTransfers, data?.issueType, data?.needResponse, data?.originTicketId, data?.status, data?.timestamp, data?.user?.clientName, data?.user?.email, data?.user?.idNumber, data?.user?.jhtNumber, data?.user?.ksNumber, data?.user?.name, data?.user?.phoneNumber, data?.user?.profilePicture, handler, histories, isLoading, ratings])

  return (
    <div className={Styles.container}>
      <div className={Styles.left}>
        <div className={Styles.leftHeader}>
          <h3>Detail Pengaduan {data?.ticketID}</h3>
          <div className={Styles.tabs}>
            {
              [
                {
                  label: 'Informasi Umum',
                  value: 'information'
                },
                {
                  label: 'Riwayat',
                  value: 'history'
                },
                {
                  label: 'Rating',
                  value: 'rating'
                }
              ]
                ?.map((tab, i) => (
                  <div key={i} onClick={() => setActiveTab(tab?.value)} className={`${Styles.tab} ${Styles[activeTab === tab?.value ? 'active' : '']}`}>
                    {tab?.label}
                  </div>
                ))
            }
          </div>
        </div>
        <div className={Styles.leftContent}>
          {leftContent}
        </div>
      </div>
      <div className={Styles.right}>
        <div className={Styles.rightHeader}>
          <button className={Styles.backButton} onClick={() => navigate(-1)}>
            <Icon icon={'arrow-chevron-left'} size={20} />
            <span>Kembali</span>
          </button>
          {/* {
            role === 'HANDLER' && !isLoading
            &&
            <div className={Styles.handlerExtraHeader}>
              <button>
                <Icon icon={'tick-circle'} size={16} />
                <span>Request Tutup Tiket</span>
              </button>
              <span>Total Request : 0</span>
            </div>
          } */}
        </div>
        {
          isLoading
            ?
            <div className={Styles.rightContent}>
              <Skeleton
                height='100%'

              />
            </div>
            :
            <div className={Styles.rightContent}>

              <div className={Styles.chats}>
                {
                  chats?.map((chat, i) => (
                    <div key={i} className={Styles.chat}>
                      {
                        chat?.type === 'user'
                          ?
                          <img src={chat?.userDetails[0]?.profilePicture ? (
                            chat?.userDetails[0]?.profilePicture?.includes('myworkspace')
                              ?
                              chat?.userDetails[0]?.profilePicture?.replaceAll('myworkspace', 'api1.myworkspace')
                              : fileBaseUrl + chat?.userDetails[0]?.profilePicture
                          ) : Images.AVA_DEFAULT} alt='' />

                          :
                          <div className={Styles.mwsLogo}>
                            <img src={chat?.userDetails[0]?.profilePicture ? (
                              chat?.userDetails[0]?.profilePicture?.includes('myworkspace')
                                ?
                                chat?.userDetails[0]?.profilePicture?.replaceAll('myworkspace', 'api1.myworkspace')
                                : fileBaseUrl + chat?.userDetails[0]?.profilePicture
                            ) : Images.MWS_LOGO} alt='' />
                          </div>
                      }
                      <div className={Styles.chatDetails}>
                        <span>{chat?.userDetails[0]?.name}</span>
                        {chat?.subject && <span>{chat?.subject}</span>}
                        <p>{chat?.message}</p>
                        {
                          chat?.attachments?.length
                          &&
                          <div className={Styles.attachments}>
                            {chat?.attachments?.map((attachment, j) => (
                              <AttachmentCard
                                key={j}
                                fileName={attachment?.fileName}
                                fileType={attachment?.fileType}
                                fileUrl={attachment?.fileUrl}
                              />
                            ))}
                          </div>

                        }
                      </div>
                      <span>{moment(chat?.timestamp)?.format('HH:mm')}</span>
                      {
                        i !== chats.length - 1 && (
                          <div className={Styles.divider} />
                        )
                      }

                    </div>
                  ))
                }
              </div>
              <div className={Styles.messageBox}>

                <p>Kamu tidak memiliki akses untuk melakukan percakapan dengan pelapor</p>

                {/* <TextAreaFileInput
                        text={message}
                        files={files}
                        setFiles={setFiles}
                        setText={setMessage}
                        withSendButton
                        templates={messageTemplates}
                   /> */}

              </div>

            </div>
        }

      </div>
      <AssignTicketModal
        show={showTransfer}
        data={data}
        onClose={() => setShowTransfer(false)}
        onSuccess={() => {
        }}
        isTransfer
      />
    </div>
  )
}

const Section = ({ children, className = '' }) => {

  return (
    <div className={`${Styles.section} ${className}`}>
      {children}
    </div>
  )
}