import React, { useState } from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import Button from "@Atom/Button";
import { formatBytes } from "helpers/formatBytes";
import FileViewerModal from "@Atom/FileViewerModal";
import { detectFileType } from "@Helpers/detectFileType";
export default function FormDataUploadDocument({
  reffUploadDocument = null,
  handleChangeUploadDocument = () => {},
  documentsUpload = [],
  uploadProgress = [],
  handleRemoveDocument = () => {},
  setIsErrorFilesDocument = () => {},
  isErrorFilesDocument,
  topref
}) {
  const [openModalFile, setOpenModalFile] = useState(false);
  const [viewFile, setViewFile] = useState()

  const handleViewFile = (data) => {
      if (data?.docUrl) {
          setViewFile({
              ...data,
              objUrl: data?.docUrl,
              fileType: detectFileType(data?.docUrl),
          });
      } else if (data instanceof File) {
          let fileType = 'image';

          const mimeType = data.type;
          if (mimeType === 'application/pdf') {
              fileType = 'pdf';
          } else {
              fileType = 'image';
          }

          setViewFile({
              name: data?.name,
              fileType,
              objUrl: URL.createObjectURL(data),
          });
      }
      setOpenModalFile(true)
  };

  return (
    <section className={Styles.lastUploadDocument}>
      {openModalFile && 
        <FileViewerModal
          isOpen={openModalFile}
          onClose={() => {
            setOpenModalFile(false)
            setViewFile()
          }}
          fileType={viewFile?.fileType}
          fileUrl={viewFile?.objUrl}
          fileName={viewFile?.name || ''}
        />
      }
      <h2 ref={topref}>Unggah Dokumen</h2>
      <section className={Styles.wrapper}>
        <div className={Styles.upload}>
          <div
            className={Styles.card1}
            style={{
              borderColor: isErrorFilesDocument.filesDocument
                ? "#D42701"
                : "unset",
            }}
          >
            <Icon icon={"document-upload"} size={"48"} color={"#2E3192"} />
            <h2>Unggah Dokumen</h2>
            <span>Unggah file dengan format dokumen PDF atau JPEG</span>
            <Button
              style={{ background: "white", color: "#2E3192" }}
              isBorder
              text="Browse File"
              onClick={() => {
                reffUploadDocument?.current?.click();
                setIsErrorFilesDocument(false);
              }}
            />
            <input
              type="file"
              hidden
              accept=".pdf, .jpeg"
              ref={reffUploadDocument}
              onChange={handleChangeUploadDocument}
            />
          </div>
          {documentsUpload?.map((file, idx) => (
            <div className={Styles.uploading} key={`document-upload-${idx}`}>
              <div className={Styles.between}>
                <div className={Styles.iconFolder} style={{cursor: 'pointer'}} onClick={() => handleViewFile(file)}>
                  <Icon icon={"folder"} color={"#2E3192"} size={"24"} />
                </div>
                <div className={Styles.wrapperProgress}>
                  <span className={Styles.name}>{file?.name} </span>
                  {file?.method !== "LOAD" && (
                    <span className={Styles.fileSize}>
                      {formatBytes(file?.size)}
                    </span>
                  )}
                  {file?.method !== "LOAD" && (
                    <div className={Styles.loading}>
                      <div className={Styles.wrapperUploader}>
                        <div
                          className={Styles.progress}
                          style={{
                            width: `${uploadProgress[idx] || 0}%`,
                          }}
                        ></div>
                      </div>
                      <span>
                        {uploadProgress[idx] >= 100 ? 100 : uploadProgress[idx]}
                        {uploadProgress[idx] >= 100 ? "%" : "%"}
                      </span>
                    </div>
                  )}
                </div>
                <div
                  className={Styles.closeIcon}
                  onClick={() => handleRemoveDocument(idx)}
                >
                  <Icon
                    icon={
                      uploadProgress[idx] >= 100 || file?.method === "LOAD"
                        ? "trash"
                        : "close-circle-delete"
                    }
                    color={uploadProgress[idx] >= 100 || file?.method === "LOAD" ? "#D42701" : "#3E4856"}
                    size={"16"}
                    alt="close"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </section>
  );
}
