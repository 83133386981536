// Example
// arrayObject.sort(sortByColumn('name','ASC'))
// arrayObject.sort(sortByColumn('',''))

export const sortByColumn = (sortBy, order = 'ASC') => {
    return (a, b) => {
        const valueA = a[sortBy] || '';
        const valueB = b[sortBy] || '';

        // String comparison
        if (typeof valueA === 'string' && typeof valueB === 'string') {
            return order === 'ASC' 
                ? valueA.localeCompare(valueB) 
                : valueB.localeCompare(valueA);
        }

        // Number comparison
        if (typeof valueA === 'number' && typeof valueB === 'number') {
            return order === 'ASC' 
                ? valueA - valueB 
                : valueB - valueA;
        }

        return 0; // Default case (if data types don't match)
    };
};