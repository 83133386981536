import TabHeader from "@Atom/TabHeader";
import Styles from "./style.module.scss";
import useWindowSize from "@Hooks/useWindowSize";
import {
  Fragment,
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Icon from "@Atom/Icon";
import Tabs from "@Molecule/Tabs";
import Button from "@Atom/Button";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import FileViewerModal from "@Atom/FileViewerModal";
import { fileBaseUrl, fileBaseUrlC2C } from "configs";
import Modal from "@Atom/Modal";
import Toast from "@Atom/Toast";
import { formatBytes } from "helpers/formatBytes";
import { deleteFileEmployee } from "@Services/employee/deleteFileEmployee";
import { editFileEmployee } from "@Services/employee/editFileEmployee";
import useQuery from "@Hooks/useQuery";
import EmptyDataFamily from "@Assets/Images/empty-data-family.png";
import SkeletonDataFamilies from "@Molecule/Skeleton/Employee/DetailEmployee/DataFamilies";
import SkeletonTable from "@Molecule/Skeleton/Table";
const Table = lazy(() => import("@Molecule/Table"));
// import useQuery from "@Hooks/useQuery";

// eslint-disable-next-line no-empty-pattern
export function DetailPersonalEmployee({ dataPersonaEmployee, employeeIDCorp }) {
  const { width } = useWindowSize();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isChecked, setIsChecked] = useState([]);
  const [isActiveTabs, setIsActiveTabs] = useState(
    +sessionStorage.getItem("tabsActiveDetail") || 0
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    sessionStorage.setItem("tabsActiveDetail", isActiveTabs);
  }, [isActiveTabs]);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const params = useQuery();
  const limitQuery = params.get("limit");
  const pageQuery = params.get("page");

  useEffect(() => {
    setPage(+pageQuery || 1);
    setLimit(+limitQuery || 10);
  }, [limitQuery, pageQuery]);

  useEffect(() => {
    if (isActiveTabs === 3) {
      navigate(`${pathname}?query=q&type=document&limit=${limit}&page=${page}`);
      setIsLoading(true)
    }
  }, [limit, navigate, page, pathname, isActiveTabs, setIsLoading]);

  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [successOrError, setSuccessOrError] = useState(false || "");

  const dataNames = dataPersonaEmployee.data.data;
  const dataToTap = useMemo(() => {
    if (isActiveTabs === 0) {
      return dataPersonaEmployee?.data?.data?.comprehensive;
    } else if (isActiveTabs === 1) {
      return dataPersonaEmployee?.data?.data?.families;
    } else if (isActiveTabs === 2) {
      return dataPersonaEmployee?.data?.data?.contracts[0];
    } else if (isActiveTabs === 3) {
      return dataPersonaEmployee?.data?.data?.documents || [];
    }
  }, [isActiveTabs, dataPersonaEmployee]);
  const [isShowFile, setIsShowFile] = useState(false);
  const [viewFile, setViewFile] = useState({ type: "", url: "", fileName: "" });
  const [isEdit, setIsEdit] = useState(false);

  const handleDownload = async () => {
    setIsLoadingDownload(true);
    for (let i = 0; i < isChecked.length; i++) {
      const selectedFile = isChecked[i];
      try {
        const response = await fetch(selectedFile.docUri);
        if (!response.ok) {
          setSuccessOrError("error");
          throw new Error("Failed to download file");
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = selectedFile.fileName || "download.pdf";
        document.body.appendChild(link);
        link.click();
        if (response.ok) {
          setSuccessOrError("success");
          setIsLoadingDownload(false);
        }
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        setSuccessOrError("error");
        setIsLoadingDownload(false);
        console.error(
          `Error downloading file ${selectedFile.fileName}:`,
          error
        );
      }
    }
  };

  const handleViewFile = (data, key) => {
    if (data[key]) {
      setViewFile({
        type: data[key].includes(".pdf") ? "pdf" : "image",
        url: data[key],
        fileName:
          data.fileName ||
          `file.${data[key].includes(".pdf") ? "pdf" : "image"}`,
      });
      setIsShowFile(true);
    }
  };

  useEffect(() => {
    navigate(
      `${pathname}?query=q&type=${isActiveTabs === 0
        ? ""
        : isActiveTabs === 1
          ? "family"
          : isActiveTabs === 2
            ? "contract"
            : "document"
      }`
    );
    setIsLoading(true)
  }, [isActiveTabs, navigate, pathname, setIsLoading]);

  const reffUploadDocument = useRef(null);
  const [docStaffing, setDocStaffing] = useState(null);
  const [progressDocStaffing, setIsProgressDocStaffing] = useState(0);
  const handleUploadFileStaffing = (e) => {
    const files = e?.target?.files[0];
    setDocStaffing(files);
    let progress = 0;
    const intervalId = setInterval(() => {
      progress += 1;
      setIsProgressDocStaffing(progress);
      if (progress >= 100) {
        clearInterval(intervalId);
      }
    }, 20);
  };
  // const [errorDelete, setErrorDelete] = useState(false || "");

  const [successDelete, setSuccessDelete] = useState(false || "");
  const handleDeleteFile = useCallback(
    async (id) => {
      if (id) {
        const files = dataToTap.filter((el) => el?.id === id);
        try {
          const response = await deleteFileEmployee(
            files[0].id,
            files[0].idNumber
          );
          if (response.status === 200) {
            setSuccessDelete("success");
            navigate(`${pathname}?query=q&type=document`, { replace: true });
          }
        } catch (error) {
          console.log(error);
          setSuccessDelete("error");
        }
      }
    },
    [dataToTap, navigate, pathname]
  );

  const [isSuccessEdit, setIsSuccessEdit] = useState(false || "");
  const [fileName, setFileName] = useState("");
  useEffect(() => {
    if (isActiveTabs === 3) {
      const getFileName = dataToTap?.filter((el) => el?.id === isEdit);
      setFileName(getFileName[0]?.fileName);
    }
  }, [dataToTap, isActiveTabs, isEdit]);
  const handleEditFile = useCallback(
    async (id) => {
      if (id) {
        const files = dataToTap.filter((el) => el?.id === id);
        const formData = new FormData();
        formData.append("file", docStaffing);
        try {
          const response = await editFileEmployee(
            files[0]?.id,
            files[0]?.idNumber,
            formData
          );
          if (response.status === 201) {
            setIsSuccessEdit("success");
            setIsEdit(false);
            navigate(`${pathname}?query=q&type=document`, { replace: true });
            setDocStaffing(null);
          }
        } catch (error) {
          console.log(error);
          setIsSuccessEdit("error");
          setIsEdit(false);
        }
      }
    },
    [dataToTap, docStaffing, navigate, pathname]
  );

  const [sort, setSort] = useState({
    column: "fileName",
    type: "ASC" || "DESC",
  });
  const sortedData = useMemo(() => {
    if (isActiveTabs === 3 && dataToTap?.length >= 0) {
      const sorted = [...dataToTap].sort((a, b) => {
        if (sort.column) {
          if (sort.type === "ASC") {
            return a.fileName?.localeCompare(b.fileName);
          } else {
            return b.fileName?.localeCompare(a.fileName);
          }
        }
        return 0;
      });

      return sorted;
    }
  }, [dataToTap, isActiveTabs, sort.column, sort.type]);

  const template = useMemo(() => {
    setIsLoading(false)
    return {
      data:
        (isActiveTabs === 3 && dataToTap?.length >= 0
          ? sortedData?.map((el) => ({
            id: el?.id || "0",
            createdAt: moment(el?.createdAt).format("ll") || "-",
            fileName: el?.docType || "-",
            docUri: !el?.docUri ? '-' : el?.source === 'c2c' ? fileBaseUrlC2C + el?.docUri : fileBaseUrl + el?.docUri,
          }))
          : []) || [],
      totalData: dataPersonaEmployee?.data?.totalData,
      columns: [
        {
          name: "fileName",
          width: width > 768 ? "max-content" : "200px",
          title: (
            <TabHeader
              text="Nama File"
              column={"fileName"}
              isSorted
              sort={sort}
              setSort={setSort}
            />
          ),
          renderData: (row) => (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => handleViewFile(row, "docUri")}
            >
              {row?.fileName}
            </span>
          ),
        },
        {
          name: "createdAt",
          width: width > 768 ? "max-content" : "200px",
          title: (
            <TabHeader
              text="Tanggal Upload"
              column={"createdAt"}
              isSorted
              sort={sort}
              setSort={setSort}
            />
          ),
          hideOnMobile: true,
        },
        {
          name: "",
          title: (
            <div className={Styles.actionTab}>
              <TabHeader text="Aksi" column={"typeContract"} />
            </div>
          ),
          width: "200px",
          renderData: (row) => (
            <div className={Styles.actionMenu}>
              <Icon
                icon={"edit-2"}
                color={"#2E3192"}
                size={"20"}
                className={Styles.icon}
                onClick={() => setIsEdit(row.id)}
              />
              <Icon
                icon={"trash"}
                color={"red"}
                size={"20"}
                className={Styles.icon}
                onClick={() => handleDeleteFile(row.id)}
              />
            </div>
          ),
          hideOnMobile: true,
        },
      ],
    };
  }, [
    isActiveTabs,
    dataToTap?.length,
    sortedData,
    dataPersonaEmployee?.data?.totalData,
    width,
    sort,
    handleDeleteFile,
  ]);

  const tabs = useMemo(() => {
    return ["Data Pribadi", "Data Keluarga", "Kepegawaian", "Lampiran"];
  }, []);

  const { search } = useLocation();

  const queryParams = new URLSearchParams(search);
  const isContractType = queryParams.get("type") === "document";

  setTimeout(() => {
    if (successOrError === "error") {
      setSuccessOrError("");
    } else if (successOrError) {
      setSuccessOrError(false);
    } else if (successDelete === "error" || successDelete === "success") {
      setSuccessDelete(false);
    } else if (isSuccessEdit === "error" || isSuccessEdit === "success") {
      setIsSuccessEdit(false);
    }
  }, 5000);

  return (
      <div className={Styles.content}>
          <Toast
              color={successDelete === 'error' ? 'red' : 'green'}
              text={successDelete === 'error' ? 'File Gagal Di Hapus' : 'File Berhasil Di Hapus'}
              isopen={successDelete === 'success' || successDelete === 'error'}
              onClose={() => setSuccessDelete(false)}
          />

          <Toast
              color={isSuccessEdit === 'error' ? 'red' : 'green'}
              text={isSuccessEdit === 'error' ? 'File Gagal Di Update' : 'File Berhasil Di Update'}
              isopen={isSuccessEdit === 'success' || isSuccessEdit === 'error'}
              onClose={() => setIsSuccessEdit(false)}
          />

          <Toast
              color={successOrError === 'error' ? 'red' : 'green'}
              text={successOrError === 'error' ? 'File Gagal Di Unduh' : 'File Berhasil Di Unduh'}
              isopen={successOrError === 'success' || successOrError === 'error'}
              onClose={() => setSuccessOrError(false)}
          />

          <Modal isOpen={isLoadingDownload}>
              <div
                  className={Styles.loadingDonwload}
                  style={{ padding: '1rem', borderRadius: '8px', background: 'white' }}>
                  loading...
              </div>
          </Modal>

          <Modal isOpen={isEdit}>
              <section className={Styles.lastUploadDocument}>
                  <div className={Styles.headerEdit}>
                      <h3>Ganti File Lampiran {fileName}</h3>
                      <Icon
                          icon={'close-circle'}
                          color={'black'}
                          size={'14'}
                          onClick={() => setIsEdit(false)}
                          style={{ cursor: 'pointer' }}
                      />
                  </div>
                  <section className={Styles.wrapper}>
                      <div className={Styles.upload}>
                          <div className={Styles.card1}>
                              <Icon icon={'document-upload'} size={'48'} color={'#2E3192'} />
                              <h2>Unggah Dokumen</h2>
                              <span>Unggah file dengan format dokumen PDF atau JPEG</span>
                              <Button
                                  style={{ background: 'white', color: '#2E3192' }}
                                  isborder
                                  text="Browse File"
                                  onClick={() => {
                                      reffUploadDocument?.current?.click();
                                  }}
                              />
                              <input
                                  type="file"
                                  hidden
                                  accept=".pdf, .jpeg"
                                  ref={reffUploadDocument}
                                  onChange={handleUploadFileStaffing}
                              />
                          </div>
                          {docStaffing?.name && (
                              <div className={Styles.uploading}>
                                  <div className={Styles.between}>
                                      <div className={Styles.iconFolder}>
                                          <Icon icon={'folder'} color={'#2E3192'} size={'24'} />
                                      </div>
                                      <div className={Styles.wrapperProgress}>
                                          <span className={Styles.name}> {docStaffing?.name}</span>
                                          <span className={Styles.fileSize}>{formatBytes(docStaffing?.size)}</span>
                                          <div className={Styles.loading}>
                                              <div className={Styles.wrapperUploader}>
                                                  <div
                                                      className={Styles.progress}
                                                      style={{
                                                          width: `${progressDocStaffing || 0}%`,
                                                      }}></div>
                                              </div>
                                              <span>
                                                  {progressDocStaffing >= 100 ? 100 : progressDocStaffing}
                                                  {progressDocStaffing >= 100 ? '' : '%'}
                                              </span>
                                          </div>
                                      </div>
                                      <div className={Styles.closeIcon} onClick={() => setDocStaffing(null)}>
                                          <Icon
                                              icon={'close-circle-delete'}
                                              color={'#3E4856'}
                                              size={'16'}
                                              alt="close"
                                          />
                                      </div>
                                  </div>
                              </div>
                          )}
                      </div>
                  </section>
                  <div style={{ display: 'flex', gap: '12px' }} className={Styles.buttonEdit}>
                      <Button
                          text="Kembali"
                          onClick={() => setIsEdit(false)}
                          isBorder
                          style={{ background: 'white', color: '#2e3192' }}
                      />
                      <Button text="Simpan" onClick={() => handleEditFile(isEdit)} />
                  </div>
              </section>
          </Modal>

          {isShowFile && (
              <FileViewerModal
                  isOpen={isShowFile}
                  onClose={() => {
                      setIsShowFile(false);
                      setViewFile({});
                  }}
                  fileType={viewFile?.type}
                  fileUrl={viewFile?.url}
                  fileName={viewFile?.url?.split('/')?.pop()}
                  hideOnMobile
              />
          )}
          <div className={Styles.wrapperTabs}>
              <Tabs listTabs={tabs} isActive={isActiveTabs} setIsActive={setIsActiveTabs} />
              <div>
                  <Button
                      text={isContractType ? 'Unduh' : 'Edit Data'}
                      style={
                          isChecked.length === 0 && isActiveTabs === 3
                              ? {
                                    background: 'white',
                                    borderColor: '#CDD2DB',
                                    color: '#A9B3C1',
                                }
                              : { background: 'white', color: '#2E3192' }
                      }
                      isBorder
                      isAddButton
                      colorIcon={isChecked.length === 0 && isActiveTabs === 3 ? '#A9B3C1' : '#2E3192'}
                      icon={isContractType ? 'download' : 'edit-2'}
                      isLeftIcon
                      isDisabled={isChecked.length === 0 && isActiveTabs === 3}
                      onClick={
                          isContractType ? () => handleDownload() : () => navigate(`/people/edit/${employeeIDCorp}`)
                      }
                  />
              </div>
          </div>
          {isActiveTabs === 0 ? (
              <div className={Styles.personalData}>
                  <Fragment>
                      <div className={Styles.headerSticky}>Data Pribadi</div>
                      <div className={Styles.lineSeparatorPersonal}></div>

                      <div className={Styles.wrapperInformation}>
                          <div className={Styles.info}>
                              <span>Nama</span>
                              <span className={Styles.separator}>:</span>
                              <p>{dataNames.name}</p>
                          </div>
                          <div className={Styles.info}>
                              <span>Nomor Handphone</span>
                              <span className={Styles.separator}>:</span>
                              <p>{dataNames.phoneNumber}</p>
                          </div>
                          <div className={Styles.info}>
                              <span>Email</span>
                              <span className={Styles.separator}>:</span>
                              <p>{dataNames.email}</p>
                          </div>
                          <div className={Styles.info}>
                              <span>KTP</span>
                              <span className={Styles.separator}>:</span>
                              <p>{dataNames.idNumber}</p>
                          </div>
                          <div className={Styles.info}>
                              <span>Masa Berlaku KTP</span>
                              <span className={Styles.separator}>:</span>
                              <p>
                                  {dataToTap?.idNumberExp?.toLowerCase() === 'seumur hidup' || (moment(dataToTap?.idNumberExp, moment.ISO_8601, true).isValid() && moment(dataToTap?.idNumberExp).isSame('2999-12-31', 'day'))
                                      ? 'Seumur Hidup'
                                      : dataToTap?.idNumberExp 
                                      ? moment(dataToTap?.idNumberExp).format('YYYY MMMM DD')
                                      : '-'}
                              </p>
                          </div>
                          <div className={Styles.info}>
                              <span>Tempat, Tanggal Lahir</span>
                              <span className={Styles.separator}>:</span>
                              <p>{dataNames.placeOfBirth}</p>
                          </div>
                          <div className={Styles.info}>
                              <span>Jenis Kelamin</span>
                              <span className={Styles.separator}>:</span>
                              <p>{dataNames.gender}</p>
                          </div>
                          <div className={Styles.info}>
                              <span>Agama</span>
                              <span className={Styles.separator}>:</span>
                              <p>{dataToTap?.religion}</p>
                          </div>
                          <div className={Styles.info}>
                              <span>Tinggi badan</span>
                              <span className={Styles.separator}>:</span>
                              <p>{dataToTap?.bodyHeight}</p>
                          </div>
                          <div className={Styles.info}>
                              <span>Golongan darah</span>
                              <span className={Styles.separator}>:</span>
                              <p>{dataToTap?.bloodType}</p>
                          </div>
                          <div className={Styles.info}>
                              <span>Status Kawin</span>
                              <span className={Styles.separator}>:</span>
                              <p>{dataToTap?.maritalStatus}</p>
                          </div>
                          <div className={Styles.info}>
                              <span>NPWP</span>
                              <span className={Styles.separator}>:</span>
                              <p>{dataToTap?.taxNumber}</p>
                          </div>
                          <div className={Styles.info}>
                              <span>Nama Ibu</span>
                              <span className={Styles.separator}>:</span>
                              <p>{dataToTap?.motherName}</p>
                          </div>
                      </div>
                  </Fragment>
                  <Fragment>
                      <div className={Styles.headerSticky}>Alamat KTP</div>
                      <div className={Styles.lineSeparatorPersonal}></div>
                      <div className={Styles.wrapperInformation}>
                          <div className={Styles.info}>
                              <span>Detail Alamat</span>
                              <span className={Styles.separator}>:</span>
                              <p>{dataToTap?.address}</p>
                          </div>
                          <div className={Styles.info}>
                              <span>RT</span>
                              <span className={Styles.separator}>:</span>
                              <p>{dataToTap?.neighborhood}</p>
                          </div>
                          <div className={Styles.info}>
                              <span>RW</span>
                              <span className={Styles.separator}>:</span>
                              <p>{dataToTap?.ward}</p>
                          </div>
                          <div className={Styles.info}>
                              <span>Kelurahan</span>
                              <span className={Styles.separator}>:</span>
                              <p>{dataToTap?.subDistrict || '-'}</p>
                          </div>
                          <div className={Styles.info}>
                              <span>Kecamatan</span>
                              <span className={Styles.separator}>:</span>
                              <p>{dataToTap?.district || '-'}</p>
                          </div>
                          <div className={Styles.info}>
                              <span>Kota</span>
                              <span className={Styles.separator}>:</span>
                              <p>{dataToTap?.city}</p>
                          </div>
                          <div className={Styles.info}>
                              <span>Provinsi</span>
                              <span className={Styles.separator}>:</span>
                              <p>{dataToTap?.province || '-'}</p>
                          </div>
                          <div className={Styles.info}>
                              <span>Kode POS</span>
                              <span className={Styles.separator}>:</span>
                              <p>{dataToTap?.postal}</p>
                          </div>
                      </div>
                  </Fragment>
                  <Fragment>
                      <div className={Styles.headerSticky}>Alamat Domisili</div>
                      <div className={Styles.lineSeparatorPersonal}></div>
                      <div className={Styles.wrapperInformation}>
                          <div className={Styles.info}>
                              <span>Detail Alamat</span>
                              <span className={Styles.separator}>:</span>
                              <p>{dataToTap?.domicileAddress}</p>
                          </div>
                          <div className={Styles.info}>
                              <span>RT</span>
                              <span className={Styles.separator}>:</span>
                              <p>{dataToTap?.domicileNeighborhood}</p>
                          </div>
                          <div className={Styles.info}>
                              <span>RW</span>
                              <span className={Styles.separator}>:</span>
                              <p>{dataToTap?.domicileWard}</p>
                          </div>
                          <div className={Styles.info}>
                              <span>Kelurahan</span>
                              <span className={Styles.separator}>:</span>
                              <p>{dataToTap?.domicileSubDisctrict || '-'}</p>
                          </div>
                          <div className={Styles.info}>
                              <span>Kecamatan</span>
                              <span className={Styles.separator}>:</span>
                              <p>{dataToTap?.domicileDistrict || '-'}</p>
                          </div>
                          <div className={Styles.info}>
                              <span>Kota</span>
                              <span className={Styles.separator}>:</span>
                              <p>{dataToTap?.domicileCity}</p>
                          </div>
                          <div className={Styles.info}>
                              <span>Provinsi</span>
                              <span className={Styles.separator}>:</span>
                              <p>{dataToTap?.domicileProvince || '-'}</p>
                          </div>
                          <div className={Styles.info}>
                              <span>Kode POS</span>
                              <span className={Styles.separator}>:</span>
                              <p>{dataToTap?.domicilePostal}</p>
                          </div>
                      </div>
                  </Fragment>

                  <Fragment>
                      <div className={Styles.headerSticky}>Data Keluarga & Kontak</div>
                      <div className={Styles.lineSeparatorPersonal}></div>

                      <div className={Styles.wrapperInformation}>
                          <div className={Styles.info}>
                              <span>Nomor KK</span>
                              <span className={Styles.separator}>:</span>
                              <p>{dataToTap?.familyCard}</p>
                          </div>
                          {dataNames?.comprehensive?.emergencyContactName && (
                              <>
                                  <div className={Styles.info}>
                                      <strong className={Styles.labelName}>Kontak Darurat</strong>
                                  </div>
                                  <div className={Styles.info}>
                                      <span>Nama</span>
                                      <span className={Styles.separator}>:</span>
                                      <p>{dataNames?.comprehensive?.emergencyContactName || '-'}</p>
                                  </div>
                                  <div className={Styles.info}>
                                      <span>Hubungan kontak darurat</span>
                                      <span className={Styles.separator}>:</span>
                                      <p>{dataNames?.comprehensive?.emergencyContactRelation || '-'}</p>
                                  </div>
                                  <div className={Styles.info}>
                                      <span>Nomor Handphone</span>
                                      <span className={Styles.separator}>:</span>
                                      <p>{dataNames?.comprehensive?.emergencyContactNumber || '-'}</p>
                                  </div>
                                  <div className={Styles.info}>
                                      <span>Alamat</span>
                                      <span className={Styles.separator}>:</span>
                                      <p>{dataNames?.comprehensive?.emergencyContactAddress || '-'}</p>
                                  </div>
                              </>
                          )}
                          {dataNames?.emergencyContact?.length >= 1 && (
                              <>
                                  <div className={Styles.info}>
                                      <strong className={Styles.labelName}>Kontak Darurat Serumah</strong>
                                  </div>
                                  <div className={Styles.info}>
                                      <span>Nama</span>
                                      <span className={Styles.separator}>:</span>
                                      <p>{dataNames?.emergencyContact[0]?.name || '-'}</p>
                                  </div>
                                  <div className={Styles.info}>
                                      <span>Hubungan kontak darurat</span>
                                      <span className={Styles.separator}>:</span>
                                      <p>{dataNames?.emergencyContact[0]?.relation || '-'}</p>
                                  </div>
                                  <div className={Styles.info}>
                                      <span>Nomor Handphone</span>
                                      <span className={Styles.separator}>:</span>
                                      <p>{dataNames?.emergencyContact[0]?.phoneNumber || '-'}</p>
                                  </div>
                                  <div className={Styles.info}>
                                      <span>Alamat</span>
                                      <span className={Styles.separator}>:</span>
                                      <p>{dataNames?.emergencyContact[0]?.address || '-'}</p>
                                  </div>
                                  <div className={Styles.info}>
                                      <strong className={Styles.labelName}>Kontak Keluarga Tidak Serumah</strong>
                                  </div>
                                  <div className={Styles.info}>
                                      <span>Nama</span>
                                      <span className={Styles.separator}>:</span>
                                      <p>{dataNames?.emergencyContact[1]?.name || '-'}</p>
                                  </div>
                                  <div className={Styles.info}>
                                      <span>Hubungan kontak darurat</span>
                                      <span className={Styles.separator}>:</span>
                                      <p>{dataNames?.emergencyContact[1]?.relation || '-'}</p>
                                  </div>
                                  <div className={Styles.info}>
                                      <span>Nomor Handphone</span>
                                      <span className={Styles.separator}>:</span>
                                      <p>{dataNames?.emergencyContact[1]?.phoneNumber || '-'}</p>
                                  </div>
                                  <div className={Styles.info}>
                                      <span>Alamat</span>
                                      <span className={Styles.separator}>:</span>
                                      <p>{dataNames?.emergencyContact[1]?.address || '-'}</p>
                                  </div>
                              </>
                          )}
                      </div>
                  </Fragment>
                  <Fragment>
                      <div className={Styles.lineSeparatorPersonal}></div>
                      <div className={Styles.wrapperInformation}>
                          <div className={Styles.info}>
                              <strong className={Styles.labelName}>Data Bank</strong>
                          </div>
                          {/* <div className={Styles.info}>
                              <span>Nama Pemilik Rekening</span>
                              <span className={Styles.separator}>:</span>
                              <p>{dataNames.bankAccountName}</p>
                          </div> */}
                          <div className={Styles.info}>
                              <span>No. Rekening</span>
                              <span className={Styles.separator}>:</span>
                              <p>{dataNames.bankAccountNumber}</p>
                          </div>
                          <div className={Styles.info}>
                              <span>Sosial Media</span>
                          </div>
                          <div className={Styles.info}>
                              <span>Facebook</span>
                              <span className={Styles.separator}>:</span>
                              <p>{dataToTap?.facebook || '-'}</p>
                          </div>
                          <div className={Styles.info}>
                              <span>Instagram</span>
                              <span className={Styles.separator}>:</span>
                              <p>{dataToTap?.instagram || '-'}</p>
                          </div>
                          <div className={Styles.info}>
                              <span>X</span>
                              <span className={Styles.separator}>:</span>
                              <p>{dataToTap?.twitter || '-'}</p>
                          </div>
                          <div className={Styles.info}>
                              <span>Linkedin</span>
                              <span className={Styles.separator}>:</span>
                              <p>{dataToTap?.linkedin || '-'}</p>
                          </div>
                          <div className={Styles.info}>
                              <strong className={Styles.labelName}>Lainnya</strong>
                          </div>
                          <div className={Styles.info}>
                              <span>Hobi</span>
                              <span className={Styles.separator}>:</span>
                              <p>{dataToTap?.hobby || '-'}</p>
                          </div>
                          <div className={Styles.info}>
                              <span>Klinik/Puskesmas Terdekat</span>
                              <span className={Styles.separator}>:</span>
                              <p>{dataToTap?.nearestClinic || '-'}</p>
                          </div>
                      </div>
                  </Fragment>
              </div>
          ) : isActiveTabs === 1 ? (
              dataToTap?.length >= 1 ? (
                  <div className={Styles.dataFamily}>
                      <Fragment>
                          {dataToTap?.map((f, idx) => (
                              <Fragment key={idx}>
                                  <div className={Styles.headerSticky}>Data Keluarga {idx + 1}</div>
                                  <div className={Styles.lineSeparatorPersonal}></div>
                                  <div className={Styles.wrapperInformation}>
                                      <div className={Styles.info}>
                                          <span>Hubungan Keluarga</span>
                                          <span className={Styles.separator}>:</span>
                                          <p>{f?.relation || '-'}</p>
                                      </div>
                                      <div className={Styles.info}>
                                          <span>Nama</span>
                                          <span className={Styles.separator}>:</span>
                                          <p>{f?.name || '-'}</p>
                                      </div>
                                      <div className={Styles.info}>
                                          <span>Tempat, Tanggal Lahir</span>
                                          <span className={Styles.separator}>:</span>
                                          <p>
                                              {f?.birthPlace}, {moment(f?.birthDate).format('LL') || '-'}
                                          </p>
                                      </div>
                                      <div className={Styles.info}>
                                          <span>Nomor KTP</span>
                                          <span className={Styles.separator}>:</span>
                                          <p>{f?.idNumber || '-'}</p>
                                      </div>
                                      {/* <div className={Styles.info}>
                      <span>Jenis Kelamin</span>
                      <span className={Styles.separator}>:</span>
                      <p>{f?.gender || "-"}</p>
                    </div>
                    <div className={Styles.info}>
                      <span>Status Pekerjaan</span>
                      <span className={Styles.separator}>:</span>
                      <p>{f?.employeementStatus || "-"}</p>
                    </div> */}
                                  </div>
                              </Fragment>
                          ))}
                      </Fragment>
                  </div>
              ) : dataToTap?.length === 0 ? (
                  <div className={Styles.viewerEmptyHandlerFamily}>
                      <div>
                          <img src={EmptyDataFamily} alt="empty-handler" />
                          <p>
                              Status kawin karyawan adalah <strong>“Tidak Kawin”</strong> tidak ada data keluarga lain
                              dari karyawan yang dapat ditampilkan
                          </p>
                      </div>
                  </div>
              ) : (
                  <SkeletonDataFamilies />
              )
          ) : isActiveTabs === 2 ? (
              <div className={Styles.job}>
                  <Fragment>
                      <div className={Styles.headerSticky}>Kepegawaian</div>
                      <div className={Styles.lineSeparatorPersonal}></div>
                      <div className={Styles.wrapperInformation}>
                          <div className={Styles.info}>
                              <span>Nomor Induk Kepegawaian</span>
                              <span className={Styles.separator}>:</span>
                              <p>{dataToTap?.employeeID}</p>
                          </div>
                          <div className={Styles.info}>
                              <span>Status karyawan</span>
                              <span className={Styles.separator}>:</span>
                              {/* <p>{dataToTap?.status}</p> */}
                              <p>{dataToTap?.contractType}</p>
                          </div>
                          <div className={Styles.info}>
                              <span>Posisi</span>
                              <span className={Styles.separator}>:</span>
                              <p>{dataToTap?.position}</p>
                          </div>
                          <div className={Styles.info}>
                              <span>Tanggal bergabung</span>
                              <span className={Styles.separator}>:</span>
                              <p>{moment(dataToTap?.joinDate).format('LL')}</p>
                          </div>
                          <div className={Styles.info}>
                              <span>Tanggal mulai kontrak</span>
                              <span className={Styles.separator}>:</span>
                              <p>{moment(dataToTap?.startDate).format('LL')}</p>
                          </div>
                          <div className={Styles.info}>
                              <span>Tanggal akhir kontrak</span>
                              <span className={Styles.separator}>:</span>
                              <p>{moment(dataToTap?.endDate).format('LL')}</p>
                          </div>
                          <div className={Styles.info}>
                              <span>Penempatan Kerja</span>
                              <span className={Styles.separator}>:</span>
                              <p>{dataToTap?.area}</p>
                          </div>
                      </div>
                      {/* <div className={Styles.headerSticky}>Organisasi</div>
            <div className={Styles.lineSeparatorPersonal}></div>
            <div className={Styles.wrapperInformation}>
              <div className={Styles.info}>
                <span>Grade</span>
                <span className={Styles.separator}>:</span>
                <p>{dataToTap?.grade}</p>
              </div>
              <div className={Styles.info}>
                <span>Posisi</span>
                <span className={Styles.separator}>:</span>
                <p>{dataToTap?.position}</p>
              </div>
            </div> */}
                  </Fragment>
              </div>
          ) : (
              <Suspense fallback={<SkeletonTable />}>
                  <Table
                      isLoading={isLoading}
                      heightEmpty="44px"
                      className={Styles.tableRadius}
                      data={template.data}
                      columns={template.columns}
                      page={page}
                      limit={limit}
                      totalData={template.totalData}
                      totalPage={Math.ceil(template.totalData / limit) || 0}
                      setLimit={setLimit}
                      setPage={setPage}
                      withPagination
                      withCheckbox
                      isChecked={isChecked}
                      setIsChecked={setIsChecked}
                      expandComponent={(row) => (
                          <div className={Styles.wrapperExpand}>
                              <div className={Styles.list}>
                                  <span className={Styles.textLeft}>Tanggal Kontrak</span>
                                  {row.contractDate}
                              </div>
                              <div className={Styles.list}>
                                  <span className={Styles.textLeft}>Departement</span>
                                  <span className={Styles.textRight}>{row.departement}</span>
                              </div>
                              <div className={Styles.list}>
                                  <span className={Styles.textLeft}>Posisi</span>
                                  <span className={Styles.textRight}>{row.position}</span>
                              </div>
                              <div className={Styles.list}>
                                  <span className={Styles.textLeft}>Jenis Kontrak</span>
                                  <span className={Styles.textRight}>{row.typeContract}</span>
                              </div>
                              <span className={Styles.detail}>Lihat Detail</span>
                          </div>
                      )}
                  />
              </Suspense>
          )}
      </div>
  );
}
