import AsyncErrorPage from '@Molecule/AsyncErrorPage';
import LoadingAnimationPage from '@Molecule/LoadingAnimationPage';
import EditEmployee from '@Organism/Employee/EditEmployee';
import { getEditDetailEmployee } from '@Services/employee/detailEmployee';
import React, { Suspense } from 'react';
import { Await, defer, useLoaderData } from 'react-router-dom';

export const editEmployeeLoader = (route) => {
    const { id } = route?.params;
    return defer({
        employeeFormPackage: getEditDetailEmployee(id),
    });
};
export default function EditEmployeePage() {
    const { employeeFormPackage } = useLoaderData();

    return (
        <Suspense fallback={<LoadingAnimationPage />}>
            <Await
                resolve={employeeFormPackage}
                errorElement={<AsyncErrorPage />}
                children={(data) => <EditEmployee getData={data?.data} />}></Await>
        </Suspense>
    );
}
