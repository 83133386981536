import { APIMWS } from "configs";

export async function fetchListsBank(token) {
  try {
    const response = await APIMWS.get(`/bank`, {
      headers: {
        Authorization: token,
      },
    });
    return response;
  } catch (error) {
    console.log(error);
  }
}
export async function fetchLoanStatus(token) {
  try {
    const response = await APIMWS.get(`/edu-loan/status`, {
      headers: {
        Authorization: token,
      },
    });
    return response;
  } catch (error) {
    console.log(error);
  }
}
