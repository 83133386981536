import AsyncErrorPage from "@Molecule/AsyncErrorPage";
import { getFeedbackDetails } from "@Services/feedback/getFeedbackDetails";
import { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

const { default: FeedbackDetailsLayout } = require("@Organism/Feedback/FeedbackDetails")

export const feedbackDetailsLoader = (route) => {
  const { id } = route?.params;

  return defer({
    packageDetails: getFeedbackDetails(id)
  })

}

export default function FeedbackDetailsPage() {
  const { packageDetails } = useLoaderData()

  return (
    <Suspense
      fallback={
        <FeedbackDetailsLayout
          isLoading
        />
      }
    >
      <Await
        resolve={packageDetails}
        errorElement={
          <AsyncErrorPage height="calc(100vh - 56px)" />
        }
      >
        {(packageDetails) => (
          <FeedbackDetailsLayout
            packageDetails={packageDetails?.data?.response[0]}
          />
        )}
      </Await>

    </Suspense>
  )
}