import { useEffect, useMemo, useRef, useState } from 'react';
import Styles from './style.module.scss';
import Images from '@Theme/Images';
import InputTitle from '@Atom/InputTitle';
import useOutsideClick from '@Hooks/useOutsideClick';

export default function PeopleSearchbar({
  title = '',
  required = false,
  requiredLabel = '',
  value,
  setValue = () => { },
  placeholder = 'Search',
  options,
}) {
  const [search, setSearch] = useState(value?.name || '');
  const [showPicture, setShowPicture] = useState(!!value)
  const [isFocused, setIsFocused] = useState(false)
  const [expand, setExpand] = useState(false);
  const [expandAll, setExpandAll] = useState(true)
  const ref = useRef()
  const outsideClick = useOutsideClick(ref)
  const [disableEmpty, setDisableEmpty] = useState(true)

  useEffect(() => {
    if (expand && !isFocused && outsideClick) {
      setExpand(false)
    }
  }, [expand, isFocused, outsideClick])

  useEffect(() => {
    if (!search && value?.name && disableEmpty) setSearch(value?.name || '')
  }, [disableEmpty, search, value?.name]);

  useEffect(() => {
    if (!expand) setExpandAll(true)
  }, [expand])

  const filteredOptions = useMemo(() => {
    if (expandAll) return options
    return options?.filter((obj) =>
      obj?.name?.toLowerCase()?.includes(search.toLowerCase())
    );
  }, [expandAll, options, search]);

  return (
    <div className={Styles.container}>
      {title && (
        <InputTitle
          title={title}
          required={required}
          requiredLabel={requiredLabel}
        />
      )}
      <div className={Styles.content}>
        {
          value && showPicture
          &&
          <img className={Styles.img} src={value?.picture || Images.AVA_DEFAULT} alt='' />
        }
        <input
          value={search}
          placeholder={placeholder}
          onChange={(e) => {
            setShowPicture(false)
            setExpand(true)
            setExpandAll(false)
            setSearch(e.target.value || '')
            setDisableEmpty(false)
          }} // Ensure input value is a string
          onFocus={() => {
            setExpand(true)
            setIsFocused(true)
          }}
          onBlur={() => {
            const found = search
              ? options?.find(
                (obj) => obj?.name?.toLowerCase() === search.toLowerCase()
              )
              : null;
            if (found) {
              setValue(found);
            } else {
              setValue(null);
            }
            setIsFocused(false);
          }}
          className={`${Styles.input} ${Styles[value && showPicture ? 'valued' : '']}`}
        />
        {expand && (
          <div ref={ref} className={Styles.options}>
            {filteredOptions?.map((option, i) => (
              <div
                key={i}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  setExpand(false)
                  setValue(option);
                  setSearch(option?.name || ''); // Ensure search is updated as a string
                  setShowPicture(true)
                }}
                className={`${Styles.option} ${Styles[value === option ? 'active' : '']}`}
              >
                <img
                  src={option?.picture || Images.AVA_DEFAULT}
                  alt=""
                />
                <span>{option?.name}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
