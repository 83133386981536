import React, { useRef, useState } from 'react';
import Styles from './style.module.scss';
import Modal from '@Atom/Modal';
import Icon from '@Atom/Icon';
import Button from '@Atom/Button';
import ExcelIcon from '@Assets/Images/Excel-Icon.png';
import { formatBytes } from 'helpers/formatBytes';
import Toast from '@Atom/Toast';
import Lottie from 'lottie-react';
import svgUploadingDoc from '@Assets/json/uploading.json';
import { downloadExcel } from '@Helpers/downloadExcel';
import ExcelJS from 'exceljs';
import { updateBPJSTKByXlsx } from '@Services/bpjs';
import moment from 'moment';

export default function ModalUploadBPJSTK({ file, setFile = () => {}, isOpen, onClose = () => {} }) {
    const [isLoadingPost, setLoadingPost] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isFailed, setIsFailed] = useState(false);
    const [notUpdatedData, setNotUpdatedData] = useState()

    const ref = useRef();
    const handleUploadFile = () => {
        ref?.current?.click();
    };
    const downloadTemplateExcel = () => {
        const columns = [
            {
                header: 'NIK Karyawan',
                key: 'idNumber',
                width: 25,
            },
            {
                header: 'Nama Karyawan',
                key: 'name',
                width: 25,
            },
            {
                header: 'Nomor BPJS TK',
                key: 'numberBpjsTK',
                width: 25,
            },
            {
                header: 'Nomor BPJS JP',
                key: 'numberBpjsJP',
                width: 25,
            }
        ];

        const rows = [
            {
                idNumber: '3328000000000001',
                name: 'Employee Name',
                numberBpjsTK: '000111222333',
                numberBpjsJP: '000222333444'
            },
        ];

        downloadExcel(columns, rows, `Template-Upload-Update-BPJS_TK.xlsx`, true);
    };

    const handleDownloadNotUpdated = () => {
        const columns = [
            {
                header: 'NIK Karyawan',
                key: 'idNumber',
                width: 25,
            },
            {
                header: 'Nama Karyawan',
                key: 'name',
                width: 25,
            },
            {
                header: 'Nomor BPJS TK',
                key: 'numberBpjsTK',
                width: 25,
            },
            {
                header: 'Nomor BPJS JP',
                key: 'numberBpjsJP',
                width: 25,
            },
            {
                header: 'Catatan',
                key: 'memo',
                width: 70,
            },
        ]

        const rows = notUpdatedData

        downloadExcel(columns, rows, `${moment().format('DD-MM-YYYY:HH:mm:ss')}-Data-BPJS_TK-Tidak_Terupdate.xlsx`, true);
    }

    // const processExcelData = (rows) => {
    //     const groupedData = {};

    //     rows.forEach((row) => {
    //         const { idNumber, name, familyIdNumber, familyName, relation, numberBpjsks, healthFacility } = row;

    //         if (!groupedData[idNumber]) {
    //             groupedData[idNumber] = {
    //                 idNumber: `${idNumber}`,
    //                 name,
    //                 families: [],
    //             };
    //         }

    //         groupedData[idNumber].families.push({
    //             familyIdNumber: `${familyIdNumber}`,
    //             name: familyName,
    //             relation,
    //             number: `${numberBpjsks}`,
    //             healthFacility,
    //         });
    //     });

    //     return Object.values(groupedData);
    // };

    const handleUploadExcel = async () => {
        setLoadingPost(true);

        if (file) {
            const workbook = new ExcelJS.Workbook();
            const reader = new FileReader();

            reader.onload = async (e) => {
                const buffer = e.target.result;
                await workbook.xlsx.load(buffer);

                const sheet = workbook.worksheets[0]; // Mengambil sheet pertama
                const rows = [];

                sheet.eachRow((row, rowIndex) => {
                    if (rowIndex > 1) {
                        // Mengabaikan header (baris pertama)
                        rows.push({
                            idNumber: `${row.getCell(2).value}`,
                            name: `${row.getCell(3).value}`,
                            numberBpjsTK: `${row.getCell(4).value}`,
                            numberBpjsJP: `${row.getCell(5).value}`,
                        });
                    }
                });

                // const formattedData = processExcelData(rows);                
                await runUpdateBPJSTK(rows);
            };

            reader.readAsArrayBuffer(file);
        }
        
    };

    const runUpdateBPJSTK = async (jsonData) => {
        try {
            if (jsonData?.length > 0) {
                const response = await updateBPJSTKByXlsx({ data: jsonData });
                if (response.status === 201) {
                    setLoadingPost(false);
                    setIsSuccess(true);

                    if (response.response?.dataNotUpdated?.length > 0){
                      setNotUpdatedData(response.response.dataNotUpdated)
                    }
                } else {
                    setLoadingPost(false);
                    setIsFailed(true);
                }
            } else {
                setLoadingPost(false);
                setIsFailed(true);
            }
        } catch (error) {
            console.log(error);
            setLoadingPost(false);
            setIsFailed(true);
        }
    }

    setTimeout(() => {
        if (isSuccess) {
            setIsSuccess(false);
        }
        if (isFailed) {
            setIsFailed(false);
        }
    }, 5000);
    return (
        <>
            <Modal isOpen={isLoadingPost}>
                <div className={Styles.uploadingDocuments}>
                    <div className={Styles.header}>
                        <Icon icon={'document-upload'} color={'#2E3192'} size={'24'} className={Styles.iconHead} />
                        <span>Sedang Proses Upload Data BPJS</span>
                    </div>
                    <div className={Styles.lottieWrap}>
                        <Lottie animationData={svgUploadingDoc} className={Styles.lottie} loop={true} />
                    </div>
                    <div className={Styles.footContain}>
                        Mohon jangan tutup halaman ini, dan tunggu hingga proses upload data BPJS selesai
                    </div>
                </div>
            </Modal>

            <Modal isOpen={notUpdatedData?.length > 0}>
                <div className={Styles.notUpdatedContainer}>
                    <div className={Styles.header}>
                        <div className={Styles.between}>
                            <Icon icon={'warning-2'} color={'#eed202'} size={'24'} />
                            <span>Data BPJS TK Yang Tidak Terupdate</span>
                        </div>
                        <Button
                          isNoBorder
                          isOutlined
                          colorIcon="#3E4856"
                          iconOnly
                          icon="close-circle"
                          onClick={() => {
                            setNotUpdatedData(null)
                          }}
                          iconSize={'20px'}
                      />
                    </div>
                    <div className={Styles.body}>
                          {notUpdatedData?.map((item, idx) => (
                            <>
                              <div key={idx} className={Styles.itemDataNotUpdated}>
                                  <span>{item?.name} - {item?.idNumber}</span>
                                  <span>{item?.memo || 'Tidak diketahui'}</span>
                              </div>
                              {notUpdatedData?.length - 1 === idx ? '' : <div className={Styles.divider}/>}
                            </>
                          ))}
                    </div>
                    <div className={Styles.buttonDownload}>
                        <Button 
                            text='Unduh Data'
                            isOutlined
                            onClick={() => {
                                handleDownloadNotUpdated()
                            }}
                        />
                    </div>

                </div>
            </Modal>

            <Toast
                color="green"
                text="Berhasil Mengupdate Data BPJS"
                isopen={isSuccess}
                onClose={() => setIsSuccess(false)}
            />
            <Toast color="red" text="Gagal Mengupdate Data BPJS" isopen={isFailed} onClose={() => setIsFailed(false)} />
            <Modal isOpen={isOpen}>
                <div className={Styles.containerModalIsEmpty}>
                    <div className={Styles.headerModal}>
                        <div className={Styles.between}>
                            <Icon icon={'document-upload'} color={'#2E3192'} size={'24'} />
                            <h2>Upload Data BPJS TK (TKO Only)</h2>
                        </div>
                        <Icon icon={'close-circle'} className={Styles.iconCloseModal} onClick={onClose} />
                    </div>
                    <div className={Styles.descriptionModal}>
                        <p>Silahkan gunakan template berikut untuk upload data BPJS</p>
                    </div>
                    <div className={Styles.textUploadModal} onClick={() => downloadTemplateExcel()}>
                        <div className={Styles.headerModal}>
                            <div className={Styles.between}>
                                <div className={Styles.excelIcon}>
                                    <img src={ExcelIcon} alt="excel-icon" />
                                </div>
                                <h2>Template Excel Data BPJS TK</h2>
                            </div>
                            <Icon icon={'download'} className={Styles.iconDownload} />
                            <input type="file" hidden />
                        </div>
                    </div>
                    <div className={Styles.uploadModal} onClick={handleUploadFile}>
                        {file ? (
                            <div className={Styles.excelIcon}>
                                <img src={ExcelIcon} alt="excel-icon" />
                            </div>
                        ) : (
                            <Icon
                                icon={'document-upload'}
                                color={'#2E3192'}
                                size={'48'}
                                className={Styles.uploadDoc}
                                style={file ? { background: '#F0F0FF', padding: '8px' } : undefined}
                            />
                        )}
                        {!file?.name ? <h2>Unggah Dokumen</h2> : ''}
                        <p style={file?.name ? { color: '#575361', fontWeight: '500' } : undefined}>
                            {' '}
                            {file?.name ? file?.name : 'Unggah dengan file .xlsx atau .csv'}{' '}
                        </p>
                        {file?.size && <p> {formatBytes(file?.size)} </p>}
                        <Button text={file ? 'Ubah File' : 'Browse File'} isOutlined color={'#2E3192'} />
                        <input
                            type="file"
                            ref={ref}
                            hidden
                            accept=".xlsx , .csv"
                            onChange={(e) => setFile(e.target.files[0])}
                        />
                    </div>
                    <div className={Styles.buttonModal}>
                        <Button text="Kembali" isOutlined onClick={onClose} color={'#2E3192'} />
                        <Button
                            text="Upload"
                            isDisabled={!file ? true : false}
                            onClick={() => {
                                handleUploadExcel();
                                onClose(!isOpen);
                            }}
                        />
                    </div>
                </div>
            </Modal>
        </>
    );
}
