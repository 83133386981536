import React, { useEffect, useState } from "react";
import Styles from "./style.module.scss";
import Images from "@Theme/Images";
import { LoanContext } from "Context/LoanContext";
import { fetchListsBank, fetchLoanStatus } from "@Services/mws";
import useQuery from "@Hooks/useQuery";

export const omitKeys = (keys = [], obj) => {
  return (
    Object.keys(obj)
      .filter((k) => !keys.includes(k))
      // eslint-disable-next-line no-sequences
      .reduce((acc, key) => ((acc[key] = obj[key]), acc), {})
  );
};

const defaultValue = {
  bank: "",
  accountNumber: "",
  accountHolderName: "",
  paymentProof: "",
  contactName: "",
  contactPhoneNumber: "",
  tenor: "",
  paymentRequest: 0,
};
export default function WebViewMWSLayout({ children }) {
  const [state, setState] = useState(
    JSON.parse(sessionStorage.getItem("loanState")) || defaultValue
  );
  const [userData, setUserData] = useState(null);
  const query = useQuery();
  const token = query.get("token");

  useEffect(() => {
    let temp = { ...state };
    sessionStorage.setItem(
      "loanState",
      JSON.stringify(omitKeys("paymentProof", temp))
    );
  }, [state]);

  return (
    <div className={Styles.container}>
      <div className={Styles.layout}>
        <img alt="" src={Images.BUBBLE_BACKGROUND} />
        <LoanContext.Provider
          value={{ state, setState, token, userData, setUserData }}
        >
          <div className={Styles.content}>{children}</div>
        </LoanContext.Provider>
      </div>
    </div>
  );
}
