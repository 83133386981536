import Modal from '@Atom/Modal'
import Styles from './style.module.scss'
import Button from '@Atom/Button'
import Icon from '@Atom/Icon'

export default function PreventNavModal({
  show,
  onClose,
  onExecute = () => { }
}) {
  return (
    <Modal
      isOpen={show}
      onClose={onClose}
    >
      <div className={Styles.container}>
        <div className={Styles.header}>
          <Icon icon={'warning-2'} size={24} color={'#D42701'}/>
          <h3>Terdapat Data yang Belum Disimpan</h3>
        </div>
        <span>Apakah kamu yakin ingin keluar dari halaman ini, terdapat data yang belum kamu simpan.</span>
        <div className={Styles.actions}>
          <Button
            text='Kembali'
            onClick={onClose}
            isOutlined
          />
          <Button
            text='Ya, keluar'
            onClick={onExecute}
          />
        </div>
      </div>

    </Modal>
  )
}