import AsyncErrorPage from "@Molecule/AsyncErrorPage";
import ManagerListLayout from "@Organism/Manager/ManagerList";
import { getCategories } from "@Services/manager/getCategories";
import { getManagers } from "@Services/manager/getManagers";
import { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export const managerListLoader = (route) => {
  const url = new URL(route?.request?.url);
  const searchParams = url.searchParams

  const type = searchParams?.get('t') || 'eksternal'
  return defer({
    packageManagers: getManagers(),
    packageCategories: getCategories(type)
  })
}

export default function ManagerListPage() {
  const { packageManagers, packageCategories } = useLoaderData()

  return (
    <Suspense
      fallback={
        <ManagerListLayout isLoadingCategories isLoadingManagers />
      }
    >
      <Await
        resolve={packageCategories}
        errorElement={
          <AsyncErrorPage height="calc(100vh - 56px)" />
        }
      >
        {(packageCategories) => (
          <Suspense
            fallback={<ManagerListLayout
              packageCategories={packageCategories?.data?.response}
              isLoadingManagers
            />}
          >
            <Await
              resolve={packageManagers}
              errorElement={
                <AsyncErrorPage height="calc(100vh - 56px)" />
              }
            >
              {(packageManagers) => (
                <ManagerListLayout
                  packageCategories={packageCategories?.data?.response}
                  packageManagers={packageManagers?.data?.response}
                />
              )}

            </Await>
          </Suspense>
        )}

      </Await>

    </Suspense>
  )
}