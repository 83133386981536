import { useEffect, useRef, useState } from 'react';
import Styles from './style.module.scss';

export default function OTPInput({ length = 4, value = '', setValue = () => {},className="" }) {
  const inputRefs = useRef([]);

  // Temporary value as an array
  const [tempValue, setTempValue] = useState(Array(length).fill(''));

  // Handle input changes
  const handleChange = (e, index) => {
    const digit = e.target.value;

    // Only allow empty or single digits
    if (!/^\d?$/.test(digit)) return;

    // Update the temporary value array
    const newTempValue = [...tempValue];
    newTempValue[index] = digit; // Set the digit at the specific index
    setTempValue(newTempValue);

    // Convert the array to a string and update the parent state
    setValue(newTempValue.join(''));

    // Move focus to the next input if a digit is entered
    if (digit && index < length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  // Handle backspace behavior
  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      // Clear the current input without affecting others
      const newTempValue = [...tempValue];

      // Check if the current input is empty
      if (newTempValue[index] === '') {
        // Move focus to the previous input if the current input is empty and it's not the first input
        if (index > 0) {
          inputRefs.current[index - 1]?.focus(); // Move focus to the previous input
        }
      } else {
        // Clear the current input
        newTempValue[index] = ''; 
      }

      setTempValue(newTempValue);

      // Convert the array to a string and update the parent state
      setValue(newTempValue.join(''));
    }
  };

  // Autofocus the first input on load
  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, []);

  return (
    <div className={Styles.container}>
      {Array.from({ length }).map((_, index) => (
        <input
          key={index}
          type="text"
          inputMode="numeric" // Mobile-friendly numeric keyboard
          maxLength="1"
          value={tempValue[index]} // Show the correct character or empty input
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          ref={(el) => (inputRefs.current[index] = el)}
          className={`${Styles.input} ${className}`}
        />
      ))}
    </div>
  );
}
