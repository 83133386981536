import ManagerCard from '@Molecule/ManagerCard'
import { useEffect, useMemo, useState } from 'react'
import Styles from './style.module.scss'
import { ticketCategories } from '@Assets/data/ticketCategories'
import { useLocation, useNavigate } from 'react-router-dom'
import useQuery from '@Hooks/useQuery'
import Images from '@Theme/Images'
import TableEmptyHandler from '@Atom/TableEmptyHandler'
import Toast from '@Atom/Toast'

export default function ManagerListLayout({
  packageManagers = [],
  packageCategories = [],
  isLoadingManagers = false,
  isLoadingCategories = false
}) {
  const query = useQuery()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [trigger, setTrigger] = useState(query?.get('tr') || '')
  const [activeTab, setActiveTab] = useState(query?.get('t') || 'eksternal')

  const [toastMessage, setToastMessage] = useState('')

  const isValueDifferent = useMemo(() => {
    if (isLoadingCategories || isLoadingManagers) return false
    return (query?.get('t') && query?.get('t') !== activeTab)
      ||
      (!query?.get('t') && activeTab === 'internal')
    // || (trigger && query?.get('tr') !== trigger)
  }, [activeTab, isLoadingCategories, isLoadingManagers, query])


  const fixedLoading = useMemo(() => {
    return isLoadingManagers || isLoadingCategories || isValueDifferent
  }, [isLoadingCategories, isLoadingManagers, isValueDifferent])


  const tabOptions = useMemo(() => {
    return [

      {
        title: 'Pengaduan Eksternal',
        value: 'eksternal',
        type: 'status',

      },
      {
        title: 'Pengaduan Internal',
        value: 'internal',
        type: 'status',

      },

    ]
  }, [])

  const [template, setTemplate] = useState([])

  const defaultTemplate = useMemo(() => {
    if (!packageCategories?.length) return []
    return [...packageCategories?.filter(obj => obj?.name !== 'Lainnya')?.map(obj => {
      return {
        ...obj,
        ...ticketCategories?.find(t => t?.label?.toLowerCase() === obj?.name?.toLowerCase())
      }
    }), ...packageCategories?.filter(obj => obj?.name === 'Lainnya')?.map(obj => {
      return {
        ...obj,
        ...ticketCategories?.find(t => t?.label?.toLowerCase() === obj?.name?.toLowerCase())
      }
    })]
  }, [packageCategories])

  useEffect(() => {
    if (!fixedLoading) setTemplate(defaultTemplate)
  }, [defaultTemplate, fixedLoading])

  useEffect(() => {
    if (isValueDifferent) {
      navigate(`${pathname}?t=${activeTab}&tr=${trigger}`, { replace: true })
    }
  }, [activeTab, fixedLoading, isValueDifferent, navigate, pathname, query, trigger])

  const managerOptions = useMemo(() => {
    if (!packageManagers?.length || fixedLoading) return []
    return packageManagers?.map(obj => {
      return {
        ...obj,
        name: obj?.user?.name + ` (${obj?.employeeId})`,
        picture: Images.AVA_DEFAULT
      }
    })
  }, [fixedLoading, packageManagers])


  useEffect(() => {
    if (fixedLoading) return
    if (template?.find(obj => obj?.preventNav)) {
      sessionStorage?.setItem('preventNav', '1')
    } else {
      if (sessionStorage?.getItem('preventNav')) {
        sessionStorage?.removeItem('preventNav')
      }
    }
  }, [fixedLoading, template])



  return (
    <div className={Styles.container}>
      <div className={Styles.containerWrapper}>
        <div className={Styles.container}>
          <div className={Styles.header}>
            <h3>Daftar Manager</h3>
            <div className={Styles.tabs}>
              {
                tabOptions
                  ?.map((tab, i) => (
                    <div key={i} className={`${Styles.tab} ${Styles[activeTab === tab?.value ? 'active' : '']}`} onClick={() => {
                      setActiveTab(tab?.value)
                    }}>
                      <span>{tab?.title}</span>
                      {
                        tab?.label &&
                        <div>{tab?.label}</div>
                      }
                    </div>
                  ))
              }

            </div>

          </div>
          {
            !fixedLoading && !template?.length
            &&
            <div className={Styles.emptyWrapper}>
              <TableEmptyHandler />
            </div>
          }
          <div className={Styles.content}>
            {
              fixedLoading

                ?
                [...Array(3)]?.map((_, i) => (
                  <ManagerCard
                    key={i}
                    isLoading
                  />
                ))
                :
                template?.map((each, i) => (
                  <ManagerCard
                    key={i}
                    options={managerOptions}
                    defaultManager={managerOptions?.find(obj => obj?.employeeId === each?.employeeId)}
                    setTrigger={setTrigger}
                    isLoading={fixedLoading}
                    preventNav={template?.preventNav}
                    setPreventNav={(newVal) => setTemplate(template?.map(obj => {
                      if (obj?._id === each?._id) {
                        return {
                          ...obj,
                          preventNav: newVal
                        }
                      } return obj
                    }))}
                    setToastMessage={setToastMessage}
                    {...each}
                  />
                ))
            }
          </div>
        </div>
      </div>
      <Toast
        color='green'
        isopen={!!toastMessage}
        text={toastMessage}
        onClose={() => setToastMessage('')}
      />
    </div>
  )
}