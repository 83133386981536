import { useLocation, useNavigate } from 'react-router-dom';
import Styles from './styles.module.scss';
import { useEffect, useMemo, useState } from 'react';
import Modal from '@Atom/Modal';
import Toast from '@Atom/Toast';
import Button from '@Atom/Button';
import Icon from '@Atom/Icon';
import { allFilledObjChecker } from '@Helpers/allFilledObjChecker';
import InputField from '@Atom/InputField';

export default function ModalAddUser({ modalAddUser, setModalAddUser }) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [toastSuccess, setToastSuccess] = useState(false);
    const [toastFailed, setToastFailed] = useState(false);
    const [optClient, setOptClient] = useState()
    const [values, setValues] = useState({
        name: '',
        phoneNumber: '',
        client: '',
        position: '',
    });

    const [errorField, setErrorField] = useState();
    const [errorMessage, setErrorMessage] = useState();

    useEffect(() => {
        setOptClient([
            'PT BNI',
            'PT SO GOOD FOOD',
            'PT PERMATA INDONESIA'
        ])
    }, [])

    const handleDisableButton = useMemo(() => {
        return !allFilledObjChecker(values) || isLoading
    }, [isLoading, values])

    const handleClose = () => {
        setModalAddUser(false);
        setValues({
            name: '',
            phoneNumber: '',
            client: '',
            position: '',
        });
    };

    const handleChange = (val, name) => {
        setValues({
            ...values,
            [name]: val,
        });
        setErrorField({
            ...errorField,
            [name]: false,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let newErrors = {};
        Object.keys(values).forEach((key) => {
            if (!values[key]) {
                newErrors[key] = true;
            }
        });

        if (Object.keys(newErrors).length > 0) {
            setErrorField(newErrors);
            return;
        }

        try {
            setIsLoading(true);

            // if (response.status === 200) {
            setIsLoading(false);
            setToastSuccess(true);
            setModalAddUser(false);
            navigate(pathname, { replace: true });
            // }
        } catch (error) {
            if (error.response) {
                setErrorMessage(error.response.data?.message || 'Terjadi kesalahan pada server.');
            } else if (error.request) {
                setErrorMessage('Tidak ada respons dari server.');
            } else {
                setErrorMessage(error.message);
            }
            setToastFailed(true);
            setIsLoading(false);
            console.error(error);
        }
    };

    return (
        <>
            <Modal isOpen={modalAddUser}>
                <div className={Styles.container}>
                    <div className={Styles.header}>
                        <Icon icon="card" color="#2E3192" size="24" />
                        <span>Tambah Pengguna</span>
                    </div>
                    <div className={Styles.body}>
                        <InputField
                            isLabel
                            isRequired
                            textLabel='Nama' 
                            placeholder='Masukkan nama'
                            value={values?.name}
                            setValue={(e) => handleChange(e, 'name')}
                        />
                        <InputField
                            isLabel
                            isRequired
                            textLabel='Nomor Telepon' 
                            isNumber
                            placeholder='+62'
                            value={values?.phoneNumber}
                            setValue={(e) => handleChange(e, 'phoneNumber')}
                        />
                        <InputField
                            isLabel
                            isRequired
                            isDropdown
                            dataMapDropdown={optClient}
                            textLabel='Klien' 
                            placeholder='Pilih klien'
                            value={values?.client}
                            setValue={(e) => handleChange(e, 'client')}
                        />
                        <InputField
                            isLabel
                            isRequired
                            textLabel='Posisi' 
                            placeholder='Masukkan posisi'
                            value={values?.position}
                            setValue={(e) => handleChange(e, 'position')}
                        />
                    </div>
                    <div className={Styles.footer}>
                        <Button
                            text="Batal"
                            isOutlined
                            onClick={handleClose}
                            isDisabled={isLoading}
                            style={{ width: '48%', justifyContent: 'center' }}

                        />
                        <Button
                            text="Simpan"
                            onClick={handleSubmit}
                            isDisabled={handleDisableButton}
                            style={{ width: '48%', justifyContent: 'center' }}
                        />
                    </div>
                </div>
            </Modal>
            <Toast
                isopen={toastSuccess}
                color="green"
                text={`Berhasil menambahkan user ${values?.name}`}
                onClose={() => {
                    setToastSuccess(false);
                    setValues({
                        name: '',
                        phoneNumber: '',
                        client: '',
                        position: '',
                    });
                }}
            />
            <Toast
                isopen={toastFailed}
                color="red"
                text={errorMessage}
                onClose={() => {
                    setToastFailed(false);
                    setValues({
                        name: '',
                        phoneNumber: '',
                        client: '',
                        position: '',
                    });
                }}
            />
        </>
    );
}
