import Button from '@Atom/Button';
import Styles from './styles.module.scss'
import Modal from '@Atom/Modal';
import Toast from '@Atom/Toast';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { allFilledObjChecker } from '@Helpers/allFilledObjChecker';
import Icon from '@Atom/Icon';
import useOutsideClick from '@Hooks/useOutsideClick';

export default function ModalDetailUser({ modalDetailUser, setModalDetailUser, selectedUser, setSelectedUser }) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [toastSuccess, setToastSuccess] = useState(false);
    const [toastFailed, setToastFailed] = useState(false);
    const [values, setValues] = useState({
        role: selectedUser?.role || ''
    });
    const [openOption, setOpenOption] = useState(false);

    const [errorMessage, setErrorMessage] = useState();

    const optionRole = useMemo(() => {
        return ['Tanpa Role', 'Sistem Admin', 'User Internal', 'Power User', 'Payroll', 'BPJS'];
    }, []); 

    useEffect(() => {
        if (selectedUser?.role) setValues({role: selectedUser?.role})
    }, [selectedUser?.role])

    const isDisabledChange = useMemo(() => {
        return true
    }, [])

    const handleDisableButton = useMemo(() => {
        return isLoading || !optionRole?.includes(values?.role) || selectedUser?.role?.toUpperCase() === values?.role?.toUpperCase()
    }, [isLoading, selectedUser?.role, values?.role, optionRole])

    const handleClose = () => {
        setModalDetailUser(false)
        setValues({role: ''})
        setSelectedUser()
    };

    const handleChange = (val, name) => {
        setValues({
            ...values,
            [name]: val,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);

            // if (response.status === 200) {
            setIsLoading(false);
            setToastSuccess(true);
            setModalDetailUser(false);
            navigate(pathname, { replace: true });
            // }
        } catch (error) {
            if (error.response) {
                setErrorMessage(error.response.data?.message || 'Terjadi kesalahan pada server.');
            } else if (error.request) {
                setErrorMessage('Tidak ada respons dari server.');
            } else {
                setErrorMessage(error.message);
            }
            setToastFailed(true);
            setIsLoading(false);
            console.error(error);
        }
    };

    const refOption = useRef()
    const outsideClick = useOutsideClick(refOption)
    useEffect(() => {
        if (openOption && outsideClick){
            setOpenOption(false)
        }
    }, [openOption, outsideClick])

    return (
        <>
            <Modal isOpen={modalDetailUser}>
                <div className={Styles.container}>
                    <div className={Styles.header}>
                        <div className={Styles.item}>
                            <Icon icon="card" color="#2E3192" size="24" />
                            <span>Detail Pengguna</span>
                        </div>
                        <Button
                            isNoBorder
                            isOutlined
                            icon="maximize"
                            text="Lihat Detail Karyawan"
                            onClick={() => {
                                navigate(`/people/detail/${selectedUser?.employeeID}`)
                            }}
                        />
                    </div>
                    <div className={Styles.body}>
                        {[
                            {
                                field: 'Nama',
                                text: selectedUser?.name || "-"
                            },
                            {
                                field: 'Penempatan',
                                text: selectedUser?.client || "-"
                            },
                            {
                                field: 'Posisi',
                                text: selectedUser?.position || "-"
                            },
                            {
                                field: 'Nomor Telepon',
                                text: selectedUser?.phoneNumber || "-"
                            },
                            {
                                field: 'NRK',
                                text: selectedUser?.employeeID || "-"
                            }
                        ].map((val) => (
                            <div className={Styles.rowWrapper}>
                                <span>{val.field}</span>
                                <span>{val.text}</span>
                            </div>    
                        ))}
                        <div className={Styles.rowWrapper}>
                            <span>Role</span>
                            <div 
                                className={`${Styles.roleSelection} ${openOption ? Styles.active : ''} ${isDisabledChange ? Styles.disabled : ''}`}
                                ref={refOption}
                                onClick={() => {
                                    if (!isDisabledChange){
                                        setOpenOption(!openOption)
                                    }
                                }}
                            >
                                <span>{values?.role}</span>
                                <Icon 
                                    icon='arrow-down'
                                    size='12px'
                                    color={isDisabledChange ? '#A9B3C1' : '#2E3192'}
                                />
                                {openOption && (
                                    <div className={Styles.roleOption}>
                                        {optionRole.map((item) => (
                                            <div 
                                                className={Styles.option}
                                                onClick={() => handleChange(item, 'role')}
                                            >
                                                <span>{item}</span>
                                                {item.toUpperCase() === values?.role?.toUpperCase() && (
                                                    <Icon 
                                                        icon='check'
                                                        size='12px'
                                                        color='#2E3192'
                                                    />
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {!isDisabledChange && (
                        <>
                            <div className={Styles.footer}>
                                <Button
                                    text="Batal"
                                    isOutlined
                                    onClick={handleClose}
                                    isDisabled={isLoading}
                                    style={{ width: '48%', justifyContent: 'center' }}
                                />
                                <Button
                                    text="Simpan"
                                    onClick={handleSubmit}
                                    isDisabled={handleDisableButton}
                                    style={{ width: '48%', justifyContent: 'center' }}
                                />
                            </div>
                        </>
                    )}
                </div>
                
            </Modal>
            <Toast
                isopen={toastSuccess}
                color="green"
                text={`Role ${selectedUser?.role} berhasil diubah menjadi ${values?.role}`}
                onClose={() => {
                    setToastSuccess(false);
                    setSelectedUser();
                    setValues();
                }}
            />
            <Toast
                isopen={toastFailed}
                color="red"
                text={errorMessage}
                onClose={() => {
                    setToastFailed(false);
                    setSelectedUser();
                }}
            />
        </>
    );
}
