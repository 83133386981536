import Icon from '@Atom/Icon'
import SearchBar from '@Atom/SearchBar'
import TabHeader from '@Atom/TabHeader'
import TooltipTab from '@Atom/TooltipTab'
import { timeFormatV2 } from '@Helpers/timeFormat'
import { useDebounce } from '@Hooks/useDebounce'
import useOutsideClick from '@Hooks/useOutsideClick'
import useQuery from '@Hooks/useQuery'
import AssignTicketModal from '@Molecule/_modal/AssignTicketModal'
import CustomReportModal from '@Molecule/_modal/CustomReportModal'
import FeedbackFilterSlider from '@Molecule/_modal/FeedbackFilterSlider'
import Table from '@Molecule/Table'
import { getFeedbackDownload } from '@Services/feedback/getFeedbackList'
import Images from '@Theme/Images'
import moment from 'moment'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Styles from './style.module.scss'

import ExcelJS from "exceljs"


export default function FeedbackListLayout({
  packageList
}) {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const query = useQuery()
  const [expandDownload, setExpandDownload] = useState(false)
  const [customMode, setCustomMode] = useState()
  const ref = useRef()
  const outsideClick = useOutsideClick(ref)

  useEffect(() => {
    if (expandDownload && outsideClick && customMode) {
      setExpandDownload(false)
    }
  }, [customMode, expandDownload, outsideClick])

  const defaultStatus = query.get('st') || '';
  const defaultStage = query.get('sg') || '';
  const defaultIsTransfers = query.get('isTransfers') || 'false'
  const defaultIsLateResponse = query.get('isLateResponse') || 'false'
  const defaultNeedResponse = query.get('needResponse') || 'false'
  // eslint-disable-next-line no-unused-vars
  const [isDownloading, setIsDownloading] = useState(false)

  const [status, setStatus] = useState(defaultStatus)
  const [stage, setStage] = useState(defaultStage)
  const handleDownload = async (downloadType) => {
    try {
      setIsDownloading(true)
      const { data } = await getFeedbackDownload(downloadType, debouncedSearch, '', '', assignedTo, status, isTransfers, isLateResponse, needResponse)
      const dataToMap = data?.response?.map((obj, i) => {
        return {
          ...obj,
          id: i + 1,
          ticketID: obj?.code,
          userName: obj?.employeeName,
          employeeID: obj?.employeeID,
          clientName: obj?.clientName,
          region: obj?.region,
          category: obj?.issueType || '-',
          title: obj?.title,
          timestamp: moment(new Date(obj?.createdAt))?.format('LL'),
          managerName: obj?.managerDetails?.name,
          firstResponseTime: obj?.firsResponseTime ? timeFormatV2(obj?.firsResponseTime) : '-',
          averageResponseTime: obj?.averagerResponseTime ? timeFormatV2(obj?.averagerResponseTime) : '-',
          sla: obj?.sla ? timeFormatV2(obj?.sla) : '-',
          status: obj?.status
        }
      })
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Report");

      worksheet.columns = [
        { header: "No", key: "id", width: 10 },
        { header: "ID", key: "ticketID", width: 20 },
        { header: "Nama Pelapor", key: "userName", width: 25 },
        { header: "Employee ID", key: "employeeID", width: 25 },
        { header: "Klien", key: "clientName", width: 25 },
        { header: "Region", key: "region", width: 25 },
        { header: "Kategori Pengaduan", key: "category", width: 25 },
        { header: "Judul Pengaduan", key: "title", width: 25 },
        { header: "Tgl Komplain", key: "timestamp", width: 25 },
        { header: "Manager", key: "managerName", width: 25 },
        { header: "First Resp. Time", key: "firstResponseTime", width: 25 },
        { header: "Avg. Resp. Time", key: "averageResponseTime", width: 25 },
        { header: "SLA", key: "sla", width: 25 },
        { header: "Status", key: "status", width: 25 },
      ];

      dataToMap.forEach(obj => worksheet.addRow(obj))

      worksheet.getRow(1).eachCell((cell) => {
        cell.font = { bold: true };
        cell.alignment = { horizontal: "center", vertical: "middle" };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "#2C5364" },
        };
        cell.font = {
          bold: true,
          color: { argb: "FFFFFFFF" },
        };
      });

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `Laporan Pengaduan.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    } catch (err) {
      console.log(err, 'error downloading')
    } finally {
      setExpandDownload(false)
      setIsDownloading(false)
    }
  }
  const tabOptions = useMemo(() => {
    return [
      {
        title: 'Semua',
        value: '',
        onClick: () => {
          setStatus('')
          setStage('')
        }

      },
      {
        title: 'Open',
        value: 'OPEN',
        type: 'status',
        onClick: () => {
          setStage('')
          setStatus('OPEN')
        }
      },
      {
        title: 'Closed',
        value: 'CLOSED',
        type: 'status',
        onClick: () => {
          setStage('')
          setStatus('CLOSED')
        }
      },
      {
        title: 'Flagged',
        value: 'VIOLATION',
        type: 'stage',
        onClick: () => {
          setStatus('')
          setStage('VIOLATION')
        }
      },
      {
        title: 'Butuh Dijawab',
        value: 'NEW MESSAGES',
        type: 'stage',
        onClick: () => {
          setStatus('')
          setStage('NEW MESSAGES')
        },
        label: packageList?.totalNeedResponse
      },
    ]
  }, [packageList?.totalNeedResponse])
  const defaultTab = useMemo(() => {
    const queriedStage = query.get('sg') || ''
    const foundStage = tabOptions?.filter(obj => obj?.type === 'stage')?.find(option => option.value === queriedStage);
    const queriedStatus = query.get('st') || '';
    const foundStatus = tabOptions?.filter(obj => obj?.type === 'status')?.find(option => option.value === queriedStatus);
    return foundStage?.value || foundStatus?.value || ''
  }, [query, tabOptions])
  const [activeTab, setActiveTab] = useState(defaultTab)
  const defaultSearch = query.get('s') || '';
  const [search, setSearch] = useState(defaultSearch)
  const defaultLimit = query.get('l') ? +query?.get('l') : 10;
  const [limit, setLimit] = useState(defaultLimit);
  const defaultPage = query.get('p') ? +query?.get('p') : 1;
  const [page, setPage] = useState(defaultPage);
  const [isTransfers, setIsTransfers] = useState(defaultIsTransfers)
  const [isLateResponse, setIsLateResponse] = useState(defaultIsLateResponse)
  const [needResponse, setNeedResponse] = useState(defaultNeedResponse)



  const [showFilter, setShowFilter] = useState(false)
  const [selectedTickets, setSelectedTickets] = useState([])
  const [showAssign, setShowAssign] = useState(false)

  const defaultDate = query.get('d') || '';
  const defaultAssignedTo = query.get('a') || '';

  const [date, setDate] = useState(defaultDate)
  const [assignedTo, setAssignedTo] = useState(defaultAssignedTo)

  const template = useMemo(() => {
    return {
      data: packageList?.data?.map(obj => {
        return {
          ...obj,
          id: obj?._id,
          ticketID: obj?.code,
          user: {
            name: obj?.employeeName,
            employeeID: obj?.employeeID,
            profilePicture: Images.AVA_DEFAULT,
            clientName: obj?.clientName,
          },
          timestamp: new Date(obj?.createdAt),
          handler: {
            name: obj?.assignedDetails?.name,
            position: obj?.assignedDetails?.position,
          },
          firstResponseTime: obj?.firsResponseTime ? timeFormatV2(obj?.firsResponseTime) : '-',
          averageResponseTime: obj?.averagerResponseTime ? timeFormatV2(obj?.averagerResponseTime) : '-',
          sla: obj?.sla ? timeFormatV2(obj?.sla) : '-',
          category: obj?.issueType || '-',
          status: [obj?.status, obj?.stage === 'ON-PROSESS' ? 'ON-PROGRESS' : obj?.stage,]
        }
      }),
      columns: [
        {
          name: "ticketID",
          title: <TabHeader text="ID" />,
          tdClassName: Styles.first,
          width: '120px'
        },
        {
          name: "user",
          title: 'Nama Pelapor',
          tdClassName: Styles.second,
          width: '176px',
          renderData: (row) => (
            <div className={Styles.profile}>
              <img alt='' src={row?.user?.profilePicture} />
              <div>
                <TooltipTab direction='right' content={row?.user?.name}>
                  <span className={Styles.name}>{row?.user?.name}</span>
                </TooltipTab>
                <p>{row?.user?.employeeID}</p>
              </div>
              <div className={Styles.border} />
            </div>
          ),
        },
        {
          name: 'category',
          title: 'Kategori Pengaduan'
        },
        {
          name: 'title',
          title: 'Judul Pengaduan'
        },
        {
          name: 'clientName',
          title: 'Klien',
          width: '179px',
          renderData: (row) => (
            <TooltipTab width='150px' direction='right' content={row?.user?.clientName}>
              <span className={Styles.name}>{row?.user?.clientName}</span>
            </TooltipTab>
          )
        },
        {
          name: "date",
          title: 'Tgl Komplain',
          width: '134px',
          renderData: (row) => (
            <div className={Styles.timestamp}>
              <div>
                <Icon color={'#718198'} icon={'calendar'} size={12} />
                <span>{moment(row?.timestamp)?.format('ll')}</span>
              </div>
              <div>
                <Icon color={'#718198'} icon={'clock-solid'} size={12} />
                <span>{moment(row?.timestamp)?.format('LT')?.replace('.', ':')}</span>
              </div>
            </div>
          ),
        },
        {
          name: "date",
          title: 'Penanggung Jawab',
          width: '200px',
          renderData: (row) => (
            row?.handler
              ?
              <div className={Styles.profile}>
                <img alt='' src={row?.handler?.profilePicture || Images.AVA_DEFAULT} />
                <div>
                  <span>{row?.handler?.name || '-'}</span>
                  <p>{row?.handler?.position || '-'}</p>
                </div>
              </div>
              :
              <span>-</span>
          ),
        },
        {
          name: "date",
          title: 'First Resp. Time',
          renderData: (row) => (
            <div className={Styles.time}>
              <Icon color={'#718198'} icon={'clock-solid'} size={16} />
              <span>{row?.firstResponseTime}</span>
            </div>
          ),
        },
        {
          name: "date",
          title: 'Avg. Resp. Time',
          renderData: (row) => (
            <div className={Styles.time}>
              <Icon color={'#718198'} icon={'clock-solid'} size={16} />
              <span>{row?.averageResponseTime}</span>
            </div>
          ),
        },
        {
          name: "telephone",
          title: <TabHeader text="SLA" />,
          width: '200px',
          renderData: (row) => (
            <div className={Styles.time}>
              <Icon color={'#718198'} icon={'clock-solid'} size={16} />
              <span>{row?.sla}</span>
            </div>
          ),
        },
        {
          name: "",
          title: <TabHeader text="Status" />,
          renderData: (row) => {
            const status = row?.status[1]
            const text = status === 'NEW'
              ? 'Baru'
              : (
                status === 'ON-PROGRESS'
                  ?
                  'Dalam Proses'
                  :
                  'Selesai'
              )
            const textStyle = status === 'NEW'
              ? 'new'
              : (
                status === 'ON-PROGRESS'
                  ?
                  'onProgress'
                  :
                  'finished'
              )
            return (
              <div className={Styles.status}>
                <div
                  style={{ backgroundColor: row?.status?.find(obj => obj === 'OPEN') ? '#CEEAD3' : '#DEE2E7' }}
                  className={Styles.circle}
                />
                <span className={Styles[textStyle]}>{text}</span>
                <Icon color={row?.originTicketId ? '#22CFBA' : '#DEE2E7'} icon={'document-previous'} size={20} />
                <Icon color={row?.isLateResponse ? '#D42701' : '#DEE2E7'} icon={'flag'} size={20} />
                <Icon color={row?.isTransfers ? '#2E3192' : '#DEE2E7'} icon={'document-forward'} size={20} />
                <div className={Styles.messageIcon}>
                  <Icon color={row?.needResponse ? '#22970F' : '#DEE2E7'} icon={'message'} size={20} />
                  {
                    row?.status?.find(obj => obj?.includes('MESSAGE'))
                    &&
                    <span>
                      {row?.status?.find(obj => obj?.includes('MESSAGE'))?.split('-')[1]}
                    </span>
                  }
                </div>
              </div>
            )
          }

        },
      ],
      totalData: packageList?.totalData,
      totalPage: packageList?.totalPages
    };
  }, [packageList?.data, packageList?.totalData, packageList?.totalPages]);

  const debouncedSearch = useDebounce(search, 300)

  const isValueDifferent = useMemo(() => {
    return defaultPage !== page
      || defaultLimit !== limit
      || defaultSearch !== debouncedSearch
      || defaultTab !== activeTab
      || defaultStatus !== status
      || defaultStage !== stage
      || defaultIsTransfers !== isTransfers
      || defaultIsLateResponse !== isLateResponse
      || defaultNeedResponse !== needResponse
      || defaultAssignedTo !== assignedTo
      || defaultDate !== date;
  }, [activeTab, assignedTo, date, debouncedSearch, defaultAssignedTo, defaultDate, defaultIsLateResponse, defaultIsTransfers, defaultLimit, defaultNeedResponse, defaultPage, defaultSearch, defaultStage, defaultStatus, defaultTab, isLateResponse, isTransfers, limit, needResponse, page, stage, status])

  useEffect(() => {
    if (stage === 'NEW MESSAGES') setNeedResponse('true')
    if (stage === 'VIOLATION') setIsLateResponse('true')
    if (stage === 'TRANSFER') setIsTransfers('true')
  }, [stage])

  useEffect(() => {
    if (isValueDifferent) {
      navigate(`${pathname}?p=${page}&l=${limit}&s=${debouncedSearch}&d=${date || ''}&a=${assignedTo}&st=${status}&isTransfers=${isTransfers}&isLateResponse=${isLateResponse}&sg=${stage}&needResponse=${needResponse}`, { replace: true })
    }
  }, [assignedTo, date, debouncedSearch, isLateResponse, isTransfers, isValueDifferent, limit, navigate, needResponse, page, pathname, stage, status])

  return (
    <div className={Styles.container}>
      <div className={Styles.containerWrapper}>
        <div className={Styles.container}>
          <div className={Styles.header}>
            <h3>Daftar Pengaduan</h3>
            <div className={Styles.tabs}>
              {
                tabOptions
                  ?.map((tab, i) => (
                    <div key={i} className={`${Styles.tab} ${Styles[activeTab === tab?.value ? 'active' : '']}`} onClick={() => {
                      setIsTransfers('false')
                      setIsLateResponse('false')
                      setNeedResponse('false')
                      setActiveTab(tab?.value)
                      tab?.onClick()
                    }}>
                      <span>{tab?.title}</span>
                      {
                        tab?.label &&
                        <div>{tab?.label}</div>
                      }
                    </div>
                  ))
              }
              <div className={Styles.filler} />
              <button className={Styles.button} onClick={() => setExpandDownload(!expandDownload)}>
                <span>Download Report</span>
                <Icon icon={'document-download-outline'} size={16} />
              </button>
              {
                expandDownload
                &&

                <div ref={ref} className={Styles.downloadOptions}>
                  {
                    [
                      {
                        label: 'Bulan Ini',
                        onClick: () => handleDownload('current')
                      },
                      {
                        label: 'Bulan Kemarin',
                        onClick: () => handleDownload('last')
                      },
                      {
                        label: 'Custom',
                        onClick: () => {
                          setCustomMode(true)
                        }
                      }
                    ]?.map((option, i) => (
                      <div key={i} onClick={option?.onClick}>
                        {option?.label}
                      </div>
                    ))
                  }
                </div>


              }
            </div>
            <div className={Styles.filter}>
              <div className={Styles.left}>
                <div className={Styles.searchWrapper}>
                  <SearchBar
                    value={search}
                    setValue={setSearch}
                    placeholder='Cari nama atau NRK'
                    backgroundColor='#fff'
                  />
                </div>
                <button className={Styles.button} onClick={() => setShowFilter(true)}>
                  <span>Filter</span>
                  <Icon icon={'setting-4'} size={16} />
                </button>
                {/* {
                  role === "ADMIN"
                  &&
                  !!isChecked?.length
                  &&
                  <button onClick={() => setShowAssign(true)}>
                    <span>Assign</span>
                    <Icon icon={'send-2'} size={16} />
                  </button>

                } */}
              </div>

              <div className={Styles.legends}>
                {
                  [
                    {
                      title: 'Ticket Dalam Status Closed',
                      icon: <div style={{ backgroundColor: '#DEE2E7' }} className={Styles.circle} />
                    },
                    {
                      title: 'Ticket Dalam Status Open',
                      icon: <div style={{ backgroundColor: '#CEEAD3' }} className={Styles.circle} />
                    },
                    {
                      title: 'Ticket Dari Dept. Lain',
                      icon: <Icon color={'#22CFBA'} icon={'document-previous'} size={20} />
                    },
                    {
                      title: 'Pesan Belum Dibalas',
                      icon: <Icon color={'#22970F'} icon={'message'} size={20} />
                    },
                    {
                      title: 'Terdapat Pelanggaran SLA',
                      icon: <Icon color={'#D42701'} icon={'flag'} size={20} />
                    },
                    {
                      title: 'Diteruskan ke Dept. Lain',
                      icon: <Icon color={'#2E3192'} icon={'document-forward'} size={20} />
                    },
                  ]
                    ?.map((legend, i) => (
                      <div key={i} className={Styles.legend}>
                        {legend?.icon}
                        <span>{legend?.title}</span>
                      </div>
                    ))
                }
              </div>
            </div>
          </div>
          <div className={Styles.content}>
            <Table
              className={Styles.table}
              data={template.data}
              columns={template.columns}
              page={page}
              limit={limit}
              totalData={template.totalData || 0}
              setLimit={setLimit}
              setPage={setPage}
              totalPage={Math.ceil(template.totalData / limit)}
              withPagination
              withNumbering
              // withCheckbox={role === 'ADMIN'}
              // isChecked={isChecked}
              // setIsChecked={setIsChecked}
              onClickRow={(row) => navigate(`${pathname}/${row?.id}`)}
              isLoading={isValueDifferent}
              isNumberSticky
            />
          </div>
        </div>
      </div>
      <FeedbackFilterSlider
        show={showFilter}
        onClose={() => setShowFilter(false)}
        defaultAssignedTo={defaultAssignedTo}
        defaultDate={defaultDate}
        defaultStatus={defaultStatus}
        defaultStage={defaultStage}
        defaultPage={defaultPage}
        defaultLimit={defaultLimit}
        defaultSearch={defaultSearch}
        setParentStage={setStage}
        setParentStatus={setStatus}
        setParentTab={setActiveTab}
        setParentDate={setDate}
        setParentIsTransfers={setIsTransfers}
        setParentIsLateResponse={setIsLateResponse}
        setParentNeedResponse={setNeedResponse}
        setParentAssignedTo={setAssignedTo}
      />
      <AssignTicketModal
        show={showAssign}
        data={selectedTickets}
        onClose={() => setShowAssign(false)}
        onSuccess={() => {
          setSelectedTickets([])
        }}
      />
      <CustomReportModal
        show={customMode}
        onCancel={() => setCustomMode(false)}
        onClose={() => {
          setExpandDownload(false)
          setCustomMode(false)
        }}
      />
    </div>
  )
}