import axios from "axios";

export const API = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL + "/v1",
  // baseURL: "http://localhost:3000/v1",
  // baseURL: "https://4dz0v26z-3000.asse.devtunnels.ms/v1",
});

export const fileBaseUrl = process.env.REACT_APP_BASE_URL + "/v1/file/";

export const fileBaseUrlC2C = process.env.REACT_APP_C2C_BASE_URL + "/api/storage/get/";


export const API365 = axios.create({
  baseURL: process.env.REACT_APP_365_BASE_URL + "/api/v2",
});

export const APIMWS = axios.create({
  baseURL: process.env.REACT_APP_MWS_BASE_URL,
});

export const fileBaseUrl365 = process.env.REACT_APP_365_BASE_URL + "/api/file/";
