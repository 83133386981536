import { API } from "configs";

export function getFeedbackList(
  page,
  limit = '',
  search,
  date = '',
  assignedTo = '',
  status = '',
  isTransfers = 'false',
  isLateResponse = 'false',
  needResponse = 'false',
) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.get(
        `/ticket`,
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
          params: {
            page,
            limit,
            search,
            date,
            assignedTo,
            status,
            isTransfers,
            isLateResponse,
            needResponse
          }
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function getFeedbackDownload(
  downloadType = '',
  search,
  startDate = '',
  endDate = '',
  assignedTo = '',
  status = '',
  isTransfers = 'false',
  isLateResponse = 'false',
  needResponse = 'false',
  issueType = []
) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.get(
        `/ticket/report`,
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
          params: {
            downloadType,
            search,
            startDate,
            endDate,
            assignedTo,
            status,
            isTransfers,
            isLateResponse,
            needResponse,
            issueType
          }
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

