import Modal from "@Atom/Modal"
import Styles from "./styles.module.scss"
import Icon from "@Atom/Icon"
import Button from "@Atom/Button"

export default function ModalFailedActivateEmployee({
    isOpenModal,
    setIsOpenModal,
    selectedName
}) {

    const handleCloseModal = () =>{
        setIsOpenModal(false)
    }

    return (
        <>
            <Modal isOpen={isOpenModal}>
                <div className={Styles.containerModal}>
                    <div className={Styles.wrapperModalDropdown}>
                        <div className={Styles.headerModal}>
                            <Icon icon={'warning-2'} color={"#D42701"} size={24}/>
                            <h2>
                                Gagal Mengaktifkan Karyawan
                            </h2>
                        </div>
                        <div className={Styles.desc}>
                                <p className={Styles.paragraph}>
                                    Kontrak karyawan atas nama {selectedName?.name} telah berakhir. Silahkan hubungi admin kontrak untuk mengaktifkan karyawan kembali.
                                </p>
                        </div>
                        <div className={Styles.wrapperButton}>
                            <Button
                                isOutlined 
                                text="Tutup" 
                                onClick={handleCloseModal} 
                                style={{ width: '100%', justifyContent: 'center' }}/>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}