import DatePicker from '@Atom/DatePicker'
import Icon from '@Atom/Icon'
import Input from '@Atom/Input'
import Modal from '@Atom/Modal'
import RadioButton from '@Atom/RadioButton'
import { getManagers } from '@Services/manager/getManagers'
import Images from '@Theme/Images'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Styles from './style.module.scss'

export default function FeedbackFilterSlider({
  defaultPage,
  defaultLimit,
  defaultDate,
  defaultAssignedTo,
  defaultStatus,
  defaultStage,
  defaultSearch,
  show = false,
  onClose = () => { },
  setParentStage,
  setParentStatus,
  setParentTab,
  defaultIsTransfers,
  defaultIsLateResponse,
  defaultNeedResponse,
  setParentIsTransfers,
  setParentIsLateResponse,
  setParentNeedResponse,
  setParentAssignedTo,
  setParentDate
}) {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [date, setDate] = useState(defaultDate || '')
  const [handler, setHandler] = useState('')
  const [status, setStatus] = useState(defaultStatus)
  const [stage, setStage] = useState(defaultStage)
  const [isTransfers, setIsTransfers] = useState(defaultIsTransfers)
  const [isLateResponse, setIsLateResponse] = useState(defaultIsLateResponse)
  const [needResponse, setNeedResponse] = useState(defaultNeedResponse)
  const [isFetchingOptions, setIsFetchingOptions] = useState(false)
  const [optionsData, setOptionsData] = useState([])

  useEffect(() => {
    const found = optionsData?.find(obj => obj?.employeeId === defaultAssignedTo)
    if (found) setHandler(found?.user?.name)
  }, [defaultAssignedTo, optionsData])

  const fetchOptions = useCallback(async () => {
    try {
      const { data } = await getManagers()
      setOptionsData(data?.response || [])
      setIsFetchingOptions(true)
    } catch (err) {

    } finally {
      setIsFetchingOptions(false)
    }
  }, [])


  useEffect(() => {
    if (show) fetchOptions()
  }, [fetchOptions, show])

  const dropdownOptions = useMemo(() => {
    return optionsData?.map(obj => {
      return {
        name: obj?.user?.name,
        picture: Images.AVA_DEFAULT
      }
    })
  }, [optionsData])

  useEffect(() => {
    setDate(defaultDate)
    setStatus(defaultStatus)
    setStage(defaultStage)
  }, [defaultDate, defaultStage, defaultStatus])

  const template = useMemo(() => {
    return [
      {
        title: 'Tanggal Ticket Diterima',
        component: <DatePicker
          value={date}
          setValue={setDate}
          placeholder='Pilih tanggal'
        />
      },
      {
        title: 'Penanggung Jawab',
        component: <Input
          value={handler}
          setValue={setHandler}
          // isDropdown
          isUserAutocomplete
          isLoadingDropdown={isFetchingOptions}
          dropdownOptions={dropdownOptions}
          placeholder='Cari penanggung jawab'
        />
      },
      {
        title: 'Status',
        component: <div className={Styles.statuses}>
          {
            [
              {
                title: 'Status Open',
                value: 'OPEN'
              },
              {
                title: 'Status Closed',
                value: 'CLOSE'
              },
            ]
              ?.map((obj, i) => (
                <div key={i} className={Styles.status} onClick={() => setStatus(obj?.value)}>
                  <RadioButton isActive={status === obj?.value} />
                  <span>{obj?.title}</span>
                </div>
              ))
          }
        </div>
      },
      {
        title: 'Stage',
        component: <div className={Styles.stages}>
          {
            [

              {
                title: 'Ticket dari departemen lain',
                icon: <Icon color={'#22CFBA'} icon={'document-previous'} size={20} />,
                value: 'RECEIVED'
              },
              {
                title: 'Diteruskan ke departemen lain',
                icon: <Icon color={'#2E3192'} icon={'document-forward'} size={20} />,
                value: 'TRANSFER'
              },
              {
                title: 'Ticket dengan pelanggaran SLA',
                icon: <Icon color={'#D42701'} icon={'flag'} size={20} />,
                value: 'VIOLATION'
              },
              {
                title: 'Ticket perlu dibalas',
                icon: <Icon color={'#22970F'} icon={'message'} size={20} />,
                value: 'NEW MESSAGES'
              },
            ]
              ?.map((obj, i) => (
                <div key={i} className={Styles.stage} onClick={() => setStage(obj?.value)}>
                  <RadioButton isActive={stage === obj?.value} />
                  {obj?.icon}
                  <span>{obj?.title}</span>
                </div>
              ))
          }
        </div>
      }
    ]
  }, [date, dropdownOptions, handler, isFetchingOptions, stage, status])

  const handleSave = () => {
    navigate(`${pathname}?p=${defaultPage}&l=${defaultLimit}&s=${defaultSearch}&d=${date ? moment(date)?.format('YYYY-MM-DD') : ''}&a=${defaultAssignedTo}&st=${status}&sg=${stage}`, { replace: true })
    setParentStage(stage)
    setParentStatus(status)
    const found = optionsData?.find(obj => obj?.user?.name === handler)
    setParentAssignedTo(found?.employeeId || '')
    setParentIsTransfers(isTransfers || 'false')
    setParentDate(date ? moment(date)?.format('YYYY-MM-DD') : '')
    setParentIsLateResponse(isLateResponse || 'false')
    setParentNeedResponse(needResponse || 'false')
    if (stage === 'VIOLATION' || stage === 'NEW MESSAGES') {
      setParentTab(stage)
    } else if (status === 'OPEN' || status === 'CLOSE') {
      setParentTab(status)
    } else {
      setParentTab('')
    }
    onClose()
  }

  return (
    <Modal isOpen={show} onClose={onClose} isSlider>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <h3>Filter</h3>
          <button onClick={onClose}>
            <Icon icon={'close-circle'} size={20} />
          </button>
        </div>
        <div className={Styles.content}>
          {
            template?.map((item, i) => (
              <div key={i}>
                <span>{item?.title}</span>
                {item?.component}
              </div>
            ))
          }
        </div>
        <div className={Styles.actions}>
          <button onClick={handleSave}>Terapkan</button>
          <button onClick={() => {
            setDate('')
            setHandler('')
            setStage('')
            setStatus('')
            setIsTransfers('false')
            setIsLateResponse('false')
            setNeedResponse('false')
          }}>Reset</button>
        </div>
      </div>
    </Modal>
  )
}