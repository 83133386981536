export const mapMaritalStatus = (marital) => {
    switch (marital) {
        case 'TIDAK KAWIN':
            return 'TK';
        case 'KAWIN (0 ANAK)' :
            return 'K0';
        case 'KAWIN (1 ANAK)':
            return 'K1';
        case 'KAWIN (2 ANAK)':
            return 'K2';
        case 'KAWIN (>= 3 ANAK)':
            return 'K3';
        default:
            return marital;
    }
};
