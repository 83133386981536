import { useEffect, useRef, useState } from 'react'
import Styles from './style.module.scss'
import useOutsideClick from '@Hooks/useOutsideClick'
import Icon from '@Atom/Icon'
import InputTitle from '@Atom/InputTitle'
import Checkbox from '@Atom/Checkbox'

export default function MultiSelectDropdown({
  value = [],
  setValue = () => { },
  options,
  width = 'auto',
  minHeight = '44px',
  placeholder = 'Select',
  title = '',
  required = false,
  maxOptionsHeight = 'auto',
  isChip = false
}) {
  const [expand, setExpand] = useState(false)
  const ref = useRef()
  const outsideClick = useOutsideClick(ref)

  useEffect(() => {
    if (expand && outsideClick) {
      setExpand(false)
    }
  }, [expand, outsideClick])


  return (
    <div className={Styles.wrapper}>
      {
        !!title
        &&
        <InputTitle
          title={title}
          required={required}
        />
      }
      <div
        ref={ref}
        className={`${Styles.container} ${Styles[expand ? 'expand' : '']}`}
        onClick={() => setExpand(!expand)}
        style={{
          width,
          minHeight
        }}
      >
        {
          isChip && value?.length
            ?
            <div className={Styles.values}>
              {
                value?.map((v, i) => (
                  <div key={i} className={Styles.chip} onClick={(e) => e.stopPropagation()}>
                    <span>
                    {v}
                    </span>
                    <button onClick={()=> setValue(value?.filter(obj => obj !== v))}>
                      <Icon color={'#2E3192'} icon={'close-circle'} size={9}/>
                    </button>
                  </div>
                ))
              }
            </div>
            :
            <span className={Styles[!value?.length ? 'placeholder' : '']}>{value?.length ? value?.join(', ') : placeholder}</span>
        }
        <Icon icon={'arrow-chevron-left'} size={16} className={Styles[expand ? 'up' : 'down']} color={'#BBC3CE'} />
        {
          expand
          &&
          <div className={Styles.options} style={{ maxHeight: maxOptionsHeight }}>
            {
              options?.map((option, i) => (
                <div
                  key={i} className={`${Styles.option} ${Styles[value === option ? 'active' : '']}`}
                  onClick={(e) => {
                    e?.stopPropagation()
                    if (value?.find(obj => obj === option)) {
                      setValue(value?.filter(obj => obj !== option))
                    } else {
                      setValue(
                        options?.filter(obj => [option, ...value]?.includes(obj))
                      )
                    }
                  }}
                >
                  <Checkbox
                    isChecked={value?.find(obj => obj === option) ? true : false}
                    setIsChecked={() => {
                      if (value?.find(obj => obj === option)) {
                        setValue(value?.filter(obj => obj !== option))
                      } else {
                        setValue(
                          options?.filter(obj => [option, ...value]?.includes(obj))
                        )
                      }
                    }}
                  />
                  <span>
                    {option}
                  </span>
                </div>
              ))
            }
          </div>
        }
      </div>
    </div>
  )
}