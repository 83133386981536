import AsyncErrorPage from '@Molecule/AsyncErrorPage';
import LoadingAnimationPage from '@Molecule/LoadingAnimationPage';
import LayoutDetailBPJS from '@Organism/BPJS/DetailBPJS';
import { fetchBPJSData } from '@Services/bpjs';
import { Suspense, useState } from 'react';
import { Await, defer, useLoaderData } from 'react-router-dom';

export const loaderDetailBPJS = (route) => {
    const { id } = route?.params;
    return defer({
        fetchBPJSData: fetchBPJSData(id),
    });
};

export default function PageDetailBPJS(params) {
    const { fetchBPJSData } = useLoaderData();
    const [loading, setLoading] = useState(false);

    return (
        <Suspense fallback={<LoadingAnimationPage />}>
            <Await resolve={fetchBPJSData} errorElement={<AsyncErrorPage />}>
                {(valueBPJS) => <LayoutDetailBPJS data={valueBPJS} isLoading={loading} setLoading={setLoading} />}
            </Await>
        </Suspense>
    );
}
