import { Document, Image, Page, PDFViewer, Text, View } from '@react-pdf/renderer';

// import Styles from './styles.module.scss';
import styles from './styles';
import Images from '@Theme/Images';
// import { textGap } from '@Helpers/textGap';
import { useMemo } from 'react';
import moment from 'moment';
import JsBarcode from 'jsbarcode';

export default function LayoutCardBPJSKS({ data }) {
    const viewData = useMemo(() => {
        const returnData = data?.families
            ?.map((item) => {
                if (item?.bpjsKesehatan?.length > 0) {
                    const bpjsks = item?.bpjsKesehatan?.[0] || {};
                    const result = {
                        ...item,
                        number: bpjsks?.number || 0,
                        name: item?.name,
                        addresss: item?.addresss,
                        birthDate: item?.birthDate ? moment(item?.birthDate).format('DD-MM-YYYY') : '',
                        idNumber: item?.idNumber,
                        healthFacility: bpjsks?.healthFacility,
                    };

                    const canvas = document.createElement('canvas');
                    JsBarcode(canvas, result?.number, {
                        format: 'CODE128',
                        background: null,
                        displayValue: false,
                        margin: 0,
                    });

                    return {
                        ...result,
                        barcode: canvas.toDataURL(),
                    };
                }
                return undefined
            })
            .filter((f) => f !== undefined);

        return returnData;
    }, [data?.families]);

    return (
        <PDFViewer style={{ width: window.innerWidth, height: '100vh' }}>
            <Document title={`bpjstk_${data?.name}`}>
                <Page size="A4" style={styles.page}>
                    {viewData?.map((item, idx) => (
                        <View style={styles.itemWrapper} key={idx}>
                            <View style={styles.titleWrapper}>
                                <Text style={styles.titleName}>{`Kartu BPJS : ${item?.name}`}</Text>
                            </View>

                            <View style={styles.cardWrapper}>
                                <Image src={Images.CARD_BPJS_KES} style={styles.backgroundImage} />
                                <View style={styles.barcodeImage}>
                                    <Image src={item?.barcode} />
                                </View>
                                <View style={styles.gridContainer}>
                                    <View style={styles.gridItem}>
                                        {/* number */}
                                        <Text style={styles.textProfileKey}>Nomor Kartu</Text>
                                        <Text style={styles.textColon}>:</Text>
                                        <Text style={styles.textProfile}>{item?.number}</Text>
                                    </View>
                                    
                                    <View style={styles.gridItem}>
                                        {/* name */}
                                        <Text style={styles.textProfileKey}>Nama</Text>
                                        <Text style={styles.textColon}>:</Text>
                                        <Text style={styles.textProfile}>{item?.name}</Text>
                                    </View>
                                    
                                    <View style={styles.gridItem}>
                                        {/* address */}
                                        <Text style={styles.textProfileKey}>Alamat</Text>
                                        <Text style={styles.textColon}>:</Text>
                                        <Text style={styles.textProfile}>{item?.address}</Text>
                                    </View>
                                    
                                    <View style={styles.gridItem}>
                                        {/* birth */}
                                        <Text style={styles.textProfileKey}>Tanggal lahir</Text>
                                        <Text style={styles.textColon}>:</Text>
                                        <Text style={styles.textProfile}>{item?.birthDate}</Text>
                                    </View>
                                    
                                    <View style={styles.gridItem}>
                                        {/* nik */}
                                        <Text style={styles.textProfileKey}>NIK</Text>
                                        <Text style={styles.textColon}>:</Text>
                                        <Text style={styles.textProfile}>{item?.idNumber}</Text>
                                    </View>
                                    
                                    <View style={styles.gridItem}>
                                        {/* health */}
                                        <Text style={styles.textProfileKey}>Faskes Tingkat I</Text>
                                        <Text style={styles.textColon}>:</Text>
                                        <Text style={styles.textProfile}>{item?.healthFacility}</Text>
                                    </View>
                                </View>

                                <View style={styles.tncWrapper}>
                                    <Text style={styles.itemTnC}>Syarat dan Ketentuan</Text>
                                    <Text style={styles.itemTnC}>1. Kartu KIS digital harap dibawa ketika berobat</Text>
                                    <Text style={styles.itemTnC}>2. Apabila kartu ini disalahgunakan akan dikenakan sanksi</Text>
                                    <Text style={styles.itemTnC}>3. Apabila ada perubahan data, silahkan menghubungi BPJS Kesehatan</Text>
                                </View>
                                <View style={styles.footerWrapper}>
                                    <Text style={styles.itemTnC}>BPJS Kesehatan Care Center 165</Text>
                                    <Text style={styles.bpjsURL}>www.bpjs-kesehatan.go.id</Text>
                                </View>
                            </View>
                            {viewData?.length - 1 !== idx && (
                                <View style={styles.cutWrapper}>
                                    <Image src={Images.ICON_SCISSORS} style={{ width: 12, height: 12 }} />
                                    <View style={styles.divider}></View>
                                </View>
                            )}
                        </View>
                    ))}
                </Page>
            </Document>
        </PDFViewer>
    );
}
