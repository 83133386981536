import { API } from "configs";

export function changeStatusEmployee(idNumber = "") {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.put(
        `/employee/status/${idNumber}`,
        {},
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function changeStatusActiveEmployee(contractID, employeeID, corpID) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.patch(
        `/employee/active/${contractID}?employeeID=${employeeID}&corpID=${corpID}`,
        {},
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function changeStatusInActiveEmployee(contractID, payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.patch(
        `/employee/resign/${contractID}`,
        payload,
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
