import AsyncErrorPage from "@Molecule/AsyncErrorPage";
import SkeletonPersonalData from "@Molecule/Skeleton/Employee/DetailEmployee/PersonalData";
import DetailEmployee from "@Organism/Employee/DetailEmployee";
import { getDetailEmployee } from "@Services/employee/detailEmployee";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";
export const loader = (route) => {
  const params = new URLSearchParams(route?.request?.url);
  const { id } = route?.params;
  const type = params?.get("type") || "comprehensive";
  const limit = params?.get("limit") || 10;
  const page = params?.get("page") || 1;

  return defer({
    getDataDetail: getDetailEmployee(id, type, limit, page),
  });
};
export default function DetailEmployeePage() {
  const { getDataDetail } = useLoaderData();
  return (
    <Suspense fallback={<SkeletonPersonalData />}>
      <Await
        errorElement={<AsyncErrorPage/>}
        resolve={getDataDetail}
        children={(value) => <DetailEmployee dataPersonaEmployee={value} />}
      ></Await>
    </Suspense>
  );
}
