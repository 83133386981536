import { Await, defer, useLoaderData } from 'react-router-dom';
import { fetchBPJSData } from '@Services/bpjs';
import { Suspense } from 'react';
import LoadingAnimationPage from '@Molecule/LoadingAnimationPage';
import AsyncErrorPage from '@Molecule/AsyncErrorPage';
import LayoutCardBPJSKS from '@Organism/BPJS/CardBPJSKS';

export const loaderCardBPJSKS = (route) => {
    const { id } = route?.params;
    return defer({
        fetchBPJSData: fetchBPJSData(id),
    });
};

export default function PageCardBPJSKS(params) {
    const { fetchBPJSData } = useLoaderData();

    return (
        <Suspense fallback={<LoadingAnimationPage />}>
            <Await resolve={fetchBPJSData} errorElement={<AsyncErrorPage />}>
                {(value) => <LayoutCardBPJSKS data={value} />}
            </Await>
        </Suspense>
    );
}
