import useQuery from '@Hooks/useQuery';
import Styles from './styles.module.scss';
import { useEffect, useState } from 'react';
import { useDebounce } from '@Hooks/useDebounce';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import { sortByColumn } from '@Helpers/sortByColumn';
import TabHeader from '@Atom/TabHeader';
import ProfileEmployee from '@Atom/ProfileEmployee';
import { fileBaseUrl } from 'configs';
import DefaultAvatar from '@Assets/Images/ava-default.png';
import Icon from '@Atom/Icon';
import SearchBar from '@Atom/SearchBar';
import Button from '@Atom/Button';
import Table from '@Molecule/Table';
import Toast from '@Atom/Toast';
import ModalAddUser from './ModalAddUser';
import ModalDetailUser from './ModalDetailUser';

export default function ListUsersLayout({ data, totalData, totalPages }) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const query = useQuery();
    const pageQuery = query?.get('page') || 1;
    const limitQuery = query?.get('limit') || 5;
    const searchQuery = query?.get('search') || '';

    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState(searchQuery);
    const [page, setPage] = useState(+pageQuery || 1);
    const [limit, setLimit] = useState(+limitQuery || 5);
    const [sort, setSort] = useState();
    const debouncedSearch = useDebounce(search, 500);

    const [modalAddUser, setModalAddUser] = useState(false);
    const [modalDetailUser, setModalDetailUser] = useState(false);
    const [selectedUser, setSelectedUser] = useState();

    useEffect(() => {
        setIsLoading(true);
        navigate(`${pathname}?&page=${page}&limit=${limit}&search=${debouncedSearch}`);
    }, [pathname, limit, page, debouncedSearch, navigate]);

    const handleClickRow = (data) => {
        setSelectedUser(data);
        setModalDetailUser(true);
    };

    const tableTemplate = useMemo(() => {
        setIsLoading(false);
        return {
            data: data
                ?.map((elem) => ({
                    name: elem?.name || '-',
                    phoneNumber: elem?.phoneNumber || '-',
                    employeeID: elem?.employeeID || '-',
                    client: elem?.client || '-',
                    position: elem?.position || '-',
                    role: elem?.role || '-',
                }))
                .sort(sortByColumn(sort?.column || '', sort?.type || '')),
            totalData: totalData,
            columns: [
                {
                    name: 'name',
                    width: '20%',
                    title: <TabHeader text="Nama" column={'name'} sort={sort} setSort={setSort} isSorted />,
                    renderData: (row) => (
                        <ProfileEmployee
                            name={row?.name}
                            idEmployee={row?.phoneNumber}
                            image={row?.imageURL && row?.imageURL !== '-' ? fileBaseUrl + row.imageURL : DefaultAvatar}
                        />
                    ),
                },
                {
                    name: 'employeeID',
                    width: '20%',
                    title: <TabHeader text="NRK" column={'employeeID'} sort={sort} setSort={setSort} isSorted />,
                    renderData: (row) => (
                        <div className={Styles.rowNormalText}>
                            <span>{row?.employeeID}</span>
                        </div>
                    ),
                },
                {
                    name: 'position',
                    width: '20%',
                    title: (
                        <TabHeader
                            text="Penempatan & Posisi"
                            column={'position'}
                            sort={sort}
                            setSort={setSort}
                            isSorted
                        />
                    ),
                    renderData: (row) => (
                        <div className={Styles.wrapperText}>
                            <div>
                                <Icon icon={'building-4'} size={'12'} color={'#2E3192'} />
                                <span>{row?.client}</span>
                            </div>
                            <div>
                                <Icon icon={'briefcase'} size={'12'} color={'#2E3192'} />
                                <span>{row?.position}</span>
                            </div>
                        </div>
                    ),
                },
                {
                    name: 'role',
                    width: '20%',
                    title: <TabHeader text="Role" column={'role'} />,
                    renderData: (row) => (
                        <div className={Styles.rowNormalText}>
                            <span>{row?.role}</span>
                        </div>
                    ),
                },
            ],
        };
    }, [data, sort, totalData]);

    console.log('selectedUser', selectedUser);

    return (
        <div className={Styles.containerWrapper}>
            <div className={Styles.container}>
                <div className={Styles.headerWrapper}>
                    <span className={Styles.headerText}>Daftar Pengguna</span>
                    <div className={Styles.headerField}>
                        <SearchBar
                            backgroundColor="white"
                            setValue={setSearch}
                            value={search}
                            placeholder="Cari nama pengguna"
                        />
                        <div className={Styles.buttonArea}>
                            <Button
                                isNoBorder
                                isOutlined
                                isLeftIcon
                                icon="add-circle"
                                text="Tambah Daftar Pengguna"
                                onClick={() => {
                                    setModalAddUser(true);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <Table
                    onClickRow={handleClickRow}
                    className={Styles.tableWrapper}
                    data={tableTemplate.data}
                    columns={tableTemplate.columns}
                    totalData={tableTemplate.totalData}
                    totalPage={totalPages}
                    page={page}
                    setPage={setPage}
                    limit={limit}
                    setLimit={setLimit}
                    withNumbering
                    withPagination
                    widthNumber="5%"
                    isLoading={isLoading}
                />
            </div>
            <ModalAddUser
                modalAddUser={modalAddUser}
                setModalAddUser={setModalAddUser}
            />
            <ModalDetailUser
                modalDetailUser={modalDetailUser}
                setModalDetailUser={setModalDetailUser}
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
            />
        </div>
    );
}
