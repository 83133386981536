import SelectDropdown from '@Atom/SelectDropdown'
import TooltipTab from '@Atom/TooltipTab'
import Images from '@Theme/Images'
import { useMemo, useState } from 'react'
import Styles from './style.module.scss'

export default function PayrollSelectDocNumberCard({
  docOptions,
  selectedDoc,
  setSelectedDoc = () => { },
  isLoading = false
}) {
  const [docNumber, setDocNumber] = useState(null)

  const data = useMemo(() => {
    return docOptions?.find(obj => obj?.docType + ' : ' + obj?.docNumber === docNumber)
  }, [docNumber, docOptions])


  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <img src={Images.RAFIKI} alt='' />
        <h3>Silahkan Pilih Nomor Dokumen Untuk Payroll yang Akan Ditampilkan</h3>
      </div>
      <div className={Styles.content}>
        <SelectDropdown
          title='Pilih PKS Alias'
          value={docNumber}
          setValue={setDocNumber}
          placeholder='Select'
          options={docOptions?.map(obj => obj?.docType + ' : ' + obj?.docNumber)}
          height='44px'
          isLoading={isLoading}
        />
        {
          !!docNumber
          &&
          <div className={Styles.detail}>
            <p>
              <span>FLB - PKS</span>
              <span>:</span>
              <TooltipTab
                tooltipMargin=''
                textAlign='center'
                width='100%'
                content={`${data?.flbNumber || '-'} - ${data?.pksNumber || '-'}`}
                direction='bottom'
                fixedTop='32px'
              >
                <span className={Styles.link} onClick={() => window.open(`/flb/${data?.flbNumber}`, '_blank', 'rel=noopener noreferrer')}>{data?.flbNumber || '-'} - {data?.pksNumber || '-'}</span>
              </TooltipTab>
            </p>
          </div>
        }

        <button onClick={() => {
          setSelectedDoc(data)
        }}>Terapkan</button>
      </div>
    </div>
  )
}