import { API } from "configs";

export function getCategories(
  type = 'eksternal'
) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.get(
        `/ticket/categories`,
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
          params: {
            type
          }
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}