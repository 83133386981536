import React from "react";
import WebViewMWSLayout from "@Organism/WebViewMWS/Layout";
import { defer, Outlet } from "react-router-dom";
import { fetchListsBank, fetchLoanStatus } from "@Services/mws";

export const webViewLoader = ({ request }) => {
  const url = new URL(request.url);
  const token = url.searchParams.get("token") || "";
  return {
    bankData: fetchListsBank(token),
    userData: fetchLoanStatus(token),
  };
};
export default function WebViewMWS() {
  return (
    <WebViewMWSLayout>
      <Outlet />
    </WebViewMWSLayout>
  );
}
