import { API } from 'configs';

export function fetchAllBPJSData({ page = 1, limit = 5, search, type, corpID }) {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await API.get(`/bpjs-data?limit=${limit}&page=${page}&search=${search}&type=${type}&corpID=${corpID}`, {
                headers: {
                    Authorization: `${localStorage.getItem('accessToken')}`,
                },
            });
            resolve(data);
        } catch (error) {
            reject(error);
        }
    });
}

export function fetchBPJSData(employeeID) {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await API.get(`/bpjs-data/${employeeID}`, {
                headers: {
                    Authorization: `${localStorage.getItem('accessToken')}`,
                },
            });
            resolve(data.response);
        } catch (error) {
            reject(error);
        }
    });
}

export function updateBPJSData(idNumber, payload) {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await API.patch(`/bpjs-data/update-bpjs/${idNumber}`, payload, {
                headers: {
                    Authorization: `${localStorage.getItem('accessToken')}`,
                },
            });
            resolve(data.response);
        } catch (error) {
            reject(error);
        }
    });
}

export function updateBPJSKesByXlsx(payload) {
  return new Promise(async (resolve, reject) => {
      try {
          const { data } = await API.post(`/bpjs-data/update-bpjs-kes`, payload, {
              headers: {
                  Authorization: `${localStorage.getItem('accessToken')}`,
              },
          });
          resolve(data);
      } catch (error) {
          reject(error);
      }
  });
}

export function updateBPJSTKByXlsx(payload) {
  return new Promise(async (resolve, reject) => {
      try {
          const { data } = await API.post(`/bpjs-data/update-bpjs-tk`, payload, {
              headers: {
                  Authorization: `${localStorage.getItem('accessToken')}`,
              },
          });
          resolve(data);
      } catch (error) {
          reject(error);
      }
  });
}
