export function allFilledObjChecker(obj, exception = []) {
    return Object.keys(obj)
        .filter((key) => !exception.includes(key))
        .every((key) => {
            const value = obj[key];

            if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
                return allFilledObjChecker(value, exception);
            }

            if (Array.isArray(value)) {
                return value.length > 0 && value.every((item) => item !== null && item !== '' && item !== undefined);
            }

            return value !== null && value !== '' && value !== undefined;
        });
}
