import Toast from '@Atom/Toast';
import Styles from './styles.module.scss';
import { useEffect, useMemo, useState } from 'react';
import Tabs from '@Molecule/Tabs';
import SearchBar from '@Atom/SearchBar';
import Button from '@Atom/Button';
import Table from '@Molecule/Table';
import TabHeader from '@Atom/TabHeader';
import ProfileEmployee from '@Atom/ProfileEmployee';
import DefaultAvatar from '@Assets/Images/ava-default.png';
import { fileBaseUrl } from 'configs';
import ModalUploadBPJSKS from '@Atom/ModalUploadBPJSKS';
import { OutsideClickHandler } from '@Atom/OutsideClickHandler';
import { useLocation, useNavigate } from 'react-router-dom';
import { sortByColumn } from '@Helpers/sortByColumn';
import ModalUploadBPJSTK from '@Atom/ModalUploadBPJSTK';
export default function LayoutListBPJS({
    data,
    debouncedSearch,
    search,
    setSearch,
    type,
    setType,
    page,
    setPage,
    limit,
    setLimit,
    totalPage,
    totalData,
    tabActive,
    setTabActive,
    listTabs,
    isLoading,
    setIsLoading,
}) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [openToast, setOpenToast] = useState(false);
    const [openModalSelectBPJS, setOpenModalSelectBPJS] = useState(false);
    const [openModalUpdateBPJSKS, setOpenModalUpdateBPJSKS] = useState(false);
    const [openModalUpdateBPJSTK, setOpenModalUpdateBPJSTK] = useState(false);
    const [file, setFile] = useState();
    const [sort, setSort] = useState();

    useEffect(() => {
        setIsLoading(true);
        const corpID = localStorage.getItem("corpID");
        navigate(`${pathname}?&page=${page}&limit=${limit}&search=${debouncedSearch}&type=${type}&corpID=${corpID}`);
    }, [pathname, limit, page, type, debouncedSearch, navigate, setIsLoading]);

    useEffect(() => {
        switch (tabActive) {
            case 0:
                setType('');
                break;
            case 1:
                setType('REGISTERED');
                break;
            case 2:
                setType('UNREGISTERED');
                break;
            default:
                setType('');
                break;
        }
    }, [tabActive, setType]);

    useEffect(()=>{
        if (data?.length > 0) setIsLoading(false)
            else setIsLoading(false)
    },[data, setIsLoading])

    const bpjsTemplate = useMemo(() => {
        const template = {
            data: data
                ?.map((elem) => ({
                    employeeID: elem?.employeeID,
                    name: elem?.name,
                    imageURL: elem?.imageURL,
                    area: elem?.contracts?.[0]?.area || '-',
                    region: elem?.contracts?.[0]?.area || '-',
                    numberBpjsTK: elem?.bpjs?.find((f) => f.type === 'TK')?.number || '-',
                    numberBpjsKes: elem?.bpjsKesehatan?.[0]?.number || '-',
                    position: elem?.contracts?.[0]?.position || '-',
                }))
                .sort(sortByColumn(sort?.column || '', sort?.type || '')),
            totalData: totalData || 10,
            columns: [
                {
                    name: 'name',
                    width: '20%',
                    title: <TabHeader text="Nama & NRK TKO" column="name" sort={sort} setSort={setSort} isSorted />,
                    renderData: (row) => (
                        <ProfileEmployee
                            name={row?.name}
                            idEmployee={row?.employeeID}
                            image={row?.imageURL && row?.imageURL !== '-' ? fileBaseUrl + row.imageURL : DefaultAvatar}
                        />
                    ),
                },
                {
                    name: 'position',
                    width: '15%',
                    title: <TabHeader text="Posisi" column="position" sort={sort} setSort={setSort} isSorted />,
                },
                {
                    name: 'region',
                    width: '15%',
                    title: <TabHeader text="Regional" column="region" sort={sort} setSort={setSort} isSorted />,
                },
                {
                    name: 'numberBpjsKes',
                    width: '15%',
                    title: (
                        <TabHeader
                            text="Nomor BPJS KES"
                            column="numberBpjsKes"
                            sort={sort}
                            setSort={setSort}
                            isSorted
                        />
                    ),
                },
                {
                    name: 'numberBpjsTK',
                    width: '15%',
                    title: (
                        <TabHeader text="Nomor BPJS TK" column="numberBpjsTK" sort={sort} setSort={setSort} isSorted />
                    ),
                },
            ],
        };

        return template;
    }, [data, sort, totalData]);

    const handleOpenModalUpdateBPJSKS = () => {
        setOpenModalUpdateBPJSKS(false);
        setFile(undefined)
    };

    const handleOpenModalUpdateBPJSTK = () => {
        setOpenModalUpdateBPJSTK(false);
        setFile(undefined)
    };

    const handleClickRow = (data) => {
        navigate(`${data?.employeeID}`);
    };

    return (
        <div className={Styles.containerWrapper}>
            <div className={Styles.container}>
                <div className={Styles.headerWrapper}>
                    <span className={Styles.headerText}>{'TKO Sudah Terdaftar BPJS'}</span>
                    <Tabs isActive={tabActive} setIsActive={setTabActive} listTabs={listTabs} />
                    <div className={Styles.headerField}>
                        <SearchBar
                            backgroundColor="white"
                            setValue={setSearch}
                            value={search}
                            placeholder="Cari nama TKO atau NRK"
                        />
                        <div className={Styles.buttonArea}>
                            <Button
                                isNoBorder
                                isOutlined
                                isLeftIcon
                                icon="refresh-square"
                                text="Update Data BPJS"
                                onClick={() => {
                                    setOpenModalSelectBPJS(!openModalSelectBPJS);
                                }}
                            />
                            {openModalSelectBPJS && (
                                <OutsideClickHandler
                                    onOutsideClick={() => {
                                        setOpenModalSelectBPJS(false);
                                    }}>
                                    <div className={Styles.modalSelect}>
                                        <span
                                            onClick={() => {
                                                setOpenModalUpdateBPJSTK(true);
                                                setOpenModalSelectBPJS(false);
                                            }}>
                                            Update Data BPJS TK
                                        </span>
                                        <span
                                            onClick={() => {
                                                setOpenModalUpdateBPJSKS(true);
                                                setOpenModalSelectBPJS(false);
                                            }}>
                                            Update Data BPJS KES
                                        </span>
                                    </div>
                                </OutsideClickHandler>
                            )}
                        </div>
                    </div>
                </div>
                <Table
                    onClickRow={handleClickRow}
                    className={Styles.tableWrapper}
                    data={bpjsTemplate.data}
                    columns={bpjsTemplate.columns}
                    page={page}
                    setPage={setPage}
                    limit={limit}
                    setLimit={setLimit}
                    totalPage={totalPage}
                    totalData={totalData}
                    withNumbering
                    withPagination
                    widthNumber="5%"
                    isLoading={isLoading}
                />
            </div>

            <Toast
                isopen={openToast}
                color="green"
                text={`Berhasil update data BPJS`}
                onClose={() => setOpenToast(false)}
            />
            <ModalUploadBPJSKS
                isOpen={openModalUpdateBPJSKS}
                onClose={handleOpenModalUpdateBPJSKS}
                setFile={setFile}
                file={file}
            />
            <ModalUploadBPJSTK
                isOpen={openModalUpdateBPJSTK}
                onClose={handleOpenModalUpdateBPJSTK}
                setFile={setFile}
                file={file}
            />
        </div>
    );
}
