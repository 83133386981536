import { Document, Image, Page, PDFViewer, Text, View } from '@react-pdf/renderer';

// import Styles from './styles.module.scss';
import styles from './styles';
import Images from '@Theme/Images';
import { textGap } from '@Helpers/textGap';
import { useMemo } from 'react';

export default function LayoutCardBPJSTK({ data }) {
    const viewData = useMemo(() => {
        return data?.bpjs
            ?.map((item) => ({
                ...item,
                name: data?.name,
            }))
            .filter((f) => f?.type === 'TK');
    }, [data?.bpjs, data?.name]);

    return (
        <PDFViewer style={{ width: window.innerWidth, height: '100vh' }}>
            <Document title={`bpjstk_${data?.name}`}>
                <Page size="A4" style={styles.page}>
                    {viewData?.map((item, idx) => (
                        <View style={styles.itemWrapper}>
                            <View style={styles.titleWrapper}>
                                <Text style={styles.titleName}>{`Kartu BPJS : ${item?.name} - ${item?.status}`}</Text>
                            </View>

                            <View style={styles.cardWrapper}>
                                <Image src={Images.CARD_BPJS} style={styles.backgroundImage} />
                                <Text style={styles.textNIK}>
                                    {item?.idNumber ? textGap(item?.idNumber, 4) : 'kesalahan menampilkan NIK'}
                                </Text>
                                <Text style={styles.textNumber}>{item?.number || 'kesalahan menampilkan BPJS'}</Text>
                                <Text style={styles.textName}>{item?.name || 'kesalahan menampilkan Nama'}</Text>
                            </View>
                            {viewData?.length - 1 !== idx && (
                                <View style={styles.cutWrapper}>
                                    <Image src={Images.ICON_SCISSORS} style={{ width: 12, height: 12 }} />
                                    <View style={styles.divider}></View>
                                </View>
                            )}
                        </View>
                    ))}
                </Page>
            </Document>
        </PDFViewer>
    );
}
