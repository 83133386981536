import AsyncErrorPage from '@Molecule/AsyncErrorPage';
import LoadingAnimationPage from '@Molecule/LoadingAnimationPage';
import ListUsersLayout from '@Organism/MasterData/ListUsersLayout';
import { Suspense } from 'react';
import { Await, defer, useLoaderData } from 'react-router-dom';

export const loaderListUsers = (route) => {
    const params = new URLSearchParams(route?.request?.url);
    const limit = params?.get('limit') || 10;
    const page = params?.get('page') || 1;
    const searchQuery = params?.get('search') || '';

    return defer({
        getListUsers: Promise.resolve([
            {
                name: "Yon Safira",
                phoneNumber: "08512341234",
                employeeID: "1663823146628",
                client: "PT Permata Indonesia",
                position: "Human Resources",
                role: "Sistem Admin"
            },
            {
                name: "Suseno Sulistyawan",
                phoneNumber: "08512341234",
                employeeID: "09812903809128",
                client: "PT Permata Indonesia",
                position: "Business Process Innovation",
                role: "User Internal"
            },
            {
                name: "I Wayan Ferry",
                phoneNumber: "08512341234",
                employeeID: "09812903809128",
                client: "PT Permata Indonesia",
                position: "Business Process Innovation",
                role: "User Eksternal"
            },
            {
                name: "Sulistyo Budi",
                phoneNumber: "08512341234",
                employeeID: "09812903809128",
                client: "PT Makmur Sejahtera Abadi",
                position: "Business Benefit",
                role: "BPJS"
            },
            {
                name: "Yundha Bella",
                phoneNumber: "08512341234",
                employeeID: "09812903809128",
                client: "PT Makmur Sejahtera Abadi",
                position: "Business Process Innovation",
                role: "Power User"
            },
            {
                name: "Muhammad Azmy Ihwan",
                phoneNumber: "08512341234",
                employeeID: "09812903809128",
                client: "PT Makmur Sejahtera Abadi",
                position: "Business Process Innovation",
                role: "Payroll"
            },
            {
                name: "Fikky Hardianto",
                phoneNumber: "08512341234",
                employeeID: "09812903809128",
                client: "PT Makmur Sejahtera Abadi",
                position: "Business Process Innovation",
                role: "Pengaduan"
            },
            {
                name: "Anggi Susanto",
                phoneNumber: "08512341234",
                employeeID: "09812903809128",
                client: "PT Makmur Sejahtera Abadi",
                position: "Front Offfice",
                role: ""
            },

        ]),
    });
};

export default function ListUsersPage() {
    const { getListUsers } = useLoaderData();
    return (
        <Suspense fallback={<LoadingAnimationPage />}>
            <Await resolve={getListUsers} errorElement={<AsyncErrorPage />}>
                {(listUsers) => <ListUsersLayout data={listUsers} totalData={listUsers?.length} totalPages={1} />}
            </Await>
        </Suspense>
    );
}
