import AsyncErrorPage from "@Molecule/AsyncErrorPage";
import SkeletonListEmployee from "@Molecule/Skeleton/Employee/ListEmployee";
import FeedbackListLayout from "@Organism/Feedback/FeedbackList";
import { getFeedbackList } from "@Services/feedback/getFeedbackList";
import { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export const feedbackListLoader = (route) => {
  const url = new URL(route?.request?.url);
  const searchParams = url.searchParams
  const search = searchParams.get('s') || '';
  const limit = searchParams.get('l') || '10';
  const page = searchParams.get('p') || '1';
  const date = searchParams.get('d') || '';
  const assignedTo = searchParams.get('a') || '';
  const status = searchParams.get('st') || '';
  const isTransfers = searchParams.get('isTransfers') || 'false';
  const isLateResponse = searchParams.get('isLateResponse') || 'false';
  const needResponse = searchParams.get('needResponse') || 'false';

  return defer({
    packageList: getFeedbackList(page, limit, search, date, assignedTo, status, isTransfers, isLateResponse, needResponse)
  })
}
export default function FeedbackListPage() {
  const { packageList } = useLoaderData()

  return (
    <Suspense
      fallback={
        <SkeletonListEmployee />
      }
    >
      <Await
        resolve={packageList}
        errorElement={
          <AsyncErrorPage height="calc(100vh - 56px)" />
        }
      >
        {(packageList) => (
          <FeedbackListLayout
            packageList={packageList?.data?.response}
          />

        )}
      </Await>
    </Suspense>
  )
}