import Modal from "@Atom/Modal";
import FaqImage from "@Assets/Icon/qustionLogo.svg";
import Styles from "./styles.module.scss"
import Icon from "@Atom/Icon";
import Button from "@Atom/Button";
import { useState } from "react";
import Toast from "@Atom/Toast";
import { changeStatusActiveEmployee } from "@Services/employee/changeStatusEmployee";
import { useLocation, useNavigate } from "react-router-dom";

export default function ModalActivateEmployee({
    isOpenModalActivate,
    setIsOpenModalActivate,
    selectedName,
    tabs,
    setIsOpenModalFailed
}) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [isLoading, setIsLoading] = useState(false)
    const [isFailed, setIsFailed] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [errorMessage, setErrorMessage] = useState()

    const handleCloseModal = () =>{
        setIsOpenModalActivate(false)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true)
            const response = await changeStatusActiveEmployee(
                selectedName?.contractID, 
                `${selectedName?.employeeID}(${localStorage?.getItem("corpID")})`,
                localStorage?.getItem("corpID")
            )
            if (response.status === 200) {
                setIsSuccess(true);
                setIsLoading(false);
                setIsOpenModalActivate(false)
                navigate(pathname, { replace: true });
            }
        } catch (error) {
            setIsFailed(true);
            setIsLoading(false);
            if (error.response) {
                if (error.response.data?.message === "Cannot activate users. Contract has ended."){
                    setIsOpenModalFailed(true)
                } else {
                    // Jika server merespons dengan status error
                    setErrorMessage(error.response.data?.message || 'Terjadi kesalahan pada server.');
                }
            } else if (error.request) {
                // Jika request dikirim tapi tidak ada respons dari server
                setErrorMessage('Tidak ada respons dari server.');
            } else {
                // Error saat mengatur request
                setErrorMessage(error.message);
            }
            console.error(error);
        }
    }

    return (
        <>
            <Modal isOpen={isOpenModalActivate}>
                <div className={Styles.containerModal}>
                    <div className={Styles.wrapperModalDropdown}>
                        <div className={Styles.headerModal}>
                            <img src={FaqImage} alt="faq" className={Styles.icon} />
                            <h2>
                                Aktifkan Karyawan?
                            </h2>
                            <div className={Styles.closeModal} onClick={handleCloseModal}>
                                <Icon icon={'close-circle'} color={'#000'} size={12} />
                            </div>
                        </div>
                        <div className={Styles.desc}>
                            {tabs === 1 ? (
                                <p className={Styles.paragraph}>
                                    Apakah kamu yakin ingin mengaktifkan kembali karyawan atas nama {selectedName?.name}
                                </p>
                            ) : tabs === 2 ? (
                                <p className={Styles.paragraph}>
                                    Apakah kamu yakin ingin meng-unhold kembali karyawan atas nama {selectedName?.name}
                                </p>
                            ) : (
                                <p className={Styles.paragraph}>
                                </p>
                            )}
                        </div>
                        <div className={Styles.wrapperButton}>
                            <Button 
                                isOutlined 
                                text="Tutup" 
                                onClick={handleCloseModal} 
                                isDisabled={isLoading}
                                style={{ width: '48%', justifyContent: 'center' }}/>
                            <Button
                                text='Ya, aktifkan'
                                onClick={handleSubmit}
                                isDisabled={isLoading}
                                style={{ width: '48%', justifyContent: 'center' }}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
            <Toast
                color="green"
                text={tabs === 1 ? 
                    `Karyawan atas nama ${selectedName?.name} berhasil diaktifkan` : 
                    tabs === 2 ? 
                    `Karyawan atas nama ${selectedName?.name} berhasil 'di-unhold'` : ''
                }
                isopen={isSuccess}
                onClose={() => {
                    setIsSuccess(false);
                }}
            />
            <Toast color="red" text={errorMessage} isopen={isFailed} onClose={() => setIsFailed(false)} />
        </>
    );
}
