import ErrorBubble from '@Atom/ErrorBubble'
import Icon from '@Atom/Icon'
import Input from '@Atom/Input'
import OTPInput from '@Atom/OTPInput'
import useQuery from '@Hooks/useQuery'
import PrivacyPolicy from '@Molecule/PrivacyPolicy'
import { login } from '@Services/auth/login'
import { verifyOtp } from '@Services/auth/verifyOtp'
import Images from '@Theme/Images'
import { useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Styles from './style.module.scss'

export default function LoginLayout() {
  const [nrk, setNrk] = useState('')
  const [error, setError] = useState('')

  const [isSubmitting, setIsSubmitting] = useState(false)

  const query = useQuery()


  const [otp, setOtp] = useState(''.padEnd(4, '')); // Initialize with empty string of 4 length

  const type = useMemo(() => {
    if (query?.get('h')) {
      return 'otp'
    } return 'nrk'
  }, [query])

  const navigate = useNavigate()
  const { pathname } = useLocation()


  const handleSubmit = async () => {
    setError('')
    try {
      setIsSubmitting(true)
      if (type === 'otp') {
        const response = await verifyOtp(query?.get('h'), otp, query?.get('p'))
        localStorage.setItem('username', response?.data?.response?.data?.name)
        localStorage.setItem('role', response?.data?.response?.data?.position || '-')
        localStorage.setItem('access', response?.data?.response?.data?.role || '-')
        localStorage.setItem('profilePicture', response?.data?.response?.data?.imageURL === '-' ? null : response?.data?.response?.data?.imageURL || null)
        localStorage.setItem('accessToken', response?.data?.response?.token)
        navigate('/select-client?p=1&l=10&s=')
        setIsSubmitting(false)

      } else {
        const response = await login(nrk)
        setIsSubmitting(false)
        navigate(`${pathname}?h=${response?.data?.response?.hash}&p=${response?.data?.response?.phoneNumber}&n=${nrk}`, { replace: true })


      }
    } catch (err) {
      console.log(err)
      setIsSubmitting(false)
      setError(err?.response?.data?.message || 'Something went wrong')
    }
  }

  const queriedNRK = query.get('n')

  if (query?.get('pp')) {
    return (
      <div>
        <PrivacyPolicy />
      </div>
    )
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.top} />
      <div className={Styles.content}>
        <div className={Styles.loginCard}>
          <div className={Styles.header}>
            <img src={Images.MWS_LOGO} alt='' />
            <h3>Login Akun MyWorkSpace</h3>
            {
              error
              &&
              <div>
                <ErrorBubble
                  errorText={error}
                  noTip
                  justifyContent='center'
                  isV2
                />
              </div>

            }
          </div>
          <div className={Styles.main}>
            {
              type === 'nrk'
                ?
                <div className={Styles.form}>
                  <Input
                    title='NRK'
                    value={nrk}
                    setValue={setNrk}
                    placeholder='Masukkan NRK'
                  // placeholder='Masukkan NRK/nomor telpon'
                  // isNumber
                  />
                  {/* <div className={Styles.info}>
                    <Icon icon={'alert-solid'} size={16} />
                    <span>Gunakan “08” di awal ketika menggunakan nomor telpon</span>
                  </div> */}

                </div>
                :
                <div className={Styles.otpForm}>
                  <span>Masukkan OTP</span>
                  <p>
                    Masukkan OTP yang telah kami kirim ke NRK yang terdaftar {`${queriedNRK?.slice(0, 3)}${'*'.repeat(queriedNRK?.length - 5)}${queriedNRK.slice(-2)}`} atau <span onClick={() => navigate(-1)}>Ubah NRK</span>
                  </p>
                  <OTPInput length={4} value={otp} setValue={setOtp} />
                </div>
            }
            <div className={Styles.errorWrapper}>

            </div>
            <button disabled={isSubmitting} onClick={handleSubmit}>{type === 'otp' ? 'Konfirmasi OTP' : 'Login'}</button>
          </div>


        </div>
        <div className={Styles.privacyPolicy}>
          <Icon icon={'legal'} size={32} color={'#3E4856'} />
          <span>Kebijakan Privasi</span>
          <p>Lihat lebih detail dan pelajari kebijakan privasi kami</p>
          <button onClick={() => navigate(type === 'nrk'
            ?
            `${pathname}?pp=true`
            :
            `${pathname}?pp=true&h=${query?.get('h')}&p=${query?.get('p')}&n=${query?.get('n')}`
          )}>Lihat Kebijakan Privasi</button>
        </div>
      </div>
      <div className={Styles.bottom} />
      <div className={Styles.footer}>
        <span>© MWS Permata Indonesia</span>
      </div>
    </div>
  )
}