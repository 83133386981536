import Icon from '@Atom/Icon';
import Styles from './styles.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '@Atom/Button';
import { useEffect, useMemo, useState } from 'react';
import { OutsideClickHandler } from '@Atom/OutsideClickHandler';
import Table from '@Molecule/Table';
import TabHeader from '@Atom/TabHeader';
import ProfileEmployee from '@Atom/ProfileEmployee';
import { fileBaseUrl } from 'configs';
import DefaultAvatar from '@Assets/Images/ava-default.png';
import ModalUploadBPJSKS from '@Atom/ModalUploadBPJSKS';
import ModalUpdateDataBPJS from '@Atom/ModalUpdateDataBPJS';
import { sortByColumn } from '@Helpers/sortByColumn';
import ModalUploadBPJSTK from '@Atom/ModalUploadBPJSTK';

export default function LayoutDetailBPJS({ data, isLoading }) {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [openModalSelectUploadBPJS, setOpenModalSelectUploadBPJS] = useState(false);
    const [openModalSelectDownloadBPJS, setOpenModalSelectDownloadBPJS] = useState(false);
    const [openModalUploadBPJSKS, setOpenModalUploadBPJSKS] = useState(false);
    const [openModalUploadBPJSTK, setOpenModalUploadBPJSTK] = useState(false);
    const [openModalUpdateBPJS, setOpenModalUpdateBPJS] = useState(null);
    const [dataUpdate, setDataUpdate] = useState();
    const [trigger, setTrigger] = useState();

    const [file, setFile] = useState();
    const [sort, setSort] = useState();

    const status = data?.bpjs?.find((f) => f.type === 'TK')?.number ? 'registered' : 'unregistered';

    const handleOpenModalUploadBPJSKS = () => {
        setOpenModalUploadBPJSKS(false);
        setFile(null);
    };

    const handleOpenModalUploadBPJSTK = () => {
        setOpenModalUploadBPJSTK(null);
        setFile(null);
    };

    const handleOpenModalUpdateBPJS = () => {
        setOpenModalUpdateBPJS(null);
        setDataUpdate(null);
    };

    useEffect(() => {
        navigate(`${pathname}?t=${trigger}`);
    }, [navigate, pathname, trigger]);

    const bpjsTemplate = useMemo(() => {
        return {
            data: data?.families
                ?.map((elem) => ({
                    ...elem,
                    employeeID: elem?.employeeID || '',
                    name: elem?.name || '',
                    idNumber: elem?.idNumber || '',
                    familyIdNumber: elem?.familyIdNumber || '',
                    relation: elem?.relation || '',
                    numberBpjsTK: elem?.bpjs?.find((f) => f.type === 'TK' && f.status === 'ACTIVE')?.number || '',
                    numberBpjsJP: elem?.bpjs?.find((f) => f.type === 'JP' && f.status === 'ACTIVE')?.number || '',
                    numberBpjsKes: elem?.bpjsKesehatan?.[0]?.number || '',
                    healthFacility: elem?.bpjsKesehatan?.[0]?.healthFacility || '',
                    imageURL: elem?.imageURL,
                    birthDate: elem?.birthDate,
                    birthPlace: elem?.birthPlace,
                    gender: elem?.gender,
                    address: elem?.address,
                }))
                .sort(sortByColumn(sort?.column || '', sort?.type)),
            length: data?.families?.length || 10,
            columns: [
                {
                    name: 'name',
                    width: '15%',
                    title: <TabHeader text="Nama & NRK TKO" column="name" sort={sort} setSort={setSort} isSorted />,
                    renderData: (row) => (
                        <ProfileEmployee
                            name={row?.name}
                            idEmployee={row?.employeeID || '-'}
                            image={row?.imageURL && row?.imageURL !== '-' ? fileBaseUrl + row.imageURL : DefaultAvatar}
                        />
                    ),
                },
                {
                    name: 'relation',
                    width: '10%',
                    title: <TabHeader text="Hubungan" column="relation" sort={sort} setSort={setSort} isSorted />,
                    renderData: (row) => <span>{row?.relation || '-'}</span>,
                },
                {
                    name: 'familyIdNumber',
                    width: '15%',
                    title: <TabHeader text="NIK" column="familyIdNumber" sort={sort} setSort={setSort} isSorted />,
                    renderData: (row) => <span>{row?.familyIdNumber || '-'}</span>,
                },
                {
                    name: 'numberBpjsKes',
                    width: '15%',
                    title: (
                        <TabHeader
                            text="Nomor BPJS KES"
                            column="numberBpjsKes"
                            sort={sort}
                            setSort={setSort}
                            isSorted
                        />
                    ),
                    renderData: (row) => <span>{row?.numberBpjsKes || '-'}</span>,
                },
                {
                    name: 'numberBpjsTK',
                    width: '15%',
                    title: (
                        <TabHeader text="Nomor BPJS TK" column="numberBpjsTK" sort={sort} setSort={setSort} isSorted />
                    ),
                    renderData: (row) => <span>{row?.numberBpjsTK || '-'}</span>,
                },
                {
                    name: 'numberBpjsJP',
                    width: '15%',
                    title: (
                        <TabHeader text="Nomor Pensiun" column="numberBpjsJP" sort={sort} setSort={setSort} isSorted />
                    ),
                    renderData: (row) => <span>{row?.numberBpjsJP || '-'}</span>,
                },
                {
                    name: '',
                    width: '10%',
                    title: 'Aksi',
                    renderData: (row) => (
                        <Button
                            text={
                                row?.relation?.toUpperCase() === 'DIRI' && status === 'unregistered'
                                    ? 'Daftarkan'
                                    : 'Ubah Data'
                            }
                            onClick={() => {
                                if (row?.relation?.toUpperCase() === 'DIRI') {
                                    setOpenModalUpdateBPJS('TKO');
                                    setDataUpdate(row);
                                } else {
                                    setOpenModalUpdateBPJS('Keluarga');
                                    setDataUpdate(row);
                                }
                            }}
                        />
                    ),
                },
            ],
        };
    }, [data?.families, sort, status]);

    return (
        <div className={Styles.containerWrapper}>
            <div className={Styles.container}>
                <div className={Styles.headerWrapper}>
                    <div className={Styles.headerText}>
                        <Icon
                            icon={'arrow-chevron-left'}
                            color={'black'}
                            size={'20'}
                            style={{ cursor: 'pointer' }}
                            onClick={() => navigate('/bpjs')}
                        />
                        <span>{data?.name}</span>
                    </div>

                    <div
                        className={`${Styles.statusWrapper} ${
                            status === 'registered' ? Styles.registeredStatus : Styles.unregisteredStatus
                        }`}>
                        <div className={Styles.leftWrapper}>
                            <Icon icon={status === 'registered' ? 'tick-circle' : 'close-circle-solid'} size={'24px'} />
                            {status === 'registered' ? (
                                <span>{`Status BPJS TKO ${data?.name} dan keluarga sudah terdaftar`}</span>
                            ) : (
                                <span>{`Status BPJS TKO ${data?.name} dan keluarga belum terdaftar`}</span>
                            )}
                        </div>
                        <span>{`NRK: ${data?.employeeID}`}</span>
                    </div>

                    <div className={Styles.headerField}>
                        <div className={Styles.buttonArea}>
                            <Button
                                isNoBorder
                                isOutlined
                                isLeftIcon
                                icon="card-receive"
                                text="Download Kartu BPJS"
                                isDisabled={status !== 'registered'}
                                onClick={() => {
                                    setOpenModalSelectDownloadBPJS(!openModalSelectDownloadBPJS);
                                }}
                            />
                            {openModalSelectDownloadBPJS && (
                                <OutsideClickHandler
                                    onOutsideClick={() => {
                                        setOpenModalSelectDownloadBPJS(false);
                                    }}>
                                    <div className={Styles.modalSelect}>
                                        <span
                                            onClick={() => {
                                                window.open(`card-bpjstk/${data.employeeID}`)
                                                setOpenModalSelectDownloadBPJS(false);
                                            }}>
                                            BPJS TK
                                        </span>
                                        <span
                                            onClick={() => {
                                                window.open(`card-bpjsks/${data.employeeID}`)
                                                setOpenModalSelectDownloadBPJS(false);
                                            }}>
                                            BPJS KES TKO & Keluarga
                                        </span>
                                    </div>
                                </OutsideClickHandler>
                            )}
                        </div>

                        <div className={Styles.buttonArea}>
                            <Button
                                isNoBorder
                                isOutlined
                                isLeftIcon
                                icon="refresh-square"
                                text="Update Data BPJS"
                                onClick={() => {
                                    setOpenModalSelectUploadBPJS(!openModalSelectUploadBPJS);
                                }}
                            />
                            {openModalSelectUploadBPJS && (
                                <OutsideClickHandler
                                    onOutsideClick={() => {
                                        setOpenModalSelectUploadBPJS(false);
                                    }}>
                                    <div className={Styles.modalSelect}>
                                        <span
                                            onClick={() => {
                                                setOpenModalUploadBPJSTK(true);
                                                setOpenModalSelectUploadBPJS(false);
                                            }}>
                                            Update Data BPJS TK
                                        </span>
                                        <span
                                            onClick={() => {
                                                setOpenModalUploadBPJSKS(true);
                                                setOpenModalSelectUploadBPJS(false);
                                            }}>
                                            Update Data BPJS KES
                                        </span>
                                    </div>
                                </OutsideClickHandler>
                            )}
                        </div>
                    </div>
                </div>
                <Table
                    className={Styles.tableWrapper}
                    data={bpjsTemplate?.data}
                    columns={bpjsTemplate?.columns}
                    limit={bpjsTemplate?.length}
                    totalData={bpjsTemplate?.length}
                    withNumbering
                    widthNumber="5%"
                    isLoading={isLoading}
                />
            </div>
            <ModalUploadBPJSKS
                isOpen={openModalUploadBPJSKS}
                onClose={handleOpenModalUploadBPJSKS}
                setFile={setFile}
                file={file}
            />
            <ModalUploadBPJSTK
                isOpen={openModalUploadBPJSTK}
                onClose={handleOpenModalUploadBPJSTK}
                setFile={setFile}
                file={file}
            />
            <ModalUpdateDataBPJS
                isOpen={openModalUpdateBPJS}
                data={dataUpdate}
                type={openModalUpdateBPJS}
                status={status}
                onClose={handleOpenModalUpdateBPJS}
                setTrigger={setTrigger}
            />

        </div>
    );
}
