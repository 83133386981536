import AsyncErrorPage from '@Molecule/AsyncErrorPage';
import LoadingAnimationPage from '@Molecule/LoadingAnimationPage';
import ListBanksLayout from '@Organism/MasterData/ListBanksLayout';
import { Suspense } from 'react';
import { Await, defer, useLoaderData } from 'react-router-dom';

export const loaderListBanks = (route) => {
    const params = new URLSearchParams(route?.request?.url);
    const limit = params?.get('limit') || 10;
    const page = params?.get('page') || 1;
    const searchQuery = params?.get('search') || '';
    const corpID = localStorage.getItem('corpID');

    return defer({
        getListBanks: Promise.resolve([]),
    });
};

export default function ListBanksPage() {
    const { getListBanks } = useLoaderData();
    return (
        <Suspense fallback={<LoadingAnimationPage />}>
            <Await resolve={getListBanks} errorElement={<AsyncErrorPage />}>
                {(listBanks) => <ListBanksLayout data={listBanks} />}
            </Await>
        </Suspense>
    );
}
