import Styles from './styles.module.scss'

export default function ListBanksLayout({
    data
}){
    return (
        <div className={Styles.container}>
            LIST BANKS
        </div>
    )
}