/* eslint-disable eqeqeq */
import Icon from "@Atom/Icon";
import InputTitle from "@Atom/InputTitle";
import Rotation from "@Atom/Rotation";
import { formatBytes } from "@Helpers/formatBytes";
import { makeRandomString } from "@Helpers/makeRandomString";
import { priceFormat } from "@Helpers/priceFormat";
import { stringToNumber } from "@Helpers/stringToNumber";
import useOutsideClick from "@Hooks/useOutsideClick";
import Images from "@Theme/Images";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import Styles from "./style.module.scss";

export default function Input({
  // type = 'text',
  value = "",
  setValue = () => {},
  placeholder = "",
  className = "",
  adornment,
  adornmentPosition,
  padding = "12px",
  borderRadius = "8px",
  isPhoneNumber = false,
  isCurrency = false,
  isNumber = false,
  isPassword = false,
  isEmail = false,
  maxLength = 1000,
  isDropdown = false,
  isUserAutocomplete = false,
  dropdownOptions = [],
  isLoadingDropdown = false,
  customDropdownPositioning = { top: "58px" },
  isFile = false,
  accept = { "image/jpeg": [".jpeg", ".png"] },
  disabled = false,
  forceUpperCase = false,
  chooseOnEnter,
  isDate = false,
  isPercentage = false,
  title = "",
  required = false,
  requiredLabel = "*",
  maxSize = 0,
  withPreview = false,
  dropdownWithImage = false,
}) {
  const [showPassword, setShowPassword] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [expandDropdown, setExpandDropdown] = useState(false);
  const [expandAll, setExpandAll] = useState(true);
  const options = useMemo(() => {
    if (expandAll) {
      return dropdownOptions;
    }
    if (isUserAutocomplete) {
      return dropdownOptions?.filter((obj) =>
        obj?.name?.toLowerCase()?.includes(value?.toLowerCase())
      );
    }
    return dropdownOptions?.filter(obj => obj?.toLowerCase()?.includes(value?.toLowerCase()))
  }, [dropdownOptions, expandAll, isUserAutocomplete, value])

  useEffect(() => {
    if (!expandDropdown) {
      setExpandAll(true);
    }
  }, [expandDropdown]);

  useEffect(() => {
    if (
      !expandDropdown &&
      (isDropdown || isUserAutocomplete) &&
      value &&
      !dropdownOptions?.find((obj) => obj === value || obj?.name === value)
    ) {
      if (
        !dropdownOptions?.find((el) =>
          !el?.name
            ? el?.toLowerCase() === value?.toLowerCase()
            : el?.name?.toLowerCase() === value?.toLowerCase()
        )
      ) {
        setValue("");
      } else {
        setValue(
          dropdownOptions?.find((el) =>
            !el?.name
              ? el?.toLowerCase() === value?.toLowerCase()
              : el?.value?.toLowerCase() === value?.toLowerCase()
          )
        );
      }
    }
  }, [
    dropdownOptions,
    expandDropdown,
    isDropdown,
    isUserAutocomplete,
    setValue,
    value,
  ]);

  const boxRef = useRef();
  const boxOutsideClick = useOutsideClick(boxRef);

  useEffect(() => {
    if (boxOutsideClick && expandDropdown) {
      if (chooseOnEnter && options?.length) {
        setValue([0]);
      }
      setExpandDropdown(false);
    }
  }, [boxOutsideClick, chooseOnEnter, expandDropdown, options, setValue]);

  function onlyNumberInput(event) {
    var key = event.which || event.keyCode;
    if (key && (key <= 47 || key >= 58) && key != 8) {
      if (key != 9) {
        event.preventDefault();
      }
    }
  }

  function phoneNumberValidation(event) {
    var key = event.which || event.keyCode;
    if (!value?.length) {
      if (key && key != 56) {
        event.preventDefault();
      }
    } else {
      if (key && (key <= 47 || key >= 58) && key != 8) {
        if (key != 9) {
          event.preventDefault();
        }
      }
    }
  }

  const handleChangeDropdown = useCallback(
    (e) => {
      setExpandDropdown(true);
      setExpandAll(false);
      setValue(e?.target?.value);
    },
    [setValue]
  );

  const handleChangeCurrency = useCallback(
    (e) => {
      const {
        target: { value },
      } = e;

      if (value === "") {
        return setValue(0);
      }

      const valueAsNumber = stringToNumber(value || "0");

      return setValue(valueAsNumber || 0);
    },
    [setValue]
  );

  const toInputUppercase = (e) => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };

  if (isFile) {
    return (
      <FileInput
        file={value}
        setFile={setValue}
        accept={accept}
        placeholder={placeholder}
        maxSize={maxSize}
        withPreview={withPreview}
      />
    );
  }

  return (
    <div className={Styles.wrapper}>
      {title && (
        <InputTitle
          title={title}
          required={required}
          requiredLabel={requiredLabel}
        />
      )}
      <div
        ref={boxRef}
        className={`${Styles.container} ${className}`}
        onClick={() => {
          if (isDropdown || isUserAutocomplete) {
            setExpandDropdown(true);
          }
          // if(isUserAutocomplete) {
          //   setExpandDropdown(true)
          // }
        }}
      >
        {(isPhoneNumber || isCurrency) && (
          <div className={Styles.phoneCurrencyAdornment}>
            <span>{isPhoneNumber ? "+62" : "Rp"}</span>
          </div>
        )}
        {isUserAutocomplete && (
          <img
            className={Styles.picture}
            src={
              dropdownOptions?.find((obj) => obj?.name === value)?.picture ||
              Images.AVA_DEFAULT
            }
            alt=""
          />
        )}
        {isPhoneNumber ? (
          <input
            id={makeRandomString(5)}
            type="tel"
            value={value}
            placeholder={placeholder}
            onChange={(e) => {
              const re = /^(8[0-9]{0,11})$/;
              // const re = /^(0|08|08[0-9]{1,9})$/
              if (e.target.value === "" || re.test(e.target.value)) {
                setValue(e.target.value);
              }
            }}
            // onKeyDown={(e) => {
            //   if (isPhoneNumber) {
            //     phoneNumberValidation(e)
            //   }
            // }}
            style={{
              borderRadius,
              padding: "12px 12px 12px 55px",
            }}
            autoComplete={isPassword ? "on" : "off"}
            maxLength={maxLength}
            disabled={disabled}
          />
        ) : (
          <input
            id={makeRandomString(5)}
            onInput={forceUpperCase ? toInputUppercase : () => {}}
            type={
              isPassword
                ? showPassword
                  ? "text"
                  : "password"
                : isEmail
                ? "email"
                : isDate
                ? "date"
                : "text"
            }
            value={isCurrency ? priceFormat(+value) : value}
            placeholder={placeholder}
            onChange={(e) =>
              isCurrency
                ? handleChangeCurrency(e)
                : (isDropdown || isUserAutocomplete
                  ? handleChangeDropdown(e) : setValue(e.target.value))}
            onKeyDown={(e) => {
              if (isPhoneNumber) {
                phoneNumberValidation(e);
              }
              if (isNumber || isPercentage) {
                onlyNumberInput(e);
              }
              if (isDropdown && chooseOnEnter && options?.length) {
                var key = e.which || e.keyCode;
                if (key == 13) {
                  setValue(options[0]);
                }
              }
            }}
            onFocus={() => { isDropdown && setExpandDropdown(true) }}
            style={{
              padding: isUserAutocomplete ? '12px 12px 12px 40px' : padding,
              borderRadius,
              paddingLeft: isPhoneNumber || isCurrency ? '60px' : 'auto'
            }}
            autoComplete={isPassword ? "on" : "off"}
            maxLength={isPercentage ? (value == "10" ? 3 : 2) : maxLength}
            disabled={disabled}
          />
        )}

        {isPassword && (
          <button
            type="button"
            className={Styles.spButton}
            onClick={() => setShowPassword(!showPassword)}
          >
            <Icon
              icon={showPassword ? "eye-visible" : "eye-invisible"}
              size={20}
            />
          </button>
        )}

        {isPercentage && (
          <span
            type="button"
            className={Styles.spButton}
            onClick={() => setShowPassword(!showPassword)}
          >
            %
          </span>
        )}
        {(isDropdown || isUserAutocomplete) && isLoadingDropdown && (
          <Rotation type="blue" size="32px" className={Styles.spinner} />
        )}
        {(isDropdown || isUserAutocomplete) && (
          <button
            className={`${Styles.dropdownAdornment}`}
            onClick={(e) => {
              e.stopPropagation();
              setExpandDropdown(!expandDropdown);
            }}
          >
            {isDropdown ? (
              <Icon
                icon={"arrow-down-4"}
                size={24}
                className={Styles[expandDropdown ? "rotate180" : ""]}
              />
            ) : (
              <Icon icon={"search-normal"} size={16} color={"#BBC3CE"} />
            )}
          </button>
        )}
        {expandDropdown && (
          <div
            className={Styles.optionsWrapper}
            style={customDropdownPositioning}
          >
            {!isLoadingDropdown ? (
              options?.length ? (
                options?.map((option, i) => (
                  <div
                    key={i}
                    className={
                      option === value || option?.name === value
                        ? Styles.active
                        : ""
                    }
                    onClick={(e) => {
                      e?.preventDefault();
                      e?.stopPropagation();
                      setExpandDropdown(false);
                      setValue(isUserAutocomplete || dropdownWithImage ? option?.name : option);
                    }}
                  >
                    {(isUserAutocomplete || dropdownWithImage) && (
                      <img src={option?.picture || Images.AVA_DEFAULT} alt="" />
                    )}
                    <span>
                      {isUserAutocomplete || dropdownWithImage
                        ? option?.name
                        : option}
                    </span>

                    {option?.label && option?.label}
                  </div>
                ))
              ) : (
                <div>no option</div>
              )
            ) : (
              <div className={Styles.loadingText}>
                <span>loading data...</span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

const FileInput = ({
  file,
  setFile,
  accept,
  placeholder,
  maxSize = 0,
  withPreview = false,
}) => {
  const calculatedMaxSize = 1048576 * maxSize;

  const [errorFileReject, setErrorFileReject] = useState({});

  useEffect(() => {
    setErrorFileReject({});
  }, []);

  const [preview, setPreview] = useState(file);
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragActive,
    isDragReject,
    isDragAccept,
    // fileRejections,
    // acceptedFiles,
    open,
  } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) => {
      if (acceptedFiles?.length) {
        setFile(acceptedFiles[0]);
        setPreview(URL.createObjectURL(acceptedFiles[0]));
        setErrorFileReject({});
      }
      if (fileRejections?.length) {
        fileRejections.forEach((file) => {
          file.errors.forEach((err) => {
            if (err.code === "file-too-large") {
              setErrorFileReject({
                message: `File terlalu besar. Ukuran maksimal file adalah ${maxSize}MB !`,
              });
            } else if (err.code === "file-invalid-type") {
              setErrorFileReject({
                message:
                  "Tipe file tidak sesuai. Pastikan file memiliki format PDF",
              });
            } else {
              setErrorFileReject({
                message: err?.message || "Something went wrong",
              });
            }
          });
        });
      }
    },
    accept,
    multiple: false,
    noClick: true,
    maxSize: calculatedMaxSize,
  });

  // useEffect(() => {
  //   if (acceptedFiles?.length) {
  //     setFile(acceptedFiles[0])
  //     setPreview(URL.createObjectURL(acceptedFiles[0]))
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [acceptedFiles])

  useEffect(() => {
    if (file instanceof File) {
      setPreview(URL.createObjectURL(file));
    }
  }, [file]);

  const baseStyle = useMemo(() => {
    return {
      // backgroundColor: 'transparent',
    };
  }, []);

  const focusedStyle = useMemo(() => {
    return {
      // borderColor: '#2196f3',
    };
  }, []);

  const acceptStyle = useMemo(() => {
    return {
      // borderColor: '#00e676',
      // backgroundColor: '#E9ECEF',
      // backgroundColor: 'green'
    };
  }, []);

  const rejectStyle = useMemo(() => {
    return {
      borderColor: "#ff1744",
      // backgroundColor: '#FFBBBB',
    };
  }, []);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragActive ? { backgroundColor: "#F5F7FE" } : {}),
      ...(isDragReject || errorFileReject?.message ? rejectStyle : {}),
    }),
    [
      baseStyle,
      isFocused,
      focusedStyle,
      isDragAccept,
      acceptStyle,
      isDragActive,
      isDragReject,
      errorFileReject,
      rejectStyle,
    ]
  );

  return (
    <div
      {...getRootProps({ style })}
      className={`${Styles.field} ${file ? Styles.withFile : ""}`}
      onClick={(e) => e?.stopPropagation()}
    >
      <input {...getInputProps()} type="file" hidden />
      {!file || (file && !withPreview) ? (
        <div className={Styles.noPreview}>
          {file &&
          Object?.keys(accept)?.find((obj) => obj?.includes("excel")) ? (
            <div className={Styles.excelLogo}>
              <img alt="" src={Images.EXCEL} />
            </div>
          ) : (
            <Icon
              icon={"document-upload"}
              size={40}
              // color={!file ? '#9E9E9E' : '#1571DE'}
              color={"#2E3192"}
            />
          )}
          <p>{!file ? "Unggah Dokumen" : file?.name || "file"}</p>
          <span>
            {file
              ? formatBytes(file?.size)
              : errorFileReject?.message || placeholder}
          </span>
          <button onClick={open}>{!file ? "Browse file" : "Ubah file"}</button>
        </div>
      ) : (
        <div className={Styles.withPreview}>
          <img src={preview} alt="" />
          <div>
            <span>{file?.name || "File"}</span>
            <span>{formatBytes(file?.size)}</span>
          </div>
          <button onClick={() => setFile(null)}>
            <Icon icon={"trash"} size={24} color={"#FF3E13"} />
          </button>
        </div>
      )}
    </div>
  );
};
