/* eslint-disable no-unused-vars */
import Button from '@Atom/Button'
import Checkbox from '@Atom/Checkbox'
import DatePicker from '@Atom/DatePicker'
import Icon from '@Atom/Icon'
import InputTitle from '@Atom/InputTitle'
import Modal from '@Atom/Modal'
import MultiSelectDropdown from '@Atom/MultiSelectDropdown'
import { useState } from 'react'
import Styles from './style.module.scss'
import { getFeedbackDownload } from '@Services/feedback/getFeedbackList'
import moment from 'moment'
import { timeFormatV2 } from '@Helpers/timeFormat'

import ExcelJS from "exceljs";
import useQuery from '@Hooks/useQuery'


const categoryOptions = [
  'BPJS',
  'Penggajian',
  'Slip Gaji',
  'Parklaring',
  'Masalah Aplikasi',
  'Pungutan Liar',
  'Kekerasan di Lingkungan Kerja',
  'Lainnya'
]

const markerOptions = [
  'Diteruskan Ke Departemen Lain',
  'Ticket Dengan Pelanggaran SLA',
  'Ticket Dari Departemen Lain'
]

export default function CustomReportModal({
  show,
  onCancel = () => { },
  onClose = () => { }
}) {
  const query = useQuery()
  const [isDownloading, setIsDownloading] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [categories, setCategories] = useState([])
  const [statuses, setStatuses] = useState([])
  const [markers, setMarkers] = useState([])

  const resetDownload = () => {
    setStartDate('')
    setEndDate('')
    setCategories([])
    setStatuses([])
    setMarkers([])
  }

  const handleDownload = async () => {
    try {
      setIsDownloading(true)
      const { data } = await getFeedbackDownload('', query?.get('s'), startDate, endDate, query?.get('a'), statuses?.length === 1 ? statuses[0]?.toUpperCase() : '', markers?.includes('Diteruskan Ke Departemen Lain', 0), markers?.includes(markerOptions[1], 0), 'false', categories)
      const dataToMap = data?.response?.map((obj, i) => {
        return {
          ...obj,
          id: i + 1,
          ticketID: obj?.code,
          userName: obj?.employeeName,
          employeeID: obj?.employeeID,
          clientName: obj?.clientName,
          region: obj?.region,
          category: obj?.issueType || '-',
          title: obj?.title,
          timestamp: moment(new Date(obj?.createdAt))?.format('LL'),
          managerName: obj?.managerDetails?.name,
          firstResponseTime: obj?.firsResponseTime ? timeFormatV2(obj?.firsResponseTime) : '-',
          averageResponseTime: obj?.averagerResponseTime ? timeFormatV2(obj?.averagerResponseTime) : '-',
          sla: obj?.sla ? timeFormatV2(obj?.sla) : '-',
          status: obj?.status
        }
      })
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Report");

      worksheet.columns = [
        { header: "No", key: "id", width: 10 },
        { header: "ID", key: "ticketID", width: 20 },
        { header: "Nama Pelapor", key: "userName", width: 25 },
        { header: "Employee ID", key: "employeeID", width: 25 },
        { header: "Klien", key: "clientName", width: 25 },
        { header: "Region", key: "region", width: 25 },
        { header: "Kategori Pengaduan", key: "category", width: 25 },
        { header: "Judul Pengaduan", key: "title", width: 25 },
        { header: "Tgl Komplain", key: "timestamp", width: 25 },
        { header: "Manager", key: "managerName", width: 25 },
        { header: "First Resp. Time", key: "firstResponseTime", width: 25 },
        { header: "Avg. Resp. Time", key: "averageResponseTime", width: 25 },
        { header: "SLA", key: "sla", width: 25 },
        { header: "Status", key: "status", width: 25 },
      ];

      dataToMap.forEach(obj => worksheet.addRow(obj))

      worksheet.getRow(1).eachCell((cell) => {
        cell.font = { bold: true };
        cell.alignment = { horizontal: "center", vertical: "middle" };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "#2C5364" },
        };
        cell.font = {
          bold: true,
          color: { argb: "FFFFFFFF" },
        };
      });

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `Laporan Pengaduan.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      resetDownload()
      onClose()
    } catch (err) {
      console.log(err, 'error downloading')
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <Modal
      isOpen={show}
      onClose={isDownloading ? () => { } : onCancel}
    >
      <div className={Styles.container}>
        <div className={Styles.header}>
          <div className={Styles.left}>
            <h3>Download Report</h3>
            <span>
              Silahkan kustomisasi report yang ingin kamu downlaod
            </span>
          </div>
          <button>
            <Icon icon={'close-circle'} size={12} />
          </button>
        </div>
        <div className={Styles.content}>
          <div className={Styles.section}>
            <InputTitle title='Tanggal' required />
            <div className={Styles.date}>
              <DatePicker
                value={startDate}
                setValue={(newVal) => setStartDate(moment(newVal)?.format('YYYY-MM-DD'))}
              />
              <DatePicker
                value={endDate}
                setValue={(newVal) => setEndDate(moment(newVal)?.format('YYYY-MM-DD'))}
              />
            </div>

          </div>
          <MultiSelectDropdown
            title='Kategori Tiket'
            required
            options={categoryOptions}
            value={categories}
            setValue={setCategories}
            placeholder='Belum dipilih'
            isChip
          />
          <div className={Styles.section}>
            <InputTitle title='Status' required />
            <div className={Styles.statuses}>
              {
                [
                  'Open',
                  'Closed'
                ]
                  ?.map((option, i) => (
                    <div key={i} className={Styles.status}
                      onClick={() => {
                        if (statuses?.find(obj => obj === option)) {
                          setStatuses(statuses?.filter(obj => obj !== option))
                        } else {
                          setStatuses([...statuses, option])
                        }
                      }}>
                      <Checkbox
                        isChecked={statuses?.find(obj => obj === option) ? true : false}
                        setIsChecked={() => {
                          if (statuses?.find(obj => obj === option)) {
                            setStatuses(statuses?.filter(obj => obj !== option))
                          } else {
                            setStatuses([...statuses, option])
                          }
                        }}
                      />
                      <span>{option}</span>
                    </div>
                  ))
              }
            </div>
          </div>
          <MultiSelectDropdown
            title='Marker'
            required
            options={markerOptions}
            value={markers}
            setValue={setMarkers}
            placeholder='Belum dipilih'
            isChip
          />
        </div>
        <div className={Styles.actions}>
          <Button
            isDisabled={isDownloading}
            text='Batal'
            isOutlined
            onClick={onCancel}
          />
          <Button
            isDisabled={isDownloading}
            text='Simpan'
            onClick={handleDownload}
          />
        </div>
      </div>
    </Modal>
  )
}