import { useDebounce } from '@Hooks/useDebounce';
import useQuery from '@Hooks/useQuery';
import AsyncErrorPage from '@Molecule/AsyncErrorPage';
import LoadingAnimationPage from '@Molecule/LoadingAnimationPage';
import LayoutListBPJS from '@Organism/BPJS/ListBPJS';
import { fetchAllBPJSData } from '@Services/bpjs';
import { Suspense, useState } from 'react';
import { Await, defer, useLoaderData } from 'react-router-dom';

export const loaderListBPJS = (route) => {
    const params = new URLSearchParams(route?.request?.url);
    const limit = params?.get('limit') || 5;
    const page = params?.get('page') || 1;
    const search = params?.get('search') || '';
    const type = params?.get('type') || '';
    const corpID = localStorage.getItem("corpID");

    return defer({
        fetchBPJSData: fetchAllBPJSData({ page, limit, search, type, corpID }),
    });
};

export default function PageListBPJS() {
    const { fetchBPJSData } = useLoaderData();
    
    const query = useQuery();
    const pageQuery = query?.get('page') || 1;
    const limitQuery = query?.get('limit') || 5;
    const searchQuery = query?.get('search') || '';
    const typeQuery = query?.get('type') || '';
    
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState(searchQuery);
    const [page, setPage] = useState(+pageQuery || 1);
    const [limit, setLimit] = useState(+limitQuery || 5);
    const [type, setType] = useState(typeQuery);
    const debouncedSearch = useDebounce(search, 500);
    const [tabActive, setTabActive] = useState(type === 'REGISTERED' ? 1 : type === 'UNREGISTERED' ? 2 : 0);
    const listTabs = ['Semua', 'Sudah Terdaftar', 'Belum Terdaftar'];

    return (
        <Suspense fallback={<LoadingAnimationPage />}>
            <Await resolve={fetchBPJSData} errorElement={<AsyncErrorPage />}>
                {(valueBPJS) => (
                    <LayoutListBPJS
                        data={valueBPJS?.data}
                        isLoading={loading}
                        setIsLoading={setLoading}
                        type={type}
                        setType={setType}
                        search={search}
                        setSearch={setSearch}
                        debouncedSearch={debouncedSearch}
                        page={page}
                        setPage={setPage}
                        limit={limit}
                        setLimit={setLimit}
                        totalData={valueBPJS?.totalData}
                        totalPage={valueBPJS?.totalPages}
                        tabActive={tabActive}
                        setTabActive={setTabActive}
                        listTabs={listTabs}
                    />
                )}
            </Await>
        </Suspense>
    );
}
