export const detectFileType = (fileName) => {
    if (!fileName) {
        return 'Invalid file name';
    }

    const fileExtension = fileName.split('.').pop().split('_')[0].toLowerCase();

    if (['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(fileExtension)) {
        return 'image';
    } else if (fileExtension?.includes('pdf')) {
        return 'pdf';
    } else {
        return 'unknown';
    }
};
