import Modal from '@Atom/Modal';
import Styles from './styles.module.scss';
import Toast from '@Atom/Toast';
import { useEffect, useMemo, useState } from 'react';
import Button from '@Atom/Button';
import moment from 'moment';
import InputField from '@Atom/InputField';
import { updateBPJSData } from '@Services/bpjs';
import { makeRandomString } from '@Helpers/makeRandomString';

export default function ModalUpdateDataBPJS({ isOpen, data, type, status, onClose, setTrigger }) {
    const [isSuccess, setIsSuccess] = useState(false);
    const [isFailed, setIsFailed] = useState(false);
    const [values, setValues] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    
    const handleValues = (value, key) => {
        setValues((prev) => {
            return {
                ...prev,
                [key]: value
            }
        })
    }

    useEffect(() => {
        setValues({
            healthFacility: data?.healthFacility,
            numberBpjsKes: data?.numberBpjsKes,
            numberBpjsJP: data?.numberBpjsJP,
            numberBpjsTK: data?.numberBpjsTK
        })
    }, [data])

    const changeTracer = useMemo(() => {
        if (
            values?.healthFacility === data?.healthFacility &&
            values?.numberBpjsKes === data?.numberBpjsKes &&
            values?.numberBpjsJP === data?.numberBpjsJP &&
            values?.numberBpjsTK === data?.numberBpjsTK
        ) {
            return true
        } else {
            return false
        }
    }, [data, values?.healthFacility, values?.numberBpjsKes, values?.numberBpjsJP, values?.numberBpjsTK])

    const handleSubmit = async () => {
        try {
            setIsLoading(true)
            const updateBPJS = await updateBPJSData(data?.familyIdNumber, {
                relation: data?.relation || null,
                healthFacility: values?.healthFacility || null,
                numberBpjsKes: values?.numberBpjsKes || null,
                numberBpjsJP: values?.numberBpjsJP || null,
                numberBpjsTK: values?.numberBpjsTK || null
            })
            console.log('SUCCESS', updateBPJS)
            setIsLoading(false)
            setIsSuccess(true)
            onClose()
        } catch (error) {
            console.log('MODAL UPDATE DATA BPJS ERROR', error)
            setIsFailed(false)
        }
    }

    return (
        <>
            <Modal isOpen={isOpen}>
                <div className={Styles.container}>
                    <div className={Styles.header}>
                        {type === 'TKO' && status === 'unregistered' ? <span>Daftarkan Data BPJS {type}</span> : <span>Ubah Data BPJS {type}</span>}
                        <Button
                            isNoBorder
                            isOutlined
                            colorIcon="#3E4856"
                            iconOnly
                            icon="close-circle"
                            onClick={onClose}
                            iconSize={'20px'}
                        />
                    </div>

                    {type === 'TKO' ? (
                        <div className={Styles.staticField}>
                            <div className={Styles.staticItem}>
                                <span>Nama</span>
                                <span>{data?.name || '-'}</span>
                            </div>
                            <div className={Styles.staticItem}>
                                <span>NRK</span>
                                <span>{data?.employeeID || '-'}</span>
                            </div>
                            <div className={Styles.staticItem}>
                                <span>Tanggal Lahir</span>
                                <span>{data?.birthDate ? moment(data.birthDate).format('DD MMMM YYYY') : '-'}</span>
                            </div>
                            <div className={Styles.staticItem}>
                                <span>NIK</span>
                                <span>{data?.idNumber || '-'}</span>
                            </div>
                        </div>
                    ) : (
                        <div className={Styles.staticField}>
                            <div className={Styles.staticItem}>
                                <span>Nama</span>
                                <span>{data?.name || '-'}</span>
                            </div>
                            <div className={Styles.staticItem}>
                                <span>NIK</span>
                                <span>{data?.idNumber || '-'}</span>
                            </div>
                            <div className={Styles.staticItem}>
                                <span>Tanggal Lahir</span>
                                <span>{data?.birth ? moment(data.birthDate).format('DD MMMM YYYY') : '-'}</span>
                            </div>
                            <div className={Styles.staticItem}>
                                <span>Hubungan</span>
                                <span>{data?.relation || '-'}</span>
                            </div>
                        </div>
                    )}

                    <div className={Styles.fieldWrapper}>
                        <InputField
                            isLabel
                            placeholder='Masukkan nomor BPJS Kesehatan'
                            textLabel='Nomor BPJS Kesehatan' 
                            value={values?.numberBpjsKes}
                            setValue={(e) => handleValues(e, 'numberBpjsKes')}
                        />
                        {type === 'TKO' && (
                            <InputField
                                isLabel
                                placeholder='Masukkan nomor BPJS Ketenagakerjaan'
                                textLabel='Nomor BPJS Ketenagakerjaan' 
                                value={values?.numberBpjsTK}
                                setValue={(e) => handleValues(e, 'numberBpjsTK')}
                            />
                        )}
                        {type === 'TKO' && status === 'unregistered' && (
                            <InputField
                                isLabel
                                placeholder='Masukkan nomor BPJS JP'
                                textLabel='Nomor BPJS JP' 
                                value={values?.numberBpjsJP}
                                setValue={(e) => handleValues(e, 'numberBpjsJP')}
                            />
                        )}
                        <InputField
                            isLabel
                            placeholder='Masukkan nomor dan nama faskes (cth. 1230123-pondok betung)'
                            textLabel='Faskes' 
                            value={values?.healthFacility}
                            setValue={(e) => handleValues(e, 'healthFacility')}
                        />
                    </div>

                    <div className={Styles.bottomButton}>
                        <Button
                            text='Kembali'
                            isOutlined
                            onClick={onClose}
                            isDisabled={isLoading}
                            style={{width:"48%", justifyContent: "center"}}
                        />
                        <Button
                            text='Ubah Data'
                            onClick={handleSubmit}
                            isDisabled={changeTracer || isLoading}
                            style={{width:"48%", justifyContent: "center"}}
                        />
                    </div>
                </div>
            </Modal>
            <Toast
                color="green"
                text="Perubahan data berhasil disimpan"
                isopen={isSuccess}
                onClose={() => {
                    setIsSuccess(false)
                    setTrigger(makeRandomString(5))
                }}
            />
            <Toast color="red" text="Gagal Mengupdate Data BPJS" isopen={isFailed} onClose={() => setIsFailed(false)} />
        </>
    );
}
