import Button from '@Atom/Button'
import Icon from '@Atom/Icon'
import PeopleSearchbar from '@Atom/PeopleSearchbar'
import Skeleton, { BoxSkeleton } from '@Atom/Skeleton'
import { updateCategoryManager } from '@Services/manager/setCategoryManager'
import { useEffect, useState } from 'react'
import Styles from './style.module.scss'

export default function ManagerCard({
  icon = '',
  iconBg = '',
  label = '',
  options = [],
  defaultManager = null,
  setTrigger,
  isLoading,
  preventNav = false,
  setPreventNav = () => { },
  setToastMessage = () => { },
  ...props
}) {
  const [disabled, setDisabled] = useState(true)
  const [manager, setManager] = useState('')
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    if (defaultManager) {
      setManager(defaultManager)
    }
  }, [defaultManager])


  useEffect(() => {
    if (isLoading) return
    if (manager && defaultManager?.employeeId !== manager?.employeeId) {
      setPreventNav(true)
      setDisabled(false)
    }
    // if (manager?.employeeId === defaultManager?.employeeId) {
    //   setDisabled(true)
    //   setPreventNav(false)
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultManager?.employeeId, isLoading, manager, manager?.employeeId])

  const handleSave = async () => {
    try {
      setIsSaving(true)
      await updateCategoryManager(props?._id, manager?.employeeId)
      setToastMessage(`${manager?.name} berhasil disimpan sebagai manajer untuk kategori pengaduan ${label}`)
      // setTrigger(makeRandomString(5))
      setPreventNav(false)
      setDisabled(true)
    } catch (err) {
      console.log(err, 'error on saving')
    } finally {
      setIsSaving(false)
    }
  }

  if (isLoading) {
    return (
      <div className={Styles.container}>
        <div className={Styles.header}>
          <div className={Styles.icon} style={{ backgroundColor: iconBg }} >
            <BoxSkeleton height='40px'
            >
              <Icon icon={icon} size={24} color={'#fff'} />
            </BoxSkeleton>
          </div>
          <Skeleton height='26px'>
            <h4>{label}</h4>
          </Skeleton>
        </div>
        <div className={Styles.content}>
          <Skeleton height='44px'>
            <PeopleSearchbar
              title='Manager'
              value={manager}
              setValue={setManager}
              placeholder='Belum dipilih'
              options={options}
              isDropdown
            />
          </Skeleton>


          <Skeleton width='72px' height='36px'>
            <Button
              text='Simpan'
              isDisabled={disabled}
              onClick={handleSave}
            />

          </Skeleton>
        </div>
      </div>
    )
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <div className={Styles.icon} style={{ backgroundColor: iconBg }} >
          <Icon icon={icon} size={24} color={'#fff'} />
        </div>
        <h4>{label}</h4>
      </div>
      <div className={Styles.content}>
        <PeopleSearchbar
          title='Manager'
          value={manager}
          setValue={setManager}
          placeholder='Belum dipilih'
          options={options}
          isDropdown
        />

        <Button
          text='Simpan'
          isLoading={isSaving}
          isDisabled={disabled}
          onClick={handleSave}
        />
      </div>
    </div>
  )
}