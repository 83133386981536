import { API } from 'configs';

export function getDetailEmployee(id, type = 'comprehensive', limit = 10, page = 1) {
    return new Promise(async (resolve, reject) => {
        const isDocument = type === 'document';
        try {
            const response = await API.get(
                isDocument
                    ? `/employee/${id}?type=document&limit=${limit}&page=${page}`
                    : `/employee/${id}?type=${type}`,
                {
                    headers: {
                        Authorization: `${localStorage.getItem('accessToken')}`,
                    },
                }
            );
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
}

export function getPayslipByEmployee({ id, page, limit, sort, sortBy }) {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await API.get(
                `/payslip/user/${id}?page=${page}&limit=${limit}&sort=${sort}&sortBy=${sortBy}`,
                {
                    headers: {
                        Authorization: `${localStorage.getItem('accessToken')}`,
                    },
                }
            );
            resolve(data);
        } catch (error) {
            reject(error);
        }
    });
}

export function getPayslipDetailByEmployee({ id }) {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await API.get(`/payslip/${id}`, {
                headers: {
                    Authorization: `${localStorage.getItem('accessToken')}`,
                },
            });
            resolve(data);
        } catch (error) {
            reject(error);
        }
    });
}

export function getAssetByEmployee({ id, page, limit, sort, sortBy }) {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await API.get(
                `/employee/asset/${id}?page=${page}&limit=${limit}&sort=${sort}&sortBy=${sortBy}`,
                {
                    headers: {
                        Authorization: `${localStorage.getItem('accessToken')}`,
                    },
                }
            );
            resolve(data);
        } catch (error) {
            reject(error);
        }
    });
}

export function getContractByEmployee({ employeeID, page, limit, sort, sortBy }) {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await API.get(
                `/contract?employeeID=${employeeID}&page=${page}&limit=${limit}&sort=${sort}&sortBy=${sortBy}`,
                {
                    headers: {
                        Authorization: `${localStorage.getItem('accessToken')}`,
                    },
                }
            );
            resolve(data);
        } catch (error) {
            reject(error);
        }
    });
}

export function getAttendancesByEmployee({ employeeID, date }) {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await API.get(`/attendance/${employeeID}?date=${date}`, {
                headers: {
                    Authorization: `${localStorage.getItem('accessToken')}`,
                },
            });
            resolve(data);
        } catch (error) {
            reject(error);
        }
    });
}

export function getLeaveHistoryByEmployee({ employeeID, year, page, limit }) {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await API.get(`/attendance/history/leave?employeeID=${employeeID}&year=${year}`, {
                headers: {
                    Authorization: `${localStorage.getItem('accessToken')}`,
                },
            });
            resolve(data);
        } catch (error) {
            reject(error);
        }
    });
}

export function getEditDetailEmployee(employeeID) {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await API.get(`/employee/edit-detail/${employeeID}`, {
                headers: {
                    Authorization: `${localStorage.getItem('accessToken')}`,
                },
            });
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
}
