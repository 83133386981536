import { API } from "configs";

export function updateCategoryManager(id, managerID) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.patch(`/ticket/categories/${id}`, {}, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
        params: { managerID }
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}